import { StarOutlined } from '@ant-design/icons';

const lastTrainingTC = [
  { title: '#', dataIndex: 'key', rowScope: 'row', align: 'center' },
  { title: 'Упражнение', dataIndex: 'title', key: 'title' },
  { title: 'Описание', dataIndex: 'description', key: 'description' },
  { title: 'План', dataIndex: 'plan', key: 'plan' },
  { title: 'Факт', dataIndex: 'fact', key: 'fact' }
];

const currentTrainingTC = [
  { title: 'Упражнение', dataIndex: 'title', key: 'title' },
  { title: 'Описание', dataIndex: 'description', key: 'description' },
  { title: 'План', dataIndex: 'plan', key: 'plan', align: 'center' },
  { title: 'Факт', dataIndex: 'fact', key: 'fact', align: 'center' }
];

const clientWorkoutTC = [
  { title: 'Дата', dataIndex: 'date', key: 'date' },
  { title: 'Тренер', dataIndex: 'trainer', key: 'trainer' },
  { title: 'Локация', dataIndex: 'location', key: 'location' }
];

const partnerRecordingTC = [
  { title: '#', dataIndex: 'key', rowScope: 'row', align: 'center' },
  { title: 'Дата', dataIndex: 'date', key: 'date' },
  { title: 'Спортсмен', dataIndex: 'client', key: 'client' },
  { title: 'Время тренировки', dataIndex: 'time', key: 'time', align: 'center' },
  { title: 'Локация', dataIndex: 'location', key: 'location' }
];

const partnerReviewWorkoutsTC = [
  { title: '#', dataIndex: 'key', rowScope: 'row', align: 'center' },
  { title: 'Дата', dataIndex: 'date', key: 'date' },
  { title: 'Спортсмен', dataIndex: 'client', key: 'client' },
  {
    title: 'Подтверждение',
    dataIndex: '',
    key: 'x',
    render: () => (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <StarOutlined style={{ fontSize: 16, margin: 5, color: '#71e1b0' }} /> <p>Подтвердить</p>
      </div>
    )
  }
];

const exerciseTableTC = [
  { title: '#', dataIndex: 'key', rowScope: 'row', align: 'center' },
  { title: 'Упражнение', dataIndex: 'name', key: 'name' },
  { title: 'Описание', dataIndex: 'description', key: 'description' },
  { title: 'Тип упражнения', dataIndex: 'type', key: 'type' }
];

const locationTableTC = [
  { title: '#', dataIndex: 'key', rowScope: 'row', align: 'center' },
  { title: 'Название', dataIndex: 'name', key: 'name' },
  { title: 'Короткое название', dataIndex: 'shortName', key: 'shortName' },
  { title: 'Адрес', dataIndex: 'address', key: 'address' }
];

const workoutProfileTableTC = [
  { title: '#', dataIndex: 'key', rowScope: 'row', align: 'center' },
  { title: 'Профиль', dataIndex: 'name', key: 'name' },
  { title: 'Описание', dataIndex: 'description', key: 'description' },
  { title: 'Назначение', dataIndex: 'appointment', key: 'appointment' }
];

export const APP_TABLE_COLUMNS = {
  lastTrainingTC,
  currentTrainingTC,
  clientWorkoutTC,
  partnerReviewWorkoutsTC,
  partnerRecordingTC,
  exerciseTableTC,
  locationTableTC,
  workoutProfileTableTC
};
