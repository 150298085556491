import { makeAutoObservable } from 'mobx';
import { WorkoutProfileService } from '../../services';

export default class WorkoutProfileStore {
  isLoading = false;
  _workoutProfile = {};
  _workoutProfiles = [];

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setWorkoutProfiles(workoutProfiles) {
    this._workoutProfiles = workoutProfiles;
  }
  setWorkoutProfile(workoutProfile) {
    this._workoutProfile = workoutProfile;
  }
  get workoutProfiles() {
    return this._workoutProfiles;
  }
  get workoutProfile() {
    return this._workoutProfile;
  }

  async createWorkoutProfile(name, description, appointment) {
    try {
      const res = await WorkoutProfileService.createWorkoutProfile(name, description, appointment);
      await this.getAllWorkoutProfiles();
      this.setWorkoutProfile(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async getAllWorkoutProfiles() {
    this.setLoading(true);
    try {
      const res = await WorkoutProfileService.getAllWorkoutProfiles();
      this.setWorkoutProfiles(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getWorkoutProfileById(id) {
    try {
      const res = await WorkoutProfileService.getWorkoutProfileById(id);
      this.setWorkoutProfile(res.data);
    } catch (error) {
      return error;
    }
  }
  async updateWorkoutProfile(id, name, description, appointment) {
    try {
      const res = await WorkoutProfileService.updateWorkoutProfile(
        id,
        name,
        description,
        appointment
      );
      this.setWorkoutProfile(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async deleteWorkoutProfile(id) {
    try {
      const res = await WorkoutProfileService.deleteWorkoutProfileById(id);
      this.setWorkoutProfile({});
      return res;
    } catch (error) {
      return error;
    }
  }
}
