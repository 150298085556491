import { Col } from 'antd';
import { useContext, useEffect, useState } from 'react';
import TrainingsList from '../../client-training/trainings-list/TrainingsList';
import { workoutList } from '../../../../utils';
import { Context } from '../../../../index';
import { observer } from 'mobx-react-lite';
import { SmallSpinner } from '../../../index';
import PropTypes from 'prop-types';

const ScheduledWorkouts = ({ title, columns, size, img }) => {
  const { user, recording } = useContext(Context);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getBaseDate = async () => {
      await user.getAllUsers();
    };
    getBaseDate()
      .then(() => {
        const info = workoutList(recording.clientsRecording, user.users);
        setData(info);
      })
      .catch((error) => console.log(error));
  }, [recording.clientsRecording?.length]);

  return (
    <Col
      xl={{ span: size }}
      lg={{ span: size }}
      md={{ span: size }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      {recording.isLoading ? (
        <SmallSpinner />
      ) : (
        <TrainingsList title={title} columns={columns} data={data} img={img} />
      )}
    </Col>
  );
};

ScheduledWorkouts.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  size: PropTypes.number,
  img: PropTypes.string
};

export default observer(ScheduledWorkouts);
