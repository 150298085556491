import { SolutionOutlined, UserOutlined } from '@ant-design/icons';

const profilePartnerFields = [
  {
    name: 'name',
    rules: { required: true, message: 'Укажите имя!' },
    icon: <UserOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Имя'
  },
  {
    name: 'middleName',
    icon: <UserOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Отчество'
  },
  {
    name: 'surname',
    rules: { required: true, message: 'Укажите фамилию!' },
    icon: <UserOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Фамилия'
  },
  {
    name: 'position',
    rules: { required: true, message: 'Укажите должность!' },
    icon: <SolutionOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Должность'
  }
];

const profileClientFields = [
  {
    name: 'name',
    rules: { required: true, message: 'Укажите имя!' },
    icon: <UserOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Имя'
  },
  {
    name: 'middleName',
    rules: { required: true, message: 'Укажите отчество!' },
    icon: <UserOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Отчество'
  },
  {
    name: 'surname',
    rules: { required: true, message: 'Укажите фамилию!' },
    icon: <UserOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Фамилия'
  }
];

export const APP_PROFILE_FORM_FIELDS = { profilePartnerFields, profileClientFields };
