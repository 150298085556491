import { useContext, useEffect, useState } from 'react';
import { Col, Row, Tabs } from 'antd';
import { APP_CLIENT_TRAINING_TABS } from '../../../config';
import { Context } from '../../../index';
import { observer } from 'mobx-react-lite';
import './client-training-page.css';
import React from 'react';

export const ClientTrainingPage = () => {
  const { user, recording, exercise } = useContext(Context);
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    setTabs(APP_CLIENT_TRAINING_TABS.tabItems);
    const getBaseDate = async () => {
      const userId = user.user.id;
      if (!userId) {
        return;
      }
      await recording.getAllRecByEmployeeIdOrClientId(userId);
      await exercise.getAllExercises();
    };
    getBaseDate().catch((error) => console.log(error));
  }, []);
  return (
    <>
      <Row className="training-container" gutter={10}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs defaultActiveKey="1" type="card" size="small" items={tabs} />
        </Col>
      </Row>
    </>
  );
};

export default observer(ClientTrainingPage);
