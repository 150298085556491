import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import TrainingCardHeader from '../training-card/training-card-header/TrainingCardHeader';
import { Alert, Card, Table } from 'antd';
import { getTrainerData } from '../../../../utils';
import PropTypes from 'prop-types';
import { Context } from '../../../..';
import PageLabel from '../../../common/PageLabel/PageLabel';
import { history } from '../../../../assets/icons';

export const WorkoutItem = ({ data = [], columns, currentStyle }) => {
  const [exercises, setExercises] = useState([]);
  const { user } = useContext(Context);
  useEffect(() => {
    if (data.exercises) {
      const currentExercises = data.exercises
        .sort((a, b) => a.id - b.id)
        .map((exercise, index) => {
          exercise['key'] = index + 1;
          exercise['title'] = exercise.exercise.name;
          return exercise;
        });
      setExercises(currentExercises);
    }
  }, [data]);
  const trainerData = getTrainerData(user.users, data?.employeeId);

  return (
    <Card
      style={{ maxHeight: currentStyle }}
      className="training-card"
      title={<PageLabel text="Прошедшая тренировка" img={history} />}
      extra={
        <span className="training-card-title">
          {!data?.date
            ? dayjs().format('DD-MM-YYYY')
            : `${data.time} ${dayjs(data.date).format('DD-MM-YYYY')}`}
        </span>
      }>
      <TrainingCardHeader location={data?.location ?? 'Нет данных'} data={trainerData?.employee} />
      <Table
        columns={columns}
        dataSource={exercises}
        pagination={false}
        className="exercise-list-table"
      />
      <Alert
        className="comment-alert-container"
        message={data?.comment ?? 'Нет комментария'}
        type="info"
      />
    </Card>
  );
};

WorkoutItem.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.array,
  currentStyle: PropTypes.string
};
