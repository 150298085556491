// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t-card-wrapper {
  padding: 0 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t-card-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.t-card-avatar {
  border: 3px solid #769ccd;
  margin-right: 10px;
}
.t-card-avatar-default {
  border: 3px solid #769ccd;
  margin-right: 10px;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/client-training/training-card/training-card-header/training-card-header.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".t-card-wrapper {\n  padding: 0 5px;\n  margin-bottom: 5px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.t-card-content {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n.t-card-avatar {\n  border: 3px solid #769ccd;\n  margin-right: 10px;\n}\n.t-card-avatar-default {\n  border: 3px solid #769ccd;\n  margin-right: 10px;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
