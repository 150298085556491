import React from 'react';
import { Col, Tabs } from 'antd';
import { APP_CATALOGS_TABS } from '../../../config';
import { getTabsPosition } from '../../../utils';

const CatalogSettings = () => {
  return (
    <Col flex="auto">
      <Tabs tabPosition={getTabsPosition()} items={APP_CATALOGS_TABS.catalogsTabItems} />
    </Col>
  );
};

export default CatalogSettings;
