import * as AuthModule from './auth';
//Layout
import AppLayout from './common/Layout/AppLayout';
import AppMenu from './common/Layout/AppMenu/AppMenu';
import AppDrawer from './common/Layout/AppDrawer/AppDrawer';
import SiderCollButton from './common/Layout/SiderCollButton/SiderCollButton';
import UserInfo from './common/Layout/UserInfo/UserInfo';
import UserMenu from './common/Layout/UserMenu/UserMenu';
import Spinner from './common/Layout/Spinner/Spinner';
import SmallSpinner from './common/Layout/Spinner/SmallSpinner';
import InformerSpinner from './common/Layout/Spinner/InformerSpinner';
import AppCopyright from './common/AppCopyright/AppCopyright';
import MobileMenu from './common/Layout/MobileMenu/MobileMenu';
import MobileMenuButton from './common/Layout/MobileMenuButton/MobileMenuButton';

//AppRouter
import AppRouter from './common/router/AppRouter';

//Training components
import TrainingCardHeader from './training/client-training/training-card/training-card-header/TrainingCardHeader';
import TrainingCard from './training/client-training/training-card/TrainingCard';
import ScheduledWorkouts from './training/partner-training/scheduled-workouts/ScheduledWorkouts';
import ConfWorkout from './training/partner-training/confirmed-workout/ConfWorkout';
//Calendar components
import CreateEventModal from './calendar/modals/create-event-modal/CreateEventModal';
import ViewEventModal from './calendar/modals/view-event-modal/ViewEventModal';
import CustomEvent from './calendar/custom-event/CustomEvent';

//Icon components
import YandexIcon from './common/Icons/YandexIcon';

//Recording components
import RecordingForm from './recording/recording-form/RecordingForm';

export {
  AppLayout,
  Spinner,
  AuthModule,
  YandexIcon,
  AppRouter,
  AppMenu,
  AppDrawer,
  SiderCollButton,
  UserInfo,
  UserMenu,
  TrainingCardHeader,
  TrainingCard,
  CreateEventModal,
  ViewEventModal,
  CustomEvent,
  AppCopyright,
  RecordingForm,
  SmallSpinner,
  ScheduledWorkouts,
  ConfWorkout,
  InformerSpinner,
  MobileMenu,
  MobileMenuButton
};
