import { useContext, useState } from 'react';
import { Button, Card, Space, Table } from 'antd';
import ConfWorkoutModal from './ConfirmedWorkoutModal/ConfWorkoutModal';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import PrintWorkoutComponent from './PrintWorkoutComponent/PrintWorkoutComponent';
import WorkoutFilter from './WorkoutFilter';
import { Context } from '../../../../index';
import { WORKOUT_STATUS } from '../../../../constants';
import PageLabel from '../../../common/PageLabel/PageLabel';
import React from 'react';

const ConfWorkoutList = ({ title, columns, data, img }) => {
  const { workout } = useContext(Context);
  const [currentRow, setCurrentRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [printOpen, setPrintOpen] = useState(false);

  const tableColumns = columns ?? [
    { title: '#', dataIndex: 'key', rowScope: 'row', align: 'center' },
    { title: 'Дата', dataIndex: 'date', key: 'date', align: 'center' },
    { title: 'Спортсмен', dataIndex: 'client', key: 'client', align: 'center' },
    { title: 'Время тренировки', dataIndex: 'time', key: 'time', align: 'center' },
    { title: 'Локация', dataIndex: 'location', key: 'location', align: 'center' },
    {
      title: 'Управление',
      key: 'action',
      align: 'center',
      render: (r) => (
        <Space size="middle">
          <Button
            onClick={async () => {
              await workout.updateWorkout(r.id, WORKOUT_STATUS.ASSIGN);
            }}
            icon={<CheckCircleOutlined />}
            className="workout-assign-btn"
            size="small">
            Назначить
          </Button>
          <Button
            onClick={() => workout.updateWorkout(r.id, WORKOUT_STATUS.CANCEL)}
            icon={<StopOutlined />}
            className="workout-cancel-btn"
            size="small">
            Отменить
          </Button>
        </Space>
      )
    }
  ];

  const dataHandler = (e) => {
    const currentDate =
      currentTime || currentLocation
        ? currentData.filter((item) => item.date === e)
        : data.filter((item) => item.date === e);
    setCurrentData(currentDate);
    setCurrentDate(e);
  };

  const timeHandler = (e) => {
    const currentTime =
      currentDate || currentLocation
        ? currentData.filter((item) => item.time === e)
        : data.filter((item) => item.time === e);
    setCurrentData(currentTime);
    setCurrentTime(e);
  };
  const locationHandler = (e) => {
    const currentLocation =
      currentDate || currentTime
        ? currentData.filter((item) => item.location === e)
        : data.filter((item) => item.location === e);
    setCurrentData(currentLocation);
    setCurrentLocation(e);
  };
  const resetHandler = (type) => {
    if (type === 'date') {
      setCurrentDate(null);
      if (!currentTime && !currentLocation) {
        setCurrentData(data);
      } else if (currentTime && !currentLocation) {
        setCurrentData(data.filter((item) => item.time === currentTime));
      } else if (!currentTime && currentLocation) {
        setCurrentData(data.filter((item) => item.location === currentLocation));
      }
    } else if (type === 'time') {
      setCurrentTime(null);
      if (!currentDate && !currentLocation) {
        setCurrentData(data);
      } else if (currentDate && !currentLocation) {
        setCurrentData(data.filter((item) => item.date === currentDate));
      } else if (!currentDate && currentLocation) {
        setCurrentData(data.filter((item) => item.location === currentLocation));
      }
    } else {
      setCurrentLocation(null);
      if (!currentTime && !currentDate) {
        setCurrentData(data);
      } else if (currentTime && !currentDate) {
        setCurrentData(data.filter((item) => item.time === currentTime));
      } else if (!currentTime && currentDate) {
        setCurrentData(data.filter((item) => item.location === currentDate));
      }
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const tableHandler = (r) => {
    if (!columns) {
      return;
    }
    setCurrentRow(r);
    showModal();
  };

  const openPrintModal = () => setPrintOpen(true);

  return (
    <>
      <Card
        title={<PageLabel img={img} text={title} />}
        className="trainings-card"
        extra={
          !columns && (
            <WorkoutFilter
              openPrintModal={openPrintModal}
              dataHandler={dataHandler}
              resetHandler={resetHandler}
              locationHandler={locationHandler}
              timeHandler={timeHandler}
              data={data}
              currentData={currentData}
            />
          )
        }>
        <Table
          onRow={(r) => ({
            onClick: () => tableHandler(r)
          })}
          scroll={{ x: 400 }}
          columns={tableColumns}
          dataSource={currentData ?? data}
          pagination={{ pageSize: 5, className: 'list-pagination', size: 'small' }}
        />
      </Card>
      {!columns ? (
        <PrintWorkoutComponent
          workouts={currentData ?? data}
          isModalOpen={printOpen}
          setPrintOpen={setPrintOpen}
        />
      ) : (
        <ConfWorkoutModal
          data={currentRow}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};

ConfWorkoutList.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.any,
  img: PropTypes.string
};

export default observer(ConfWorkoutList);
