import { useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import {
  AppDrawer,
  AppMenu,
  SiderCollButton,
  UserInfo,
  UserMenu,
  AuthModule,
  MobileMenu,
  MobileMenuButton
} from '../../index';
import AppCopyright from '../AppCopyright/AppCopyright';
import { Context } from '../../../index';
import { observer } from 'mobx-react-lite';
import './layout.css';
import React from 'react';

const { LogoBlock } = AuthModule.ClientAuth;
const { Header, Footer, Sider, Content } = Layout;

const AppLayout = () => {
  const { userMenu } = useContext(Context);
  const [collapsed, setCollapsed] = useState(true);
  const [open, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('Calendar');
  const windowInnerWidth = document.documentElement.clientWidth;

  useEffect(() => {
    setActiveItem(userMenu.activeItem);
  }, [userMenu.activeItem]);
  const siderToggle = () => setCollapsed(!collapsed);

  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <Layout className="layout-style">
      {windowInnerWidth > 540 ? (
        <Sider className="sider-style" width={400} collapsed={collapsed}>
          <LogoBlock width={collapsed ? 50 : 180} min={collapsed} />
          <UserInfo collapsed={collapsed} />
          <UserMenu collapsed={collapsed} active={activeItem} />
        </Sider>
      ) : (
        <MobileMenu collapsed={collapsed} open={open} activeItem={activeItem} setOpen={setOpen} />
      )}
      <Layout className="layout-content" style={{ backgroundColor: '#fff' }}>
        <Header className="header-style">
          {windowInnerWidth > 540 ? (
            <SiderCollButton collapsed={collapsed} setCollapsed={siderToggle} />
          ) : (
            <MobileMenuButton openHandler={showDrawer} />
          )}
          <AppMenu />
          <AppDrawer />
        </Header>
        <Content className="content-style">
          <Outlet />
        </Content>
        <Footer className="footer-style">
          <AppCopyright color="#7f94a5" />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default observer(AppLayout);
