import React from 'react';
import { useContext, useState } from 'react';
import { Avatar, Button, Col, Drawer, Form, Image, Input, Row, Select } from 'antd';
import { message, support } from '../../../../assets/icons';
import { APP_SUPPORT_FORM_FIELDS } from '../../../../config';
import { Context } from '../../../../index';
import { openNotification } from '../../../../utils';
import './app-drawer.css';

const AppDrawer = () => {
  const { user, ticket } = useContext(Context);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const { createSupportFields, supportSelectType } = APP_SUPPORT_FORM_FIELDS;
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinish = async (values) => {
    if (!user.user.id) {
      return;
    }
    const res = await ticket.createTicket(values.type, values.title, values.message, user.user.id);
    form.resetFields();
    openNotification(res);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button
        className="app-drawer-btn"
        type="dashed"
        icon={<Avatar src={support} size={20} />}
        onClick={showDrawer}>
        Поддержка
      </Button>
      <Drawer placement="right" onClose={onClose} open={open}>
        <Row className="app-drawer-row">
          <Col>
            <Image src={support} width={150} preview={false} />
            <h3 className="app-drawer-title">Поддержка</h3>
          </Col>
          <Col className="app-drawer-col">
            <Form
              form={form}
              name="normal_login"
              className="sign-in-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off">
              <Form.Item name="type">
                <Select placeholder="Выберите тип сообщения">
                  {supportSelectType &&
                    supportSelectType.map(({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              {createSupportFields &&
                createSupportFields.map((field) => (
                  <Form.Item
                    hasFeedback
                    validateDebounce={1000}
                    key={field.name}
                    name={field.name}
                    rules={[field.rules]}>
                    {field.textarea ? (
                      <Input.TextArea
                        rows={10}
                        allowClear
                        prefix={field.icon}
                        type={field.type}
                        placeholder={field.placeholder}
                      />
                    ) : (
                      <Input
                        allowClear
                        prefix={field.icon}
                        type={field.type}
                        placeholder={field.placeholder}
                      />
                    )}
                  </Form.Item>
                ))}
              <Button
                icon={<Image src={message} width={25} preview={false} />}
                type="primary"
                htmlType="submit"
                className="app-drawer-form-btn">
                Отправить сообщение
              </Button>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default AppDrawer;
