// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-outer-wrapper {
  height: 65vh !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;
  padding: 5px !important;
}
.custom-inner-wrapper {
  margin: 2px !important;
  height: 64vh !important;
  overflow-y: auto !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/partner-training/create-workout/WorkoutTemplateWrapper/workout-template-wrapper.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,8BAA8B;EAG9B,wDAAwD;EACxD,uBAAuB;AACzB;AACA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;AAC7B","sourcesContent":[".custom-outer-wrapper {\n  height: 65vh !important;\n  border-radius: 10px !important;\n  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;\n  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;\n  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2) inset;\n  padding: 5px !important;\n}\n.custom-inner-wrapper {\n  margin: 2px !important;\n  height: 64vh !important;\n  overflow-y: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
