import { Modal } from 'antd';
import PropTypes from 'prop-types';
import './training-modal.css';
import TrainingModalForm from './TrainingModalForm';

const TrainingModal = ({ isModalOpen, data, setIsModalOpen, setCurrentExercise }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      forceRender={true}
      title={data.title}
      open={isModalOpen}
      footer={false}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <TrainingModalForm
        data={data}
        setIsModalOpen={setIsModalOpen}
        setCurrentExercise={setCurrentExercise}
        handleCancel={handleCancel}
      />
    </Modal>
  );
};

TrainingModal.propTypes = {
  isModalOpen: PropTypes.bool,
  data: PropTypes.object,
  setIsModalOpen: PropTypes.func,
  setCurrentExercise: PropTypes.func
};

export default TrainingModal;
