import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useContext } from 'react';
import { Context } from '../../../../../index';
import PropTypes from 'prop-types';
import { selectExercises } from '../../../../../utils';
import React from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

const AddExerciseForm = ({ handleCancel }) => {
  const { exercise, templateElement } = useContext(Context);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values['id'] = Date.now();
    templateElement.addExercise(values);
    form.resetFields();
    handleCancel();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const closeHandler = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item name="exerciseId">
        <Select
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          showSearch
          placeholder="Выбрать упражнение"
          options={selectExercises(exercise.exercises)}
        />
      </Form.Item>
      <Form.Item name="plan">
        <Input placeholder="Плановая дистанция" />
      </Form.Item>
      <Form.Item name="description">
        <TextArea placeholder="Условия выполнения" rows={4} />
      </Form.Item>
      <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          danger
          icon={<CloseOutlined />}
          size="small"
          type="default"
          onClick={closeHandler}
          className="exercise-cancel-btn">
          Отмена
        </Button>
        <Button
          icon={<PlusOutlined />}
          size="small"
          type="primary"
          onClick={() => form.setFieldsValue({})}
          className="exercise-add-btn"
          htmlType="submit">
          Добавить
        </Button>
      </Form.Item>
    </Form>
  );
};

AddExerciseForm.propTypes = {
  handleCancel: PropTypes.func
};

export default AddExerciseForm;
