export const defaultMessages = {
  date: 'Дата',
  time: 'Время',
  event: 'Событие',
  allDay: 'Все дни',
  week: 'Неделя',
  work_week: 'Рабочая неделя',
  day: 'День',
  month: 'Месяц',
  previous: 'Назад',
  next: 'Вперед',
  yesterday: 'Вчера',
  tomorrow: 'Завтра',
  today: 'Сегодня',
  agenda: 'Агенда',
  noEventsInRange: 'В этом диапазоне нет событий.',
  showMore: function showMore(total) {
    return '+' + total + ' более';
  }
};
