// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t-timeline-card {
  width: 100%;
  height: 15vh;
  text-align: left;
}
.t-timeline-card .ant-card-head {
  display: flex;
  justify-content: space-between;
  background: #fff;
  cursor: pointer;
  color: blue;
}
.t-timeline-card.active .ant-card-head {
  background: linear-gradient(
    211deg,
    rgba(90, 142, 189, 1) 0%,
    rgba(127, 195, 220, 1) 35%,
    rgba(172, 236, 223, 1) 100%
  );
}
.t-timeline-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 10px;
  cursor: pointer;
}
.t-timeline-card-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t-timeline-trainer-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.t-timeline-trainer-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.t-timeline-location-info {
  font-size: 10px;
  width: 50%;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/client-training/training-timeline/timeline-card/timeline-card.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb;AACA;EACE;;;;;GAKC;AACH;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,eAAe;EACf,eAAe;AACjB;AACA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,UAAU;AACZ","sourcesContent":[".t-timeline-card {\n  width: 100%;\n  height: 15vh;\n  text-align: left;\n}\n.t-timeline-card .ant-card-head {\n  display: flex;\n  justify-content: space-between;\n  background: #fff;\n  cursor: pointer;\n  color: blue;\n}\n.t-timeline-card.active .ant-card-head {\n  background: linear-gradient(\n    211deg,\n    rgba(90, 142, 189, 1) 0%,\n    rgba(127, 195, 220, 1) 35%,\n    rgba(172, 236, 223, 1) 100%\n  );\n}\n.t-timeline-card-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  font-size: 10px;\n  cursor: pointer;\n}\n.t-timeline-card-content {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.t-timeline-trainer-info {\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n.t-timeline-trainer-name {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n}\n.t-timeline-location-info {\n  font-size: 10px;\n  width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
