import { Image, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Context } from '../../../../index';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import img from '../../../../assets/images/logo.png';
import './view-event-modal.css';
const ViewEventModal = ({ currentEvent, open, handleOk, handleCancel }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const { calendar, auth, user } = useContext(Context);
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;
  useEffect(() => {
    setCurrentUser(user.user);
  }, []);
  const deleteEvent = async () => {
    await calendar.deleteEventById(currentEvent?.id);
    handleCancel();
  };

  return (
    <Modal footer={false} open={open} onOk={handleOk} onCancel={handleCancel}>
      <div className="modal-content-wrapper">
        <div>
          <Image src={img} alt="logo" preview={false} />
        </div>
        <div>
          <h3 className="modal-content-title">{currentEvent?.title}</h3>
          <div className="modal-content-body">
            {currentEvent?.img !== 'no-image.jpg' && (
              <div style={{ padding: 10 }}>
                <Image
                  src={`${URL}static/events/${currentEvent?.img}`}
                  className="modal-content-image"
                  alt={currentEvent?.title}
                />
              </div>
            )}
            {currentEvent?.description}
          </div>
        </div>
        <div className="modal-content-footer">
          <div className="content-footer-dates">
            <p style={{ textAlign: 'center' }}>
              <b>Старт события:</b>
              <br /> {dayjs(currentEvent?.start).format('DD-MM-YYYY HH:MM')}
            </p>
            <p style={{ textAlign: 'center' }}>
              <b>Завершение события:</b>
              <br /> {dayjs(currentEvent?.end).format('DD-MM-YYYY HH:MM')}
            </p>
          </div>
          {auth.partner?.employee?.id === currentEvent?.employeeId ||
          currentUser?.employee?.id === currentEvent?.employeeId ? (
            <div className="content-footer-actions">
              <DeleteOutlined className="footer-action-item" onClick={deleteEvent} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Modal>
  );
};

ViewEventModal.propTypes = {
  currentEvent: PropTypes.object,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  open: PropTypes.bool
};

export default ViewEventModal;
