import {
  calendar,
  competition,
  training,
  creditCard,
  email,
  faq,
  logout,
  newsletter,
  team,
  onlineLearning,
  calendarActv,
  trainingActv,
  competitionActv,
  faqActv,
  newsletterActv,
  teamActv,
  creditCardActv,
  emailActv,
  onlineLearningActv
} from '../../assets/icons';
import { COMMON_PATHS, CLIENT_PATHS, PARTNER_PATHS } from '../../constants';
import { Link } from 'react-router-dom';

const generalMenuItems = [
  {
    label: (
      <Link to={COMMON_PATHS.ABOUT_ROUTE} className="sign-in-form-link">
        О нас
      </Link>
    ),
    key: 'about'
  },
  {
    label: (
      <Link to={COMMON_PATHS.USER_AGREEMENT_ROUTE} className="sign-in-form-link">
        Условия использования
      </Link>
    ),
    key: 'policy'
  },
  {
    label: (
      <Link to={COMMON_PATHS.CONTACT_INFO_ROUTE} className="sign-in-form-link">
        Контактная информация
      </Link>
    ),
    key: 'contacts'
  }
];

const clientMenuItems = [
  {
    label: 'Кадендарь',
    key: 'calendar',
    activeIcon: calendarActv,
    icon: calendar,
    to: CLIENT_PATHS.CALENDAR_CLIENT_ROUTE,
    disabled: false
  },
  {
    label: 'Тренировки',
    key: 'training',
    icon: training,
    activeIcon: trainingActv,
    to: CLIENT_PATHS.TRAINING_CLIENT_ROUTE,
    disabled: false
  },
  {
    label: 'Соревнования',
    key: 'competition',
    icon: competition,
    activeIcon: competitionActv,
    to: CLIENT_PATHS.COMPETITION_CLIENT_ROUTE,
    disabled: true
  },
  {
    label: 'Уведомления',
    key: 'notification',
    icon: email,
    activeIcon: emailActv,
    to: CLIENT_PATHS.NOTIFICATION_CLIENT_ROUTE,
    disabled: true
  },
  {
    label: 'Платежи',
    key: 'payment',
    icon: creditCard,
    activeIcon: creditCardActv,
    to: CLIENT_PATHS.PAYMENT_CLIENT_ROUTE,
    disabled: true
  },
  {
    label: 'Обучение',
    key: 'learning',
    icon: onlineLearning,
    activeIcon: onlineLearningActv,
    to: CLIENT_PATHS.LEARNING_ROUTE,
    disabled: true
  },
  {
    label: 'FAQ',
    key: 'faq',
    icon: faq,
    activeIcon: faqActv,
    to: CLIENT_PATHS.FAQ_CLIENT_ROUTE,
    disabled: false
  },
  { label: 'Выйти', key: 'logout', icon: logout, to: '#', disabled: false }
];

const partnerMenuItems = [
  {
    label: 'Кадендарь',
    key: 'calendar',
    icon: calendar,
    activeIcon: calendarActv,
    to: CLIENT_PATHS.CALENDAR_CLIENT_ROUTE,
    disabled: false
  },
  {
    label: 'Тренировки',
    key: 'training',
    icon: training,
    activeIcon: trainingActv,
    to: PARTNER_PATHS.TRAINING_PARTNER_ROUTE,
    disabled: false
  },
  {
    label: 'Соревнования',
    key: 'competition',
    icon: competition,
    activeIcon: competitionActv,
    to: PARTNER_PATHS.COMPETITION_PARTNER_ROUTE,
    disabled: true
  },
  {
    label: 'Рассылка',
    key: 'newsletter',
    icon: newsletter,
    activeIcon: newsletterActv,
    to: PARTNER_PATHS.NEWSLETTER_ROUTE,
    disabled: true
  },
  {
    label: 'Клиенты',
    key: 'clients',
    icon: team,
    activeIcon: teamActv,
    to: PARTNER_PATHS.CLIENTS_ROUTE,
    disabled: true
  },
  {
    label: 'Платежи',
    key: 'payment',
    icon: creditCard,
    activeIcon: creditCardActv,
    to: PARTNER_PATHS.PAYMENT_PARTNER_ROUTE,
    disabled: true
  },
  {
    label: 'FAQ',
    key: 'faq',
    icon: faq,
    activeIcon: faqActv,
    to: PARTNER_PATHS.FAQ_PARTNER_ROUTE,
    disabled: false
  },
  { label: 'Выйти', key: 'logout', icon: logout, to: '#', disabled: false }
];

export const APP_MENU = { generalMenuItems, clientMenuItems, partnerMenuItems };
