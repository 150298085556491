import { Image } from 'antd';
import { AppCopyright } from '../../../../components';
import PropsTypes from 'prop-types';
import './preview-section.css';

const PreviewSection = ({ img }) => {
  return (
    <div className="columnStyle">
      <div className="columnContentWrapper">
        <div className="contentTitleWrapper">
          <Image src={img} className="contentImage" preview={false} />
        </div>
        <AppCopyright />
      </div>
    </div>
  );
};

PreviewSection.propTypes = {
  img: PropsTypes.string
};

export default PreviewSection;
