// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-label-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-label-avatar {
  padding: 2px;
}
.page-label-text {
  font-size: 12px;
  color: #6b7a99;
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/PageLabel/page-label.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".page-label-wrapper {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n.page-label-avatar {\n  padding: 2px;\n}\n.page-label-text {\n  font-size: 12px;\n  color: #6b7a99;\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
