import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Input, Card } from 'antd';
import { APP_EDIT_PROFILE_FORM_FIELDS } from '../../../../config';
import { Context } from '../../../..';
import { openNotification } from '../../../../utils';
import PropTypes from 'prop-types';
import './edit-password-form.css';

const EditPasswordForm = ({ type, disabledForm, currentUser }) => {
  const { user, auth } = useContext(Context);
  const [form] = Form.useForm();
  let navigate = useNavigate();

  const delayRedirect = async (password) => {
    setTimeout(() => navigate('/signin'), 1000);
    await auth.updatePassword(currentUser, password);
    form.resetFields();
  };

  const onFinish = async (values) => {
    if (type === 'reset') {
      delayRedirect(values.password);
    } else {
      if (!user.user) {
        return;
      }
      const { id } = user.user;
      const res = await user.updateUserPassword(id, values.password);
      if (res.status === 200) {
        openNotification(res);
      }
      form.resetFields();
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={type === 'edit' ? 'edit-form-container' : 'reset-form-container'}>
      <div style={{ width: type === 'edit' ? '35%' : '100%' }}>
        <Form
          disabled={type === 'reset' && disabledForm ? true : false}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off">
          {APP_EDIT_PROFILE_FORM_FIELDS.editProfileFields.map((field) => (
            <Form.Item
              key={field.name}
              className="edit-form-field"
              name={field.name}
              rules={field.rules}
              hasFeedback>
              <Input.Password
                placeholder={field.placeholder}
                prefix={field.icon}
                type={field.type}
              />
            </Form.Item>
          ))}

          <Form.Item>
            <Button
              disabled={type === 'reset' && disabledForm ? true : false}
              className={type === 'edit' ? 'edit-form-button' : 'reset-form-button'}
              htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div
        className={type === 'edit' ? 'edit-password-requirements' : 'reset-password-requirements'}>
        <Card className="edit-requirements-card">
          <h5>Требования к паролю:</h5>
          <div>
            <ul className="edit-requirements-list">
              <li>пароль содержит хотя бы одно число;</li>
              <li>пароль содержит хотя бы один спецсимвол (!@#$%^&*);</li>
              <li>пароль содержит хотя бы одну латинскую букву в нижнем регистре;</li>
              <li>пароль содержит хотя бы одну латинскую букву в верхнем регистре;</li>
              <li>пароль состоит не менее, чем из 6 вышеупомянутых символов.</li>
            </ul>
          </div>
        </Card>
      </div>
    </div>
  );
};

EditPasswordForm.propTypes = {
  type: PropTypes.string,
  disabledForm: PropTypes.bool,
  currentUser: PropTypes.string
};

export default EditPasswordForm;
