// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exercise-cancel-btn,
.exercise-add-btn {
  margin-right: 5px;
  font-size: 11px;
}
.exercise-add-btn {
  color: #5a8ebd !important;
  border: 1px solid #5a8ebd !important;
  background-color: #ffffff;
}
.exercise-add-btn:hover {
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  background-color: #5a8ebd !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/partner-training/create-workout/ExerciseModal/exercise-modal.css"],"names":[],"mappings":"AAAA;;EAEE,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,oCAAoC;EACpC,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,oCAAoC;EACpC,oCAAoC;AACtC","sourcesContent":[".exercise-cancel-btn,\n.exercise-add-btn {\n  margin-right: 5px;\n  font-size: 11px;\n}\n.exercise-add-btn {\n  color: #5a8ebd !important;\n  border: 1px solid #5a8ebd !important;\n  background-color: #ffffff;\n}\n.exercise-add-btn:hover {\n  color: #ffffff !important;\n  border: 1px solid #ffffff !important;\n  background-color: #5a8ebd !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
