import { LockOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

const signInClientFields = [
  {
    name: 'email',
    rules: {
      required: true,
      type: 'email',
      message: 'Пожалуйста, введите свой адрес электронной почты!'
    },
    icon: <MailOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Email'
  },
  {
    name: 'password',
    rules: { required: true, message: 'Пожалуйста, введите свой пароль!' },
    icon: <LockOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'password',
    placeholder: 'Password'
  }
];

const signUpClientFields = [
  {
    name: 'email',
    rules: [
      {
        required: true,
        type: 'email',
        message: 'Пожалуйста, введите свой адрес электронной почты!'
      }
    ],
    icon: <MailOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Email: email@email.com'
  },
  {
    name: 'phone',
    maxLength: 12,
    rules: [
      {
        pattern: new RegExp(/^[0-9]*$/),
        message: 'Пожалуйста, введите корректный номер телефона!'
      },
      {
        required: true,
        message: 'Пожалуйста, введите свой номер телефона!'
      },
      {
        min: 12,
        message: 'Значение должно быть не меньше 12 символов!'
      },
      {
        max: 12,
        message: 'Значение должно быть не больше 12 символов!'
      }
    ],
    icon: <PhoneOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Телефон: 375333333333'
  },
  {
    name: 'password',
    rules: [
      { required: true, message: 'Пожалуйста, введите свой пароль!' },
      {
        min: 6,
        message: 'Значение должно быть не меньше 6 символов!'
      },
      {
        max: 12,
        message: 'Значение должно быть не больше 12 символов!'
      },
      {
        pattern: new RegExp(
          /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g
        ),
        message: 'Пожалуйста, введите корректный пароль!'
      }
    ],
    icon: <LockOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'password',
    placeholder: 'Пароль'
  }
];

const signInPartnerFields = [
  {
    name: 'phone',
    maxLength: 12,
    rules: [
      {
        pattern: new RegExp(/^[0-9]*$/),
        message: 'Пожалуйста, введите корректный номер телефона!'
      },
      {
        type: 'phone',
        message: 'Пожалуйста, введите корректный номер телефона!'
      },
      {
        required: true,
        message: 'Пожалуйста, введите свой номер телефона!'
      },
      {
        min: 12,
        message: 'Значение должно быть не меньше 12 символов!'
      },
      {
        max: 12,
        message: 'Значение должно быть не больше 12 символов!'
      }
    ],
    icon: <PhoneOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'phone',
    placeholder: 'Телефон: 375333333333'
  },
  {
    name: 'password',
    rules: [{ required: true, message: 'Пожалуйста, введите свой пароль!' }],
    icon: <LockOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'password',
    placeholder: 'Пароль'
  }
];

export const APP_AUTH_FORM_FIELDS = { signInClientFields, signUpClientFields, signInPartnerFields };
