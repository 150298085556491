import TimelineCard from '../../components/training/client-training/training-timeline/timeline-card/TimelineCard';
import { AimOutlined } from '@ant-design/icons';

const trainingItems = [
  {
    dot: <AimOutlined style={{ fontSize: 16, border: '2px solid #acecdf', borderRadius: 10 }} />,
    children: <TimelineCard color="#acecdf" />
  },
  {
    dot: <AimOutlined />,
    children: <TimelineCard />
  },
  {
    dot: <AimOutlined />,
    children: <TimelineCard />
  },
  {
    dot: <AimOutlined />,
    children: <TimelineCard />
  }
];

export const APP_TIMELINES_ITEMS = { trainingItems };
