import dayjs from 'dayjs';
import RowInfo from '../components/training/partner-training/scheduled-workouts/row-info/RowInfo';
import React from 'react';

export const  workoutList = (clients, users) => {
  if (!clients || clients.length < 1) {
    return [];
  }

  return clients
    .map((item, index) => {
      item['client'] = getUser(item.clientId, users)[0];
      item['key'] = index + 1;
      return item;
    })
    .map((item) => {
      return {
        key: item.key,
        date: dayjs(item.date).format('DD/MM/YYYY'),
        client: <RowInfo clientInfo={item.client} />,
        time: item.time,
        location: item.location,
        clientData: item,
        id: item.id
      };
    });
};

function getUser(id, users) {
  return users.filter((item) => {
    if (item.id === id) {
      return item;
    }
  });
}
