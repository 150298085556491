import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../config';
import { Suspense, useContext, useEffect } from 'react';
import { Context } from '../../../index';
import { AppLayout } from '../../index';
import Spinner from '../Layout/Spinner/Spinner';
import { COMMON_PATHS } from '../../../constants';

const AppRouter = () => {
  const { auth } = useContext(Context);
  const navigate = useNavigate();
  const { defaultRoutes, clientRoutes, partnerRoutes } = APP_ROUTES;
  let currentRoutes;

  useEffect(() => {
    if (sessionStorage.getItem('page')) {
      navigate(sessionStorage.getItem('page'));
    }
  }, []);

  if (localStorage.getItem('type')) {
    currentRoutes = partnerRoutes;
  } else {
    currentRoutes = clientRoutes;
  }

  if (!auth.isAuth) {
    return (
      <Suspense fallback={<Spinner />}>
        <Routes>
          {defaultRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route
            path="*"
            element={
              localStorage.getItem('type') === 'partner' ? (
                <Navigate to={'/partner/signin'} />
              ) : (
                <Navigate to={'/signin'} />
              )
            }
          />
        </Routes>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path={COMMON_PATHS.HOME_CLIENT_ROUTE} element={<AppLayout />}>
          {currentRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="*" element={<Navigate to={COMMON_PATHS.HOME_CLIENT_ROUTE} />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
