export const selectLocations = (data) => {
  let locationsList = [];
  if (!data) {
    return locationsList;
  }
  data?.map((location) => {
    locationsList.push({
      value: location.shortName,
      label: location.shortName
    });
  });
  return locationsList;
};

export const selectWorkoutProfile = (data) => {
  let workoutProfileList = [];
  if (!data) {
    return workoutProfileList;
  }
  data?.map((profiles) => {
    workoutProfileList.push({
      value: profiles.id,
      label: profiles.name
    });
  });
  return workoutProfileList;
};

export const selectWorkoutProfileLabel = (data, workouts) => {
  let workoutProfileList = [];
  if (!data || !workouts) {
    return workoutProfileList;
  }
  data?.map((item) => {
    const profile = workouts.filter((workout) => workout.id === item.type)[0];
    const uniqueProfile = workoutProfileList.filter((item) => item.value === profile.id);
    if (uniqueProfile.length === 0) {
      workoutProfileList.push({
        value: profile.id,
        label: profile.name
      });
    }
  });
  return workoutProfileList;
};
