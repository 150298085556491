import {
  RadarChart,
  Tooltip,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend
} from 'recharts';
import PropTypes from 'prop-types';
import ChartTooltip from './tooltip/ChartTooltip';
import React from 'react';
import { observer } from 'mobx-react-lite';

const GeneralChart = ({ data }) => {
  return (
    <RadarChart
      style={{ cursor: 'pointer' }}
      cx="50%"
      cy="50%"
      outerRadius="80%"
      width={360}
      height={415}
      data={data}>
      <PolarGrid />
      <Tooltip content={<ChartTooltip />} />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis angle={30} domain={[0, data[0]?.fullMark]} />
      <Radar name="AIDA" dataKey="A" stroke="#FF9999" fill="#FF9999" fillOpacity={0.6} />
      <Radar name="Molchanovs" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.4} />
      <Radar name="Ваш результат" dataKey="C" stroke="#5a8ebd" fill="#5a8ebd" fillOpacity={0.6} />
      <Legend wrapperStyle={{ fontSize: 10 }} />
    </RadarChart>
  );
};

GeneralChart.propTypes = {
  data: PropTypes.array
};

export default observer(GeneralChart);
