import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from './UserAvatar/UserAvatar';
import UserData from './UserData/UserData';
import './user-info.css';
import { observer } from 'mobx-react-lite';

const UserInfo = ({ collapsed }) => {
  return (
    <div className="user-info-wrapper" style={{ display: collapsed ? 'none' : 'flex' }}>
      <UserAvatar collapsed={collapsed} />
      <UserData />
    </div>
  );
};

UserInfo.propTypes = {
  collapsed: PropTypes.bool
};

export default observer(UserInfo);
