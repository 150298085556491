// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyright-row {
  width: 100%;
}
.copyright-col {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
}
.copyright-span {
  font-size: 10px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/AppCopyright/copyright.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".copyright-row {\n  width: 100%;\n}\n.copyright-col {\n  width: 100%;\n  padding: 10px;\n  display: flex;\n  justify-content: center;\n}\n.copyright-span {\n  font-size: 10px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
