import React from 'react';
import { Drawer } from 'antd';
import UserMenu from '../UserMenu/UserMenu';
import PageLabel from '../../PageLabel/PageLabel';
import { logoMini } from '../../../../assets/images';
import PropTypes from 'prop-types';

const MobileMenu = ({ open, collapsed, activeItem, setOpen }) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      width={180}
      title={<PageLabel img={logoMini} text="Энергия глубины" />}
      closeIcon={false}
      placement="left"
      onClose={onClose}
      open={open}>
      <UserMenu setOpen={setOpen} collapsed={collapsed} active={activeItem} />
    </Drawer>
  );
};

MobileMenu.propTypes = {
  open: PropTypes.bool,
  collapsed: PropTypes.bool,
  activeItem: PropTypes.string,
  setOpen: PropTypes.func
};

export default MobileMenu;
