import { lazy } from 'react';

const ClientAuthPage = lazy(() => import('./auth/client-auth/ClientAuthPage'));
const PartnerAuthPage = lazy(() => import('./auth/partner-auth/PartnerAuthPage'));
const HomePage = lazy(() => import('./home/HomePage'));
// const CalendarPage = lazy(() => import('./calendar/CalendarPage'));
import CalendarPage from './calendar/CalendarPage';
const ClientCompetitionPage = lazy(
  () => import('./competition/client-competition-page/ClientCompetitionPage')
);
const ClientNotificationPage = lazy(
  () => import('./notification/client-notification-page/ClientNotificationPage')
);
const ClientPaymentPage = lazy(() => import('./payment/client-payment-page/ClientPaymentPage'));
const ClientSettingsPage = lazy(() => import('./settings/client-settings-page/ClientSettingsPage'));
// const ClientTrainingPage = lazy(() => import('./training/client-training-page/ClientTrainingPage'));
import ClientTrainingPage from './training/client-training-page/ClientTrainingPage';
const ClientsPage = lazy(() => import('./clients/ClientsPage'));
const ClientFaqPage = lazy(() => import('./faq/client-faq-page/ClientFaqPage'));
const ClientLearningPage = lazy(() => import('./learning/client-learning-page/ClientLearningPage'));
import PartnerTrainingPage from './training/partner-training-page/PartnerTrainingPage';
const PartnerCompetitionPage = lazy(
  () => import('./competition/partner-competition-page/PartnerCompetitionPage')
);
const PartnerNotificationPage = lazy(
  () => import('./notification/partner-notification-page/PartnerNotificationPage')
);
const PartnerPaymentPage = lazy(() => import('./payment/partner-payment-page/PartnerPaymentPage'));
import PartnerFaqPage from './faq/partner-faq-page/PartnerFaqPage';
import AboutPage from './about/AboutPage';
import UserAgreementPage from './user-agreement-page/UserAgreementPage';
import ContactInfoPage from './contact-info-page/ContactInfoPage';
import RecordingPage from './recording/RecordingPage';
import ResetPasswordPage from './reset-password/ResetPasswordPage';

export {
  ClientsPage,
  ClientFaqPage,
  PartnerTrainingPage,
  PartnerCompetitionPage,
  PartnerNotificationPage,
  PartnerPaymentPage,
  PartnerFaqPage,
  AboutPage,
  UserAgreementPage,
  ContactInfoPage,
  ClientAuthPage,
  PartnerAuthPage,
  HomePage,
  CalendarPage,
  ClientCompetitionPage,
  ClientLearningPage,
  ClientNotificationPage,
  ClientPaymentPage,
  ClientSettingsPage,
  ClientTrainingPage,
  RecordingPage,
  ResetPasswordPage
};
