import { Col } from 'antd';
import CollapsedItem from './CollapsedItem';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Context } from '../../../../../index';
import DefaultItem from './DefaultItem';
import './user-meu-item.css';

const UserMenuItem = (props) => {
  const { userMenu, auth } = useContext(Context);
  const clickHandler = () => {
    if (props.label === 'Выйти') {
      userMenu.setActiveItem('');
      sessionStorage.removeItem('page');
      return auth.logout();
    }
    sessionStorage.setItem('page', props.to);
    userMenu.setActiveItem(props.label);
    if (props.setOpen) {
      props.setOpen(false);
    }
  };

  return (
    <Col span={props.spanValue} className={`user-menu-item ${props.disabled && 'inactive'}`}>
      {props.collapsed ? (
        <CollapsedItem item={props} clickHandler={clickHandler} />
      ) : (
        <DefaultItem item={props} clickHandler={clickHandler} />
      )}
    </Col>
  );
};

UserMenuItem.propTypes = {
  spanValue: PropTypes.number,
  collapsed: PropTypes.bool,
  label: PropTypes.string,
  img: PropTypes.string,
  active: PropTypes.bool,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  setOpen: PropTypes.func
};
export default UserMenuItem;
