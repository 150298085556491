import { makeAutoObservable } from 'mobx';
import { CalendarService } from '../../services';

export default class CalendarStore {
  isLoading = false;
  _events = [];
  _event = {};

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setEvents(events) {
    this._events = events;
  }
  setEvent(event) {
    this._event = event;
  }
  get events() {
    return this._events;
  }
  get event() {
    return this._event;
  }

  async createEvent(title, description, type, start, end, img, employeeId) {
    try {
      const res = await CalendarService.createEvent(
        title,
        description,
        type,
        start,
        end,
        img,
        employeeId
      );
      await this.getAllEvents();
      return res;
    } catch (error) {
      return error;
    }
  }
  async getAllEvents() {
    this.setLoading(true);
    try {
      const res = await CalendarService.getAllEvents();
      this.setEvents(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getEventById(id) {
    this.setLoading(true);
    try {
      const res = await CalendarService.getEventById(id);
      console.log(res);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async updateEvent(id, title, description, start, end) {
    try {
      const res = await CalendarService.updateEvent(id, title, description, start, end);
      console.log(res);
    } catch (error) {
      return error;
    }
  }
  async deleteEventById(id) {
    try {
      const res = await CalendarService.deleteEventById(id);
      if (res) {
        const localEvents = this._events.filter((item) => item.id !== id);
        this.setEvents(localEvents);
      }
    } catch (error) {
      return error;
    }
  }

  async saveImg(formData) {
    try {
      for (const value of formData.values()) {
        console.log(value);
      }
      const res = await CalendarService.saveImage(formData);
      return await res.json();
    } catch (error) {
      return error;
    }
  }
}
