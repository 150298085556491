import { selectUserDates } from '../../../../utils';
import { selectUserLocations, selectUserTimes } from '../../../../utils/select-userdata';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const RecordingFilter = ({ dataHandler, resetHandler, timeHandler, locationHandler, data }) => {
  const selectDates = selectUserDates(data);
  const selectTime = selectUserTimes(data);
  const selectLocations = selectUserLocations(data);

  return (
    <div className="workout-filters-wrapper">
      <div className="workout-filter-wrapper">
        <Select
          size="small"
          className="workout-filter-select"
          onSelect={(e) => dataHandler(e)}
          onChange={() => resetHandler('date')}
          options={selectDates}
          placeholder="Выбрать дату"
          allowClear
        />
      </div>
      <div className="workout-filter-wrapper">
        <Select
          size="small"
          className="workout-filter-select"
          onSelect={(e) => timeHandler(e)}
          onChange={() => resetHandler('time')}
          options={selectTime}
          placeholder="Выбрать время"
          allowClear
        />
      </div>
      <div className="workout-filter-wrapper">
        <Select
          size="small"
          className="workout-filter-select"
          onSelect={(e) => locationHandler(e)}
          onChange={() => resetHandler('location')}
          options={selectLocations}
          placeholder="Выбрать локацию"
          allowClear
        />
      </div>
    </div>
  );
};

RecordingFilter.propTypes = {
  dataHandler: PropTypes.func,
  resetHandler: PropTypes.func,
  timeHandler: PropTypes.func,
  locationHandler: PropTypes.func,
  currentData: PropTypes.array,
  data: PropTypes.array
};
export default RecordingFilter;
