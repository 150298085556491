import React from 'react';
import PersonalWorkoutForm from './PersonalWorkoutForm';
import PageLabel from '../../../common/PageLabel/PageLabel';
import { Card, Col } from 'antd';
import { exercise } from '../../../../assets/icons';
import './create-personal-workout.css';

const CreatePersonalWorkout = () => {
  return (
    <Col span={24}>
      <Card
        className="personal-workout-card"
        title={<PageLabel img={exercise} text="Создание индивидуальной тренировки" />}>
        <PersonalWorkoutForm />
      </Card>
    </Col>
  );
};

export default CreatePersonalWorkout;
