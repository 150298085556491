import calendar from './calendar.png';
import competition from './competition.png';
import training from './training.png';
import creditCard from './credit-card.png';
import email from './email.png';
import settings from './settings.png';
import faq from './faq.png';
import logout from './logout.png';
import exc from './exc.png';
import swim from './swim.png';
import swimWhite from './swim-white.png';
import newsletter from './newsletter.png';
import onlineLearning from './online-learning.png';
import team from './team.png';
import resPassword from './respas.png';
import help from './help.png';
import key from './key.png';
import online from './online.png';
import info from './info.png';
import message from './message.png';
import news from './news.png';
import diving from './diving.png';
import personalWorkout from './personal-workout.png';
import timer from './timer.png';
import calendarActv from './calendarActv.png';
import trainingActv from './trainingActv.png';
import competitionActv from './competitionActv.png';
import faqActv from './faqActv.png';
import newsletterActv from './newsletterActv.png';
import teamActv from './teamActv.png';
import creditCardActv from './creditCardActv.png';
import onlineLearningActv from './onlineLearningActv.png';
import emailActv from './emailActv.png';
import location from './location.png';
import exercise from './exercise.png';
import add from './add.png';
import errorIcon from './errorIcon.png';
import successIcon from './successIcon.png';
import schedule from './schedule.png';
import approve from './approve.png';
import template from './template.png';
import workouts from './workouts.png';
import calendarIcon from './calendarIcon.png';
import safety from './safety.png';
import support from './support.png';
import activeWorkout from './activeWorkout.png';
import history from './history.png';
import profile from './profile.png';
import search from './search.png';
import terms from './terms.png';
import reset from './reset.png';
import wProfiles from './wProfiles.png';
import faqPage from './faqPage.png';
import techWork from './tech-work.png';
import about from './about.png';
import contacts from './contacts.png';

export {
  newsletter,
  team,
  onlineLearning,
  exc,
  calendar,
  competition,
  training,
  creditCard,
  email,
  settings,
  faq,
  logout,
  swim,
  swimWhite,
  resPassword,
  help,
  key,
  online,
  info,
  message,
  news,
  diving,
  personalWorkout,
  timer,
  calendarActv,
  trainingActv,
  competitionActv,
  faqActv,
  newsletterActv,
  teamActv,
  creditCardActv,
  onlineLearningActv,
  emailActv,
  location,
  exercise,
  add,
  errorIcon,
  successIcon,
  schedule,
  approve,
  template,
  workouts,
  calendarIcon,
  safety,
  support,
  activeWorkout,
  history,
  profile,
  search,
  terms,
  reset,
  wProfiles,
  faqPage,
  techWork,
  about,
  contacts
};
