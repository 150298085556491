import { makeAutoObservable } from 'mobx';
import { ExerciseService } from '../../services';

export default class ExerciseStore {
  isLoading = false;
  _exercises = [];
  _exercise = {};

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setExercises(exercises) {
    this._exercises = exercises;
  }
  setExercise(exercise) {
    this._exercise = exercise;
  }
  get exercises() {
    return this._exercises;
  }
  get exercise() {
    return this._exercise;
  }

  async createExercise(name, type, description, control) {
    try {
      const res = await ExerciseService.createExercise(name, type, description, control);
      await this.getAllExercises();
      this.setExercise(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async getAllExercises() {
    this.setLoading(true);
    try {
      const res = await ExerciseService.getAllExercises();
      this.setExercises(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getExerciseById(id) {
    try {
      const res = await ExerciseService.getExerciseById(id);
      this.setExercise(res.data);
    } catch (error) {
      return error;
    }
  }
  async updateExercise(id, name, type, description, control) {
    try {
      const res = await ExerciseService.updateExercise(id, name, type, description, control);
      this.setExercise(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async deleteExercise(id) {
    try {
      const res = await ExerciseService.deleteExerciseById(id);
      this.setExercise({});
      return res;
    } catch (error) {
      console.log(error);
    }
  }
}
