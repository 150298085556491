import React from 'react';
import { Col, Row, Card, Image, Collapse } from 'antd';
import { terms } from '../../assets/icons';
import { USE_OF_TERMS } from '../../constants';
import './user-agreement-page.css';

const UserAgreementPage = () => {
  return (
    <Row className="user-agreement-row">
      <Col className="user-agreement-col" xs={24} lg={12}>
        <Image src={terms} preview={false} alt="Terms of use" />
      </Col>
      <Col className="user-agreement-content-col" xs={24} lg={12}>
        <Card className="user-agreement-card">
          <Collapse defaultActiveKey={['1']} size="small" items={USE_OF_TERMS.termsList} />
        </Card>
      </Col>
    </Row>
  );
};

export default UserAgreementPage;
