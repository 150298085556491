import { makeAutoObservable, runInAction } from 'mobx';
import { TemplateElementService } from '../../services';

export default class TemplateElementStore {
  isLoading = false;
  _templateElement = {};
  _templateElements = [];

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setTemplateElements(templateElements) {
    this._templateElements = templateElements;
  }
  setTemplateElement(templateElement) {
    this._templateElement = templateElement;
  }
  get templateElements() {
    return this._templateElements;
  }
  get templateElement() {
    return this._templateElement;
  }

  async createTemplateElement(templateId, exerciseId, plan, description) {
    try {
      const res = await TemplateElementService.createTemplateElement(
        templateId,
        exerciseId,
        plan,
        description
      );
      await this.getAllTemplateElements();
      this.setTemplateElement(res.data);
    } catch (error) {
      return error;
    }
  }
  async getAllTemplateElements() {
    this.setLoading(true);
    try {
      const res = await TemplateElementService.getAllTemplateElements();
      this.setTemplateElements(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getTemplateElementsByTemplateId(templateId) {
    this.setLoading(true);
    try {
      const res = await TemplateElementService.getTemplateElementsByTemplateId(templateId);
      this.setTemplateElements(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getTemplateElementById(id) {
    try {
      const res = await TemplateElementService.getTemplateElementById(id);
      this.setTemplateElement(res.data);
    } catch (error) {
      return error;
    }
  }
  async updateTemplateElement(id, plan, description) {
    try {
      const res = await TemplateElementService.updateTemplateElement(id, plan, description);
      const newExercises = this._templateElements.map((exercise) => {
        if (exercise.id === res.data.id) {
          return res.data;
        }
        return exercise;
      });
      this._templateElements = newExercises;
      return res;
    } catch (error) {
      return error;
    }
  }
  addExercise(exercise) {
    const newExercises = [...this._templateElements, exercise];
    this._templateElements = newExercises;
  }
  updateExercise(exerciseId, values) {
    const newExercises = this._templateElements.map((element) => {
      if (element.id === exerciseId) {
        element.description = values.description ?? element.description;
        element.plan = values.plan ?? element.plan;
      }
      return element;
    });
    runInAction(() => {
      this._templateElements = newExercises;
    });
  }
  deleteTemplateElement(id) {
    const newExercises = this._templateElements.filter((item) => item.id !== id);
    this._templateElements = newExercises;
  }
}
