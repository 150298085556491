// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pass-reset-card {
  width: 100%;
  font-weight: lighter;
}
.pass-reset-card-title {
  font-size: 11px;
  margin-bottom: 10px;
  font-weight: 600;
}
.pass-reset-card-container {
  margin-bottom: 50px;
}
.pass-reset-steps {
  font-size: 11px;
}
.pass-reset-instructions {
  background-color: #769ccd !important;
  color: #fff !important;
  font-size: 10px !important;
}
.pass-reset-form-container {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.pass-reset-form-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.pass-reset-form-button {
  font-size: 11px;
  width: 100%;
  background-color: #769ccd;
  color: #fff;
}
.pass-reset-form-button:hover {
  background-color: #fff !important;
  border-color: #769ccd !important;
  color: #769ccd !important;
}
.pass-reset-card-footer {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pass-reset-card-footer-text {
  font-size: 11px;
  font-weight: lighter;
  vertical-align: bottom;
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/client-auth/pass-reset-drawer/pass-reset-drawer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,oCAAoC;EACpC,sBAAsB;EACtB,0BAA0B;AAC5B;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,WAAW;EACX,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,iCAAiC;EACjC,gCAAgC;EAChC,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":[".pass-reset-card {\n  width: 100%;\n  font-weight: lighter;\n}\n.pass-reset-card-title {\n  font-size: 11px;\n  margin-bottom: 10px;\n  font-weight: 600;\n}\n.pass-reset-card-container {\n  margin-bottom: 50px;\n}\n.pass-reset-steps {\n  font-size: 11px;\n}\n.pass-reset-instructions {\n  background-color: #769ccd !important;\n  color: #fff !important;\n  font-size: 10px !important;\n}\n.pass-reset-form-container {\n  height: 50vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 20px;\n}\n.pass-reset-form-icon {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 30px;\n}\n.pass-reset-form-button {\n  font-size: 11px;\n  width: 100%;\n  background-color: #769ccd;\n  color: #fff;\n}\n.pass-reset-form-button:hover {\n  background-color: #fff !important;\n  border-color: #769ccd !important;\n  color: #769ccd !important;\n}\n.pass-reset-card-footer {\n  height: 10vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.pass-reset-card-footer-text {\n  font-size: 11px;\n  font-weight: lighter;\n  vertical-align: bottom;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
