import { makeAutoObservable } from 'mobx';
import { UserService } from '../../services';

export default class UserStore {
  isLoading = false;
  _users = [];
  _user = {};

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setUsers(users) {
    this._users = users;
  }
  setUser(user) {
    this._user = user;
  }
  get users() {
    return this._users;
  }
  get user() {
    return this._user;
  }
  async getAllUsers() {
    this.setLoading(true);
    try {
      const res = await UserService.getAllUsers();
      this.setUsers(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getAllEmployeeUsers() {
    this.setLoading(true);
    try {
      const res = await UserService.getAllEmployeeUsers();
      this.setUsers(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getUserById(id) {
    try {
      const res = await UserService.getUserById(id);
      return res.data;
    } catch (error) {
      return error;
    }
  }
  async getUserByEmailOrPhone(emailOrPhone) {
    try {
      const res = await UserService.getUserByEmailOrPhone(emailOrPhone);
      this.setUser(res.data);
    } catch (error) {
      return error;
    }
  }
  async updateUser(id, email, phone, password) {
    try {
      const res = await UserService.updateUser(id, email, phone, password);
      this.setUser(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async updateUserPassword(id, password) {
    try {
      const res = await UserService.updateUserPassword(id, password);
      this.setUser(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async updateUserAgreement(id, agreement) {
    try {
      const res = await UserService.updateUserAgreement(id, agreement);
      this.setUser(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
}
