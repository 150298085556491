import { APP_CATALOG_FORM_FIELDS, APP_TABLE_COLUMNS } from '../config';

export const getCatalogFields = (type) => {
  switch (type) {
    case 'exercise':
      return APP_CATALOG_FORM_FIELDS.exerciseFields;
    case 'location':
      return APP_CATALOG_FORM_FIELDS.locationFields;
    case 'workout-profiles':
      return APP_CATALOG_FORM_FIELDS.workoutProfile;
    default:
      return;
  }
};

export const getCatalogColumn = (type) => {
  switch (type) {
    case 'exercise':
      return APP_TABLE_COLUMNS.exerciseTableTC;
    case 'location':
      return APP_TABLE_COLUMNS.locationTableTC;
    case 'workout-profiles':
      return APP_TABLE_COLUMNS.workoutProfileTableTC;
    default:
      return;
  }
};

export const getCatalogTitle = (type) => {
  switch (type) {
    case 'exercise':
      return 'Справочник упражнений';
    case 'location':
      return 'Справочник локаций';
    case 'workout-profiles':
      return 'Справочник профилей тренировки';
    default:
      return;
  }
};
