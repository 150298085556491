import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './reference-block.css';

const ReferenceBlock = ({ link, linkedText, text }) => {
  return (
    <>
      <p className="form-reference">
        {text}
        <Link to={link} className="sign-in-form-link">
          {' '}
          {linkedText}
        </Link>
      </p>
    </>
  );
};

ReferenceBlock.propTypes = {
  text: PropTypes.string,
  linkedText: PropTypes.string,
  link: PropTypes.string
};

export default ReferenceBlock;
