import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, Button, Card, Form, Input } from 'antd';
import { CLIENT_PATHS } from '../../../../constants';
import { LogoBlock, ReferenceBlock } from '../index';
import { Context } from '../../../../index';
import { openNotification } from '../../../../utils';
import { APP_AUTH_FORM_FIELDS } from '../../../../config';
import './signup-form.css';

const SignUpForm = () => {
  const { auth } = useContext(Context);
  const { signUpClientFields } = APP_AUTH_FORM_FIELDS;
  const [partnerError, setPartnerError] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.search === '') {
      setPartnerError(true);
    }
  }, []);

  const onFinish = async (value) => {
    const partnerId = location.search.slice(4);
    const res = await auth.signupClient(
      value.email.toLowerCase(),
      value.phone,
      value.password,
      partnerId
    );
    if (res) {
      openNotification(res);
    }
  };

  return (
    <div className="columnFormWrapper">
      <div className="sign-up-logo-container">
        {!partnerError ? (
          <LogoBlock />
        ) : (
          <Alert
            className="sign-up-logo-alert"
            type="error"
            message="Идентификация партнера невозможна. Пожалуйста, используйте правильную ссылку."
            banner
          />
        )}
      </div>
      <Card title="Регистрация" bordered={true} className="auth-card">
        <div className="auth-card-content">
          <Form
            name="normal_login"
            className="sign-up-form"
            disabled={partnerError}
            onFinish={onFinish}
            autoComplete="off">
            {signUpClientFields &&
              signUpClientFields.map((field) => (
                <Form.Item
                  hasFeedback
                  validateDebounce={1000}
                  key={field.name}
                  name={field.name}
                  rules={field.rules}
                  validateTrigger="onBlur">
                  <Input
                    allowClear
                    maxLength={field.maxLength ?? undefined}
                    prefix={field.icon}
                    type={field.type}
                    placeholder={field.placeholder}
                  />
                </Form.Item>
              ))}
            <Form.Item>
              <div className="form-button-container">
                <ReferenceBlock
                  link={CLIENT_PATHS.SIGNIN_CLIENT_ROUTE}
                  linkedText="Авторизация"
                  text="Есть аккаунт?"
                />
                <Button
                  disabled={partnerError}
                  type="primary"
                  htmlType="submit"
                  className="sig-up-form-button">
                  Зарегистрироваться
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default SignUpForm;
