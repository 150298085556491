// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-content {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #dddddd;
}
.success-avatar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.avatar-outer-border {
  border: 3px solid #9fb9dc;
  border-radius: 50px;
}
.success-avatar {
  border: 3px solid #71e1b0;
  padding: 5px;
}
.success-text {
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}
.success-btn-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-btn {
  width: 100%;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/recording/success-message/success-message.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".success-content {\n  padding: 10px;\n  border-radius: 10px;\n  border: 1px solid #dddddd;\n}\n.success-avatar-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n}\n.avatar-outer-border {\n  border: 3px solid #9fb9dc;\n  border-radius: 50px;\n}\n.success-avatar {\n  border: 3px solid #71e1b0;\n  padding: 5px;\n}\n.success-text {\n  font-size: 12px;\n  text-align: center;\n  margin-top: 20px;\n}\n.success-btn-container {\n  margin-top: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.success-btn {\n  width: 100%;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
