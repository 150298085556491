const NEW = 'NEW';
const ASSIGN = 'ASSIGN';
const DONE = 'DONE';
const CANCEL = 'CANCEL';
const CLOSED = 'CLOSED';
export const WORKOUT_STATUS = {
  NEW,
  ASSIGN,
  DONE,
  CANCEL,
  CLOSED
};
