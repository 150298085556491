import $api from '../../http';

export default class WorkoutProfileService {
  static async createWorkoutProfile(name, description, appointment) {
    return $api.post('/workout-profiles/', { name, description, appointment });
  }
  static async getAllWorkoutProfiles() {
    return $api.get('/workout-profiles/');
  }
  static async getWorkoutProfileById(id) {
    return $api.get(`/workout-profiles/${id}`);
  }
  static async updateWorkoutProfile(id, name, description, appointment) {
    return $api.patch(`/workout-profiles/${id}`, { name, description, appointment });
  }
  static async deleteWorkoutProfileById(id) {
    return $api.delete(`/workout-profiles/${id}`);
  }
}
