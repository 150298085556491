// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-agreement-row {
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  max-height: 80vh;
  height: 100%;
  overflow-y: auto;
}
.user-agreement-col {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-agreement-content-col {
  max-height: 75vh;
  height: 100%;
}
.user-agreement-card {
  height: 72vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/user-agreement-page/user-agreement-page.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,YAAY;AACd","sourcesContent":[".user-agreement-row {\n  padding: 10px 20px;\n  background-color: #ffffff;\n  border-radius: 10px;\n  max-height: 80vh;\n  height: 100%;\n  overflow-y: auto;\n}\n.user-agreement-col {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.user-agreement-content-col {\n  max-height: 75vh;\n  height: 100%;\n}\n.user-agreement-card {\n  height: 72vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
