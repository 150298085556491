import React, { useContext, useState } from 'react';
import { Button, Card, List } from 'antd';
import WorkoutListItem from './WorkoutListItem';
import { SearchOutlined } from '@ant-design/icons';
import { Context } from '../../../../../index';
import WorkoutListHeader from './WorkoutListHeader';
import HistoryDrawer from './history-drawer/HistoryDrawer';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import './workout-list.css';
import SmallSpinner from '../../../../common/Layout/Spinner/SmallSpinner';

const WorkoutList = observer(({ showModal }) => {
  const { templateElement } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <Card bordered>
      {loading ? (
        <SmallSpinner />
      ) : (
        <>
          <div className="drawer-btn-wrapper">
            <Button
              icon={<SearchOutlined />}
              size="small"
              className="workout-drawer-btn"
              onClick={showDrawer}>
              История
            </Button>
          </div>
          <HistoryDrawer open={open} setOpen={setOpen} />
          <WorkoutListHeader setLoading={setLoading} showModal={showModal} />
          <List
            className="exercise-list"
            bordered
            dataSource={templateElement.templateElements}
            renderItem={(item) => <WorkoutListItem item={item} showModal={showModal} />}
          />
        </>
      )}
    </Card>
  );
});

WorkoutList.propTypes = {
  showModal: PropTypes.func
};

export default WorkoutList;
