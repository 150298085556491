import { Avatar } from 'antd';
import { aida, molchanovs } from '../../../../../assets/images';
import { useContext } from 'react';
import { Context } from '../../../../../index';
import PropTypes from 'prop-types';

const StaItem = ({ value, description, color }) => {
  const { user } = useContext(Context);
  // eslint-disable-next-line no-unused-vars,no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;
  const avatarSrc = (desc) => {
    switch (desc) {
      case 'AIDA':
        return aida;
      case 'Molchanovs':
        return molchanovs;
      default:
        return `${URL}static/avatars/${user.user.client?.img}`;
    }
  };
  return (
    <>
      <div className="standards-item-container">
        <div>
          <Avatar className="standards-item-img" src={avatarSrc(description)} size="5vw" />
          <div className="standards-item-value" style={{ color: color }}>
            {value}
          </div>
        </div>
        <div className="standards-item-description" style={{ color: color }}>
          {description}
        </div>
      </div>
    </>
  );
};

StaItem.propTypes = {
  value: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string
};

export default StaItem;
