const SIGNUP_CLIENT_ROUTE = '/signup';
const SIGNIN_CLIENT_ROUTE = '/signin';
const CALENDAR_CLIENT_ROUTE = '/calendar';
const TRAINING_CLIENT_ROUTE = '/training';
const COMPETITION_CLIENT_ROUTE = '/competition';
const PAYMENT_CLIENT_ROUTE = '/payment';
const NOTIFICATION_CLIENT_ROUTE = '/notification';
const SETTINGS_CLIENT_ROUTE = '/settings';
const FAQ_CLIENT_ROUTE = '/faq';
const LEARNING_ROUTE = '/online-learning';
const RECORDING_ROUTE = '/client-recording';
const RESET_ROUTE = '/password-reset/:userId';

export const CLIENT_PATHS = {
  SIGNUP_CLIENT_ROUTE,
  SIGNIN_CLIENT_ROUTE,
  CALENDAR_CLIENT_ROUTE,
  COMPETITION_CLIENT_ROUTE,
  TRAINING_CLIENT_ROUTE,
  PAYMENT_CLIENT_ROUTE,
  NOTIFICATION_CLIENT_ROUTE,
  SETTINGS_CLIENT_ROUTE,
  FAQ_CLIENT_ROUTE,
  LEARNING_ROUTE,
  RECORDING_ROUTE,
  RESET_ROUTE
};
