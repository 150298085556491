import AuthService from './auth/auth.service';
import CalendarService from './calendar/calendar.service';
import ClientService from './client/client.service';
import EmployeeService from './partner/employee/employee.service';
import ExerciseService from './exercise/exercise.service';
import TemplateService from './template/template.service';
import TemplateElementService from './template-element/template-element.service';
import UserService from './user/user.service';
import WorkoutService from './workout/workout.service';
import WorkoutElementService from './workout-element/workout-elements.service';
import ClientRecordingService from './client-recording/client-recording.service';
import TicketService from './ticket/ticket.service';
import LocationService from './location/location.service';
import WorkoutProfileService from './workout-profile/workout-profile.service';

export {
  AuthService,
  CalendarService,
  ClientService,
  EmployeeService,
  ExerciseService,
  TemplateService,
  TemplateElementService,
  UserService,
  WorkoutService,
  WorkoutElementService,
  ClientRecordingService,
  TicketService,
  LocationService,
  WorkoutProfileService
};
