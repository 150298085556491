import { useContext } from 'react';
import { Context } from '../../../../../index';
import { Divider, Modal } from 'antd';
import ConfirmedModalHeader from './ConfirmedModalHeader';
import ConfirmedModalBody from './ConfirmedModalBody';
import ConfirmedModalFooter from './ConfirmedModalFooter';
import { openNotification } from '../../../../../utils';
import { WORKOUT_CONFIRM_STATUS, WORKOUT_STATUS } from '../../../../../constants';
import PropTypes from 'prop-types';
import './confirmed-workout-modal.css';

const ConfWorkoutModal = ({ setIsModalOpen, isModalOpen, data }) => {
  const { user, workout } = useContext(Context);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const updateStatusHandler = async () => {
    const res = await workout.updateWorkout(data?.id, WORKOUT_STATUS.CLOSED);
    if (res.status === 200) {
      await workout.getEmployeeWorkouts(user.user.id);
      handleCancel();
      openNotification(res);
    }
  };
  const rejectHandler = async () => {
    const res = await workout.confirmedWorkout(data?.id, WORKOUT_CONFIRM_STATUS.REJECTED);
    if (res.status === 200) {
      updateStatusHandler().catch((e) => console.log(e));
    }
  };

  const confirmHandler = async () => {
    const res = await workout.confirmedWorkout(data?.id, WORKOUT_CONFIRM_STATUS.CONFIRMED);
    if (res.status === 200) {
      updateStatusHandler().catch((e) => console.log(e));
    }
  };

  return (
    <Modal
      title={<h5>Дата: {data?.date}</h5>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      width={500}>
      {!data?.clientData ? (
        <h5>Нет данных...</h5>
      ) : (
        <div className="conf-workout-modal-container">
          <ConfirmedModalHeader data={data} />
          <Divider className="conf-workout-modal-divider" />
          <div>
            {data?.clientData?.exercises
              .sort((a, b) => a.id - b.id)
              .map((item, index) => (
                <ConfirmedModalBody key={item.id} item={item} index={index + 1} />
              ))}
            <ConfirmedModalFooter
              data={data}
              confirmHandler={confirmHandler}
              rejectHandler={rejectHandler}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

ConfWorkoutModal.propTypes = {
  setIsModalOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
  data: PropTypes.object
};

export default ConfWorkoutModal;
