import React from 'react';
import { Avatar, Button, DatePicker, Form, Select } from 'antd';
import DynamicField from '../../template-settings/create-template/DynamicField';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { Context } from '../../../../index';
import { openNotification, selectLocations, selectUserData } from '../../../../utils';
import { WORKOUT_SELECT } from '../../../../config';
import { WORKOUT_TYPE } from '../../../../constants';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

dayjs.locale('ru');

const PersonalWorkoutForm = () => {
  const { user, workout, workoutElement, location } = useContext(Context);
  const [form] = Form.useForm();
  const [userAvatar, setUserAvatar] = useState('no-image.jpeg');
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;
  const selectClient = selectUserData(user.users);

  const selectUserHandler = (e) => {
    const currentUser = user.users.filter((user) => user.id === e)[0];
    setUserAvatar(currentUser.client.img);
  };
  const checkDate = (current) => {
    if (current < dayjs()) {
      return current.isBefore(dayjs().subtract(1, 'day'));
    }
    return current.isBefore(dayjs(new Date()));
  };
  const handleFinish = async (values) => {
    const res = await workout.createWorkout(
      user.user.id,
      values.userId,
      values.location,
      values.date,
      values.time,
      WORKOUT_TYPE.PERSONAL
    );
    values.fields.forEach((element) => {
      workoutElement.createWorkoutElement(
        res.data.id,
        element.exerciseId,
        element.plan,
        0,
        element.description
      );
    });
    setUserAvatar('no-image.jpeg');
    openNotification(res);
    form.resetFields();
  };

  return (
    <Form className="personal-workout-form" form={form} onFinish={handleFinish}>
      <div className="form-static-fields-wrapper">
        <div className="form-avatar-wrapper">
          <Avatar
            className="header-avatar-img"
            src={`${URL}static/avatars/${userAvatar}`}
            size={80}
          />
        </div>
        <div className="form-static-fields-group">
          <Form.Item name="userId" className="static-fields">
            <Select
              onSelect={(e) => selectUserHandler(e)}
              onClear={() => setUserAvatar('no-image.jpeg')}
              size="small"
              dropdownStyle={{ fontSize: 11 }}
              placeholder="Спортсмен"
              allowClear
              options={selectClient}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              showSearch
            />
          </Form.Item>
          <Form.Item name="date" className="date-field-wrapper">
            <DatePicker
              className="date-field"
              size="small"
              disabledDate={(current) => checkDate(current)}
              locale={locale}
              format="DD-MM-YYYY"
            />
          </Form.Item>
          <Form.Item name="time" className="static-fields">
            <Select
              size="small"
              dropdownStyle={{ fontSize: 11 }}
              placeholder="Время тренировки"
              allowClear
              options={WORKOUT_SELECT.workoutTimes}
            />
          </Form.Item>
          <Form.Item name="location" className="static-fields">
            <Select
              size="small"
              dropdownStyle={{ fontSize: 11 }}
              placeholder="Локация тренировки"
              allowClear
              options={selectLocations(location.locations)}
            />
          </Form.Item>
        </div>
      </div>
      <div className="form-dynamic-fields-wrapper">
        <DynamicField />
      </div>
      <Form.Item>
        <Button
          className="form-create-btn"
          icon={<AppstoreAddOutlined />}
          type="primary"
          htmlType="submit">
          Создать тренировку
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PersonalWorkoutForm;
