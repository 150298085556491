import { Alert, Card, Col, Table } from 'antd';
import PageLabel from '../../../common/PageLabel/PageLabel';
import { Context } from '../../../../index';
import TrainingCardHeader from './training-card-header/TrainingCardHeader';
import TrainingModal from './training-modal/TrainingModal';
import { useContext, useEffect, useState } from 'react';
import TrainingCommentForm from './TrainingCommentForm';
import { getClientWorkoutData, getTrainerData } from '../../../../utils';
import { SmallSpinner } from '../../../index';
import { WORKOUT_STATUS } from '../../../../constants';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import './training-card.css';
import React from 'react';

const TrainingCard = ({ title, columns, status, img }) => {
  const { user, workout, workoutElement } = useContext(Context);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentExercise, setCurrentExercise] = useState({});
  const [data, setData] = useState([]);
  useEffect(() => {
    const getBaseDate = async () => {
      await workout.getClientWorkouts(user.user.id);
      setData(workout.workouts);
    };
    getBaseDate().catch((error) => console.log(error));
  }, [workoutElement.workoutElement, workout.workouts.length]);

  const { actualWorkout, exercisesData } = getClientWorkoutData(data, status);
  const trainerData = getTrainerData(user.users, actualWorkout?.employeeId);

  const tableHandler = (exercise) => {
    if (status === WORKOUT_STATUS.DONE || status === WORKOUT_STATUS.CLOSED) {
      return;
    }
    setCurrentExercise(exercise);
    setIsModalOpen(true);
  };

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      {workout.isLoading ? (
        <SmallSpinner />
      ) : (
        <Card
          className="training-card"
          title={<PageLabel text={title} img={img} />}
          extra={
            <span className="training-card-title">
              {actualWorkout?.date
                ? `${actualWorkout.time} ${dayjs(actualWorkout.date).format('DD-MM-YYYY')}`
                : dayjs().format('DD-MM-YYYY')}
            </span>
          }>
          <TrainingCardHeader
            location={actualWorkout?.location ?? 'Нет данных'}
            data={trainerData?.employee}
          />
          <Table
            onRow={(r) => ({
              onClick: () => tableHandler(r)
            })}
            columns={columns}
            dataSource={exercisesData ?? []}
            pagination={false}
            className="exercise-list-table"
          />
          {status === WORKOUT_STATUS.ASSIGN ? (
            <TrainingCommentForm actualWorkout={actualWorkout} />
          ) : (
            <Alert
              style={{ marginTop: 10, fontSize: 11 }}
              message={actualWorkout?.comment ?? 'Нет комментария'}
              type="info"
            />
          )}
        </Card>
      )}
      {status === WORKOUT_STATUS.ASSIGN && (
        <TrainingModal
          isModalOpen={isModalOpen}
          data={currentExercise}
          setIsModalOpen={setIsModalOpen}
          setCurrentExercise={setCurrentExercise}
        />
      )}
    </Col>
  );
};

TrainingCard.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  status: PropTypes.string,
  img: PropTypes.string
};

export default observer(TrainingCard);
