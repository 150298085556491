import { Button, Form, Input } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Context } from '../../../../index';
import { openNotification } from '../../../../utils';
import { APP_PROFILE_FORM_FIELDS } from '../../../../config';
import { observer } from 'mobx-react-lite';
import './profile-form.css';

const ProfileForm = ({ currentUser, setOpen, setCurrentUser }) => {
  const { employee, client, user } = useContext(Context);
  const userType = localStorage.getItem('type') === 'partner';
  const { profilePartnerFields, profileClientFields } = APP_PROFILE_FORM_FIELDS;
  const formFields = userType ? profilePartnerFields : profileClientFields;
  const initialState = userType
    ? {
        name: currentUser?.name,
        middleName: currentUser?.middleName,
        surname: currentUser?.surname,
        position: currentUser?.position,
        level: currentUser?.level
      }
    : {
        name: currentUser?.name,
        middleName: currentUser?.middleName,
        surname: currentUser?.surname,
        level: currentUser?.level
      };

  const clientHandler = async (values, userData) =>
    await client.updateClient(
      userData.id,
      values.name,
      values.middleName,
      values.surname,
      values.level
    );
  const partnerHandler = async (values, userData) =>
    await employee.updateEmployee(
      userData.id,
      values.name,
      values.middleName,
      values.surname,
      values.position,
      values.level
    );
  const onFinish = async (values) => {
    const res = userType
      ? await partnerHandler(values, currentUser)
      : await clientHandler(values, currentUser);
    if (res.status === 200) {
      await user.getUserByEmailOrPhone(user.user.email);
      setCurrentUser(userType ? user.user.employee : user.user.client);
      setOpen(false);
      openNotification(res);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      initialValues={initialState}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="form-profile-container">
      <div className="form-profile-column">
        {formFields &&
          formFields.map((field) => (
            <Form.Item
              className="form-profile-field"
              validateDebounce={1000}
              key={field.name}
              name={field.name}
              rules={[field.rules]}
              validateTrigger="onBlur">
              <Input prefix={field.icon} type={field.type} placeholder={field.placeholder} />
            </Form.Item>
          ))}
      </div>
      <div className="form-profile-second-column">
        <Form.Item
          name="level"
          rules={[
            {
              required: true,
              message: 'Укажите ваши достижения!'
            }
          ]}>
          <Input.TextArea rows={4} style={{ marginBottom: 10 }} />
        </Form.Item>
        <Button
          className="form-profile-button"
          icon={<SaveOutlined />}
          type="primary"
          htmlType="submit"
          ghost>
          Сохранить и выйти
        </Button>
      </div>
    </Form>
  );
};

ProfileForm.propTypes = {
  setOpen: PropTypes.func,
  setCurrentUser: PropTypes.func,
  currentUser: PropTypes.object
};

export default observer(ProfileForm);
