import { Button, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { Context } from '../../../../../index';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

const EditExerciseForm = ({ editExercise, setIsModalOpen, handleCancel }) => {
  const { templateElement } = useContext(Context);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(editExercise);
  }, [editExercise, templateElement.templateElement]);

  const onFinish = async (values) => {
    if (!editExercise.templateId) {
      templateElement.updateExercise(editExercise.id, values);
    } else {
      const res = await templateElement.updateTemplateElement(
        +templateElement.templateElement.id,
        +values.plan,
        values.description
      );
      if (res) {
        handleOk();
      }
    }
    handleOk();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const handleOk = () => {
    form.resetFields();
    templateElement.setTemplateElement({});
    setIsModalOpen(false);
  };
  const closeHandler = () => {
    handleCancel();
    templateElement.setTemplateElement({});
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item name="plan">
        <Input placeholder="Плановая дистанция" />
      </Form.Item>
      <Form.Item name="description">
        <TextArea placeholder="Условия выполнения" rows={4} />
      </Form.Item>
      <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          danger
          icon={<CloseOutlined />}
          size="small"
          type="default"
          onClick={closeHandler}
          className="exercise-cancel-btn">
          Отмена
        </Button>
        <Button
          icon={<SaveOutlined />}
          size="small"
          type="primary"
          className="exercise-add-btn"
          htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
};

EditExerciseForm.propTypes = {
  editExercise: PropTypes.object,
  setIsModalOpen: PropTypes.func,
  handleCancel: PropTypes.func
};

export default observer(EditExerciseForm);
