import React, { useState, useContext, useEffect } from 'react';
import AgreementModal from './components/common/AgreementModal/AgreementModal';
import { BrowserRouter as Router } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AppRouter, Spinner } from './components';
import { Context } from './index';

import './App.css';

function App() {
  const { auth, user } = useContext(Context);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => await auth.checkAuth();
    const getUser = async (email = undefined) => {
      let currentEmail = email ?? JSON.parse(sessionStorage.getItem('ddu'))?.reverse().join('');
      await user.getUserByEmailOrPhone(currentEmail);
      if (user.user.id && !user.user.agreement && auth.isAuth) {
        setIsModalOpen(true);
      }
    };
    if (localStorage.getItem('token')) {
      checkAuthStatus()
        .then((data) => {
          if (!data) {
            localStorage.getItem('type') === 'partner'
              ? getUser(auth.partner.email).catch(console.error)
              : getUser(auth.client.email).catch(console.error);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [auth.isAuth]);

  if (auth.isLoading) {
    return <Spinner />;
  }

  return (
    <Router>
      <AppRouter />
      <AgreementModal open={isModalOpen} modalHandler={setIsModalOpen} />
    </Router>
  );
}

export default observer(App);
