import React from 'react';
import { Row } from 'antd';
import TrainingCard from '../../components/training/client-training/training-card/TrainingCard';
import InformerBlock from '../../components/training/client-training/analitic-block/informer-block/InformerBlock';
import { APP_TABLE_COLUMNS } from '../tables/training-card-tables';
import { WORKOUT_STATUS } from '../../constants';
import WorkoutList from '../../components/training/client-training/workout-list/WorkoutList';
import { activeWorkout, exercise, template } from '../../assets/icons';
import AnaliticBlock from '../../components/training/client-training/analitic-block/AnaliticBlock';
import InProgress from '../../components/common/InProgress/InProgress';

const tabItems = [
  {
    key: '1',
    label: 'Статистика тренировок',
    children: (
      <>
        <Row gutter={16} style={{ marginBottom: 10 }}>
          <InformerBlock />
        </Row>
        <Row gutter={10}>
          <AnaliticBlock />
        </Row>
      </>
    )
  },
  {
    key: '2',
    label: 'Контроль результатов',
    children: (
      <Row gutter={10}>
        <TrainingCard
          status={WORKOUT_STATUS.CLOSED}
          title="Прошедшая тренировка"
          columns={APP_TABLE_COLUMNS.lastTrainingTC}
          img={exercise}
        />
        <TrainingCard
          status={WORKOUT_STATUS.ASSIGN}
          title="Активная тренировка"
          columns={APP_TABLE_COLUMNS.lastTrainingTC}
          img={activeWorkout}
        />
      </Row>
    )
  },
  {
    key: '3',
    label: 'История тренировок',
    children: (
      <Row gutter={10}>
        <WorkoutList />
      </Row>
    )
  },
  {
    key: '4',
    label: 'Постановка целей',
    children: <InProgress maxHeight={72} img={template} title="Постановка целей" />
  }
];

export const APP_CLIENT_TRAINING_TABS = { tabItems };
