import { makeAutoObservable } from 'mobx';
import { WorkoutElementService } from '../../services';

export default class WorkoutElementStore {
  isLoading = false;
  _workoutElement = {};
  _workoutElements = [];

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setWorkoutElements(workoutElements) {
    this._workoutElements = workoutElements;
  }
  setWorkoutElement(workoutElement) {
    this._workoutElement = workoutElement;
  }
  get workoutElements() {
    return this._workoutElements;
  }
  get workoutElement() {
    return this._workoutElement;
  }

  async createWorkoutElement(workoutId, exerciseId, plan, fact = 0, description) {
    try {
      const res = await WorkoutElementService.createWorkoutElement(
        workoutId,
        exerciseId,
        plan,
        fact,
        description
      );
      await this.getAllWorkoutElements();
      this.setWorkoutElement(res.data);
    } catch (error) {
      return error;
    }
  }
  async getAllWorkoutElements() {
    this.setLoading(true);
    try {
      const res = await WorkoutElementService.getAllWorkoutElements();
      this.setWorkoutElements(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getWorkoutElementById(id) {
    try {
      const res = await WorkoutElementService.getWorkoutElementById(id);
      this.setWorkoutElement(res.data);
    } catch (error) {
      return error;
    }
  }
  async updateWorkoutElement(id, fact) {
    try {
      const res = await WorkoutElementService.updateWorkoutElement(id, fact);
      this.setWorkoutElement(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
}
