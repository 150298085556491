// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rec-page-container {
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(circle, rgba(113, 225, 176, 1) 0%, rgba(148, 187, 233, 1) 100%);
}
.rec-page-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/recording/recording-page.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,2FAA2F;AAC7F;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".rec-page-container {\n  width: 100%;\n  min-height: 100vh;\n  background: radial-gradient(circle, rgba(113, 225, 176, 1) 0%, rgba(148, 187, 233, 1) 100%);\n}\n.rec-page-column {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
