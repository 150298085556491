import { Avatar, Button, Card } from 'antd';
import { rec } from '../../../assets/images';

import PropTypes from 'prop-types';
import { diving } from '../../../assets/icons';
import './success-message.css';

const SuccessMessage = ({ successHandler, setChecked, trainerPhoto }) => {
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;

  const resetHandler = () => {
    successHandler(false);
    setChecked(false);
  };

  return (
    <Card
      className="rec-form-card"
      cover={<img alt="example" src={rec} className="rec-card-img" />}>
      <div className="success-content">
        <h4 style={{ textAlign: 'center' }}>Вы успешно записаны!</h4>
        <div className="success-avatar-wrapper">
          <div className="avatar-outer-border">
            <Avatar
              className="success-avatar"
              src={trainerPhoto ? `${URL}static/avatars/${trainerPhoto}` : diving}
              size={84}
            />
          </div>
        </div>
        <div className="success-text">До встречи на тренировке 👍</div>
      </div>
      <div className="success-btn-container">
        <Button className="success-btn" onClick={resetHandler}>
          Закрыть
        </Button>
      </div>
    </Card>
  );
};

SuccessMessage.propTypes = {
  successHandler: PropTypes.func,
  setChecked: PropTypes.func,
  trainerPhoto: PropTypes.string
};

export default SuccessMessage;
