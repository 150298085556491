import React from 'react';
import { useEffect, useState } from 'react';
import { Card, Col } from 'antd';
import CardHeader from '../../card-header/CardHeader';
import TimeBlockWrapper from './TimeBlockWrapper';
import StaItem from './StaItem';
import Jotting from './Jotting';
import { staStandards } from '../../../../../config/segments/levels-standards';
import PropTypes from 'prop-types';
import './visual-time-block.css';

const VisualTimeBlock = ({ result }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(staStandards.one);
  }, []);

  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ flex: 1 }}>
      <Card>
        <CardHeader type="time" title="Нормативы STA" data={staStandards} dataHandler={setData} />
        <TimeBlockWrapper>
          <StaItem value={data.aida} description="AIDA" color="#FF9999" />
          <StaItem value={data.molchanovs} description="Molchanovs" color="#82ca9d" />
          <StaItem value={result} description="Personal Best" color="#5a8ebd" />
        </TimeBlockWrapper>
      </Card>
      <Jotting />
    </Col>
  );
};

VisualTimeBlock.propTypes = {
  result: PropTypes.string
};

export default VisualTimeBlock;
