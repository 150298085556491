import { Avatar, Button, List } from 'antd';
import { diving } from '../../../../../assets/icons';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Context } from '../../../../../index';
import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';

const WorkoutListItem = observer(({ item, showModal }) => {
  const { templateElement, exercise } = useContext(Context);
  const exerciseData = exercise.exercises.filter((ex) => ex.id === item.exerciseId);

  return (
    <List.Item className="list-item-wrapper">
      <List.Item.Meta avatar={<Avatar src={diving} />} />
      <div className="list-item-content">
        <div className="list-item-description">
          <div>
            <b>Упражнение: </b> {exerciseData[0]?.name}
          </div>
          <div>
            <b>Дистанция: </b>
            {item.plan}
          </div>
        </div>
        <div className="exercise-description">
          <b>Описание: </b>
          {item.description}
        </div>
      </div>
      <Button
        icon={<EditOutlined />}
        className="exercise-edit-button"
        type="primary"
        shape="circle"
        ghost
        onClick={() => {
          templateElement.setTemplateElement(item);
          showModal();
        }}
      />
      <Button
        icon={<DeleteOutlined />}
        className="exercise-remove-button"
        danger
        shape="circle"
        onClick={() => templateElement.deleteTemplateElement(item.id)}
      />
    </List.Item>
  );
});

WorkoutListItem.propTypes = {
  item: PropTypes.object,
  showModal: PropTypes.func
};

export default WorkoutListItem;
