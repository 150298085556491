import { Avatar, Drawer, Space, Divider, Pagination } from 'antd';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../../index';
import { WorkoutItem } from '../../../../client-training/workout-list/WorkoutItem';
import { APP_TABLE_COLUMNS } from '../../../../../../config';
import { noAvatar } from '../../../../../../assets/images';
import { WORKOUT_STATUS } from '../../../../../../constants';
import { SmallSpinner } from '../../../../../index';
import PageLabel from '../../../../../common/PageLabel/PageLabel';
import { history } from '../../../../../../assets/icons';
import './history-drawer.css';
import { observer } from 'mobx-react-lite';
import React from 'react';

const HistoryDrawer = ({ open, setOpen }) => {
  const { workout } = useContext(Context);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;
  useEffect(() => {
    if (!workout.workoutClientInfo.id) {
      return;
    }
    const getBaseDate = async () => {
      await workout.getClientWorkouts(workout.workoutClientInfo.id);
    };
    getBaseDate()
      .then(() => {
        const completedWorkouts = workout.workouts.filter(
          (item) => item.status === WORKOUT_STATUS.CLOSED
        );
        setData(completedWorkouts.reverse());
      })
      .catch((error) => console.log(error));
  }, [workout.counnt, workout.workoutClientInfo.id]);
  const onClose = () => {
    setOpen(false);
  };
  const paginationHandler = (e) => {
    setCurrentPage(e);
  };

  return (
    <Drawer
      title={<PageLabel text="История тренировок" img={history} />}
      placement="right"
      onClose={onClose}
      open={open}
      width={500}>
      <Space>
        <div>
          <Avatar
            size={60}
            src={
              workout.workoutClientInfo?.img
                ? `${URL}static/avatars/${workout.workoutClientInfo.img}`
                : noAvatar
            }
          />
        </div>
        <div className="history-user-container">
          <p className="history-user-name">{workout.workoutClientInfo.name}</p>
          <p>{workout.workoutClientInfo.surname}</p>
        </div>
      </Space>
      <Divider />
      {workout.isLoading ? (
        <SmallSpinner />
      ) : (
        <>
          <div className="history-user-content">
            {data.length < 1 ? (
              <div className="history-no-content">
                <p>Нет данных...</p>
              </div>
            ) : (
              data
                .slice(currentPage * 3 - 3, currentPage * 3)
                .map((item) => (
                  <WorkoutItem
                    currentStyle="50vh"
                    key={Math.random()}
                    data={item}
                    columns={APP_TABLE_COLUMNS.lastTrainingTC}
                  />
                ))
            )}
          </div>
          <div className="history-pagination-container">
            <Pagination
              onChange={(e) => paginationHandler(e)}
              size="small"
              defaultCurrent={currentPage}
              defaultPageSize={5}
              total={data?.length}
            />
          </div>
        </>
      )}
    </Drawer>
  );
};

HistoryDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default observer(HistoryDrawer);
