// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.res-pass-container {
  width: 100%;
  height: 100vh;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(
    211deg,
    rgba(90, 142, 189, 1) 0%,
    rgba(127, 195, 220, 1) 35%,
    rgba(172, 236, 223, 1) 100%
  );
}
.res-pass-card-wrapper {
  padding: 0 10px;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.res-pass-card {
  max-width: 480px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/reset-password/reset-password-page.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,oBAAoB;EACpB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B;;;;;GAKC;AACH;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".res-pass-container {\n  width: 100%;\n  height: 100vh;\n  padding-bottom: 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background: linear-gradient(\n    211deg,\n    rgba(90, 142, 189, 1) 0%,\n    rgba(127, 195, 220, 1) 35%,\n    rgba(172, 236, 223, 1) 100%\n  );\n}\n.res-pass-card-wrapper {\n  padding: 0 10px;\n  width: 100%;\n  height: 80vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.res-pass-card {\n  max-width: 480px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
