import {
  AuthStore,
  CalendarStore,
  ClientStore,
  EmployeeStore,
  ExerciseStore,
  TemplateStore,
  TemplateElementStore,
  UserMenuStore,
  UserStore,
  WorkoutStore,
  WorkoutElementStore,
  WorkoutTemplateStore,
  ClientRecordingStore,
  TicketStore,
  LocationStore,
  WorkoutProfileStore
} from '../../store';

const authClientStore = new AuthStore();
const calendarStore = new CalendarStore();
const clientStore = new ClientStore();
const employeeStore = new EmployeeStore();
const exerciseStore = new ExerciseStore();
const templateStore = new TemplateStore();
const templateElementStore = new TemplateElementStore();
const userMenuStore = new UserMenuStore();
const userStore = new UserStore();
const workoutStore = new WorkoutStore();
const workoutElementStore = new WorkoutElementStore();
const workoutTemplateStore = new WorkoutTemplateStore();
const clientRecordingStore = new ClientRecordingStore();
const ticketStore = new TicketStore();
const locationStore = new LocationStore();
const workoutProfileStore = new WorkoutProfileStore();

export const APP_CONTEXT = {
  authClientStore,
  calendarStore,
  clientStore,
  employeeStore,
  exerciseStore,
  templateStore,
  templateElementStore,
  userMenuStore,
  userStore,
  workoutStore,
  workoutElementStore,
  workoutTemplateStore,
  clientRecordingStore,
  ticketStore,
  locationStore,
  workoutProfileStore
};
