import $api from '../../http';

export default class AuthService {
  static async signupClient(email, phone, password, partnerId) {
    return $api.post('/auth/signup', { email, phone, password, partnerId });
  }

  static async signinClient(email, password) {
    return $api.post('/auth/signin', { email, password });
  }
  static async signinPartner(phone, password) {
    return $api.post('/auth/partner/signin', { phone, password });
  }
  static async googleAuth() {
    return $api.get('/auth/google');
  }
  static async yandexAuth() {
    return $api.get('/auth/yandex');
  }
  static async logout() {
    return $api.get('/auth/logout');
  }
  static async authStatus() {
    return $api.get('/auth/refresh-tokens');
  }
  static async resetPassword(email) {
    return $api.get(`/auth/reset-pass-request/${email}`);
  }
  static async updatePassword(id, password) {
    return $api.patch(`/auth/reset-pass/${id}`, { password });
  }
}
