import { CLIENT_PATHS } from './paths/client-paths';
import { PARTNER_PATHS } from './paths/partner-paths';
import { COMMON_PATHS } from './paths/common-paths';
import { WORKOUT_STATUS } from './workout/workout-status';
import { WORKOUT_TYPE } from './workout/workout-type';
import { RECORDING_STATUS } from './recording/recording-status';
import { WORKOUT_CONFIRM_STATUS } from './workout/workout-confirm-status';
import { USE_OF_TERMS } from './terms/terms';
import { TEMPLATE_SELECTS } from './template/workout-profile';

export {
  CLIENT_PATHS,
  PARTNER_PATHS,
  COMMON_PATHS,
  WORKOUT_STATUS,
  WORKOUT_TYPE,
  RECORDING_STATUS,
  WORKOUT_CONFIRM_STATUS,
  USE_OF_TERMS,
  TEMPLATE_SELECTS
};
