// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.training-card {
  height: 100%;
  overflow-y: auto;
  text-align: left;
  margin-bottom: 10px;
}
.training-card-title {
  color: #6b7a99;
  font-size: 12px;
}
.ant-table table {
  font-size: 10px !important;
}
.ant-table-cell {
  padding: 10px !important;
}
.comment-form-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.comment-form {
  width: 100%;
}
.comment-form-input-wrapper {
  margin-bottom: 10px;
}
.comment-form-input {
  font-size: 11px;
}
.comment-form-btn-wrapper {
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.comment-form-btn {
  font-size: 12px;
}
.exercise-list-table .ant-table-cell {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/client-training/training-card/training-card.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".training-card {\n  height: 100%;\n  overflow-y: auto;\n  text-align: left;\n  margin-bottom: 10px;\n}\n.training-card-title {\n  color: #6b7a99;\n  font-size: 12px;\n}\n.ant-table table {\n  font-size: 10px !important;\n}\n.ant-table-cell {\n  padding: 10px !important;\n}\n.comment-form-wrapper {\n  margin-top: 10px;\n  display: flex;\n  justify-content: flex-end;\n}\n.comment-form {\n  width: 100%;\n}\n.comment-form-input-wrapper {\n  margin-bottom: 10px;\n}\n.comment-form-input {\n  font-size: 11px;\n}\n.comment-form-btn-wrapper {\n  margin-top: 5px;\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n}\n.comment-form-btn {\n  font-size: 12px;\n}\n.exercise-list-table .ant-table-cell {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
