import { EditOutlined, FormOutlined } from '@ant-design/icons';

const createEventFields = [
  {
    name: 'title',
    rules: { required: true, message: 'Укажите навание события!' },
    icon: <EditOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Название события'
  },
  {
    textarea: true,
    name: 'description',
    rules: { required: true, message: 'Добавьте описание события!' },
    icon: <FormOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Описание события (макс. 1200  символов)'
  }
];

const eventSelectType = [
  { value: 'NEWS', label: 'Новости' },
  { value: 'COMPETITION', label: 'Соревнования' },
  { value: 'TRAVELING', label: 'Поездки' },
  { value: 'SALE', label: 'Акции' }
];

export const APP_EVENT_FORM_FIELDS = { createEventFields, eventSelectType };
