// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  max-width: 70%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.card-header-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/client-training/card-header/card-header.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,8BAA8B;EAC9B,YAAY;AACd;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".card-header {\n  max-width: 70%;\n  display: flex;\n  justify-content: space-between;\n  padding: 5px;\n}\n.card-header-title {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
