import $api, { API_URL } from '../../http';

export default class ClientService {
  static async createClient(name, surname, level, userId) {
    return $api.post('/clients/', { name, surname, level, userId });
  }
  static async getAllClients() {
    return $api.get('/clients/');
  }
  static async getClientById(id) {
    return $api.get(`/clients/${id}`);
  }

  static async updateClientAvatar(id, formData) {
    const res = await fetch(`${API_URL}/clients/update-avatar/${id}`, {
      method: 'PATCH',
      body: formData,
      headers: { Authorization: localStorage.getItem('token') }
    });
    return res;
  }
  static async updateClient(id, name, middleName, surname, level) {
    return $api.patch(`/clients/${id}`, { name, middleName, surname, level });
  }
}
