import { Button, Card, Col, Drawer, Form, Image, Input, Row } from 'antd';
import { resPassword, reset } from '../../../../assets/icons';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { Context } from '../../../..';
import PageLabel from '../../../common/PageLabel/PageLabel';
import './pass-reset-drawer.css';
import React from 'react';

const PassResetDrawer = ({ onClose, open }) => {
  const { auth } = useContext(Context);
  const [disabled, setDisabled] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    onClose();
    form.resetFields();
    await auth.resetPassword(values.email.toLowerCase());
  };
  const onFinishFailed = () => {
    setDisabled(true);
    setTimeout(() => {
      form.resetFields();
      setDisabled(false);
    }, 3000);
  };

  return (
    <Drawer
      closeIcon={false}
      title={<PageLabel text="Восстановление пароля" img={reset} />}
      placement="right"
      width={400}
      onClose={onClose}
      open={open}>
      <Card className="pass-reset-card">
        <Row className="pass-reset-card-container">
          <Col>
            <h3 className="pass-reset-card-title">Процесс восстановление пароля:</h3>
            <ol className="pass-reset-steps">
              <li>
                Для процесса восстановления пароля вам понадобится адрес электронной почты,
                указанный при регистрации на платформе.
              </li>
              <li>
                В форме ниже укажите адрес электронной почты и нажмите кнопку{' '}
                <Button className="pass-reset-instructions" size="small" type="primary" disabled>
                  Восстановить пароль
                </Button>
              </li>
              <li>
                На указанный адрес электронной почты будет отправлено письмо с дальнейшими
                иснструкциями.
              </li>
            </ol>
          </Col>
        </Row>
        <Row className="pass-reset-form-container">
          <Col>
            <div className="pass-reset-form-icon">
              <Image src={resPassword} preview={false} alt="reset-password" width={80} />
            </div>
            <Form
              form={form}
              name="email"
              className="sign-in-form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off">
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Пожалуйста, введите свой email!'
                  }
                ]}>
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item>
                <div className="form-button-container">
                  <Button
                    disabled={disabled}
                    className="pass-reset-form-button"
                    type="primary"
                    htmlType="submit">
                    Восстановить пароль
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Drawer>
  );
};

PassResetDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default PassResetDrawer;
