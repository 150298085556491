import { AppCopyright, RecordingForm } from '../../components';
import { Col, Row } from 'antd';
import './recording-page.css';
const RecordingPage = () => {
  return (
    <Row className="rec-page-container">
      <Col className="rec-page-column" xs={24}>
        <RecordingForm />
      </Col>
      <AppCopyright />
    </Row>
  );
};

export default RecordingPage;
