// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-style {
  height: 100vh;
  background-color: #ffffff;
}
.header-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  height: 64px;
  padding-inline: 50px;
  line-height: 64px;
  background-color: #ffffff;
}
.layout-content {
  background-color: #ffffff;
}
.content-style {
  padding: 10px 20px;
  text-align: center;
  min-height: 120px;
  line-height: 120px;
  color: grey;
  border: 5px solid #ffffff;
  border-radius: 10px;
  background-color: rgba(107, 122, 153, 0.06);
}
.sider-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px 0px;
  background-color: #ffffff !important;
}
.footer-style {
  text-align: center;
  color: #6b7a99;
  background-color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Layout/layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,oBAAoB;EACpB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,2CAA2C;AAC7C;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,oCAAoC;AACtC;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,yBAAyB;AAC3B","sourcesContent":[".layout-style {\n  height: 100vh;\n  background-color: #ffffff;\n}\n.header-style {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  color: #ffffff;\n  height: 64px;\n  padding-inline: 50px;\n  line-height: 64px;\n  background-color: #ffffff;\n}\n.layout-content {\n  background-color: #ffffff;\n}\n.content-style {\n  padding: 10px 20px;\n  text-align: center;\n  min-height: 120px;\n  line-height: 120px;\n  color: grey;\n  border: 5px solid #ffffff;\n  border-radius: 10px;\n  background-color: rgba(107, 122, 153, 0.06);\n}\n.sider-style {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n  padding: 10px 0px;\n  background-color: #ffffff !important;\n}\n.footer-style {\n  text-align: center;\n  color: #6b7a99;\n  background-color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
