import React from 'react';
import { Col, Timeline } from 'antd';
import { APP_TABLE_COLUMNS } from '../../../../config';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../index';
import { AimOutlined } from '@ant-design/icons';
import TimelineCard from '../training-timeline/timeline-card/TimelineCard';
import { getTrainerData } from '../../../../utils';
import { WorkoutItem } from './WorkoutItem';
import './workout-list.css';
import { WORKOUT_STATUS } from '../../../../constants';
import { Spinner } from '../../../index';

const WorkoutList = () => {
  const { user, workout } = useContext(Context);
  const [data, setData] = useState([]);
  const [activeItem, setActiveItem] = useState({});
  useEffect(() => {
    const getBaseDate = async () => {
      await workout.getClientWorkouts(user.user.id);
      setData(workout.workouts);
    };
    getBaseDate().catch((error) => console.log(error));
  }, [workout]);

  const setItemHandler = (item) => setActiveItem(item);
  const items = data
    .filter((item) => item.status === WORKOUT_STATUS.CLOSED)
    .map((item) => {
      item['trainer'] = getTrainerData(user.users, item.employeeId)?.employee;
      return {
        dot: <AimOutlined />,
        children: (
          <TimelineCard
            active={item.id === activeItem.id}
            key={item.id}
            setItemHandler={setItemHandler}
            data={item}
          />
        )
      };
    });

  return (
    <>
      <Col xs={24} sm={24} md={12} lg={16} xl={16}>
        <WorkoutItem
          currentStyle="65vh"
          data={activeItem}
          columns={APP_TABLE_COLUMNS.lastTrainingTC}
        />
      </Col>
      <Col
        style={{ border: '1px solid #dddddd', borderRadius: 10, padding: 10 }}
        xs={24}
        sm={24}
        md={12}
        lg={8}
        xl={8}
        className="t-timeline-container">
        <div className="timeline-content-wrapper">
          {workout.isLoading ? <Spinner /> : <Timeline reverse={true} items={items} />}
        </div>
      </Col>
    </>
  );
};

export default WorkoutList;
