import { logo, logoMini } from '../../../../assets/images';
import PropTypes from 'prop-types';

const LogoBlock = ({ width, min }) => {
  return (
    <>
      <img src={min ? logoMini : logo} width={width ?? 250} alt="logo" />
    </>
  );
};

LogoBlock.propTypes = {
  width: PropTypes.number,
  min: PropTypes.bool
};

export default LogoBlock;
