import AuthStore from './auth/auth.store';
import CalendarStore from './calendar/calendar.store';
import ClientStore from './client/client.store';
import EmployeeStore from './partner/employee/employee.store';
import ExerciseStore from './exercise/exercise.store';
import TemplateStore from './template/template.store';
import TemplateElementStore from './template-element/template-element.store';
import UserMenuStore from './menu/user-menu.store';
import UserStore from './user/user.store';
import WorkoutStore from './workout/workout.store';
import WorkoutTemplateStore from './workout-template/workout-template.store';
import WorkoutElementStore from './workout-element/workout-element.store';
import ClientRecordingStore from './client-recording/client-recording.store';
import TicketStore from './ticket/ticket.store';
import LocationStore from './locatoin/location.store';
import WorkoutProfileStore from './workout-profile/workout-profile.store';

export {
  AuthStore,
  CalendarStore,
  ClientStore,
  EmployeeStore,
  ExerciseStore,
  TemplateStore,
  TemplateElementStore,
  UserMenuStore,
  UserStore,
  WorkoutStore,
  WorkoutTemplateStore,
  WorkoutElementStore,
  ClientRecordingStore,
  TicketStore,
  LocationStore,
  WorkoutProfileStore
};
