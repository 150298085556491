import { Avatar } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { selectUserDates } from '../../../../../utils';
import { Context } from '../../../../../index';
import WorkoutListForm from './WorkoutListForm';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import React from 'react';

const WorkoutListHeader = ({ showModal, setLoading }) => {
  const { recording } = useContext(Context);
  const [userAvatar, setUserAvatar] = useState(null);
  const [recRows, setRecRows] = useState([]);
  useEffect(() => {
    const newDates = async () => {};
    newDates()
      .then(() => {
        setRecRows(recording.clientsRecording);
      })
      .catch((e) => console.log(e));
  }, []);

  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;
  const dates = selectUserDates(recRows, true);

  return (
    <>
      <div className="exercise-list-header-wrapper">
        <div className="header-avatar">
          <Avatar
            className="header-avatar-img"
            src={
              userAvatar
                ? `${URL}static/avatars/${userAvatar}`
                : `${URL}static/avatars/no-image.jpeg`
            }
            size={100}
          />
        </div>
        <div className="header-selectors">
          <WorkoutListForm
            setLoading={setLoading}
            showModal={showModal}
            userDates={dates}
            setUserAvatar={setUserAvatar}
          />
        </div>
      </div>
    </>
  );
};

WorkoutListHeader.propTypes = {
  cleanList: PropTypes.bool,
  showModal: PropTypes.func,
  setLoading: PropTypes.func
};

export default observer(WorkoutListHeader);
