import PropTypes from 'prop-types';

const ConfirmedModalBody = ({ item, index }) => {
  return (
    <div className="conf-workout-modal-body">
      <div className="conf-workout-modal-number">
        <p>
          <b>{index}</b>
        </p>
      </div>
      <div className="conf-workout-modal-table">
        <p>
          <b>Упражнение</b>
        </p>
        <p>{item?.exercise?.name}</p>
      </div>
      <div className="conf-workout-modal-row">
        <p>
          <b>План</b>
        </p>
        <p>{item.plan}</p>
      </div>
      <div className="conf-workout-modal-row">
        <p>
          <b>Факт</b>
        </p>
        <p>{item.fact}</p>
      </div>
    </div>
  );
};

ConfirmedModalBody.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number
};

export default ConfirmedModalBody;
