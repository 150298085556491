import React from 'react';
import { Avatar, Button, Card, Divider, Modal, Pagination } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { logoMini } from '../../../../../assets/images';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PropTypes from 'prop-types';
import { useState } from 'react';
import dayjs from 'dayjs';
import './print-workout.css';

const PrintWorkoutComponent = ({ workouts, isModalOpen, setPrintOpen }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const handleOk = () => {
    setPrintOpen(false);
  };
  const handleCancel = () => {
    setPrintOpen(false);
  };
  const paginationHandler = (e) => {
    setCurrentPage(e);
  };
  const downloadPDF = () => {
    const capture = document.querySelector('.print-workout-area');
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF('l', 'px', 'a5');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      doc.save('workout.pdf');
    });
  };

  return (
    <Modal
      width={'90%'}
      style={{
        top: 20
      }}
      title="Макет для печати"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}>
      <div className="print-workout-area">
        {workouts
          .slice(currentPage * 9 - 9, currentPage * 9)
          .sort((a, b) => a.date - b.date)
          .map((item) => (
            <Card key={item.key} className="print-workout-card">
              <div className="print-workout-content">
                <div>
                  <Avatar src={logoMini} size={34} className="print-workout-avatar" />{' '}
                </div>
                <div>
                  <b>
                    {item.clientData.client.client.surname} {item.clientData.client.client.name}
                  </b>
                </div>
                <div>
                  <b>
                    {item.clientData.time} {dayjs(item.clientData.date).format('DD/MM/YYYY')}
                  </b>
                </div>
              </div>
              <Divider className="print-workout-divider" />
              {item.clientData?.exercises
                .sort((a, b) => a.id - b.id)
                .map((exercise, index) => (
                  <div key={`${index}`}>
                    <div className="print-exercise-row">
                      <span>
                        {exercise.exercise.name} {exercise.description}
                      </span>{' '}
                      {exercise.plan}m
                    </div>
                  </div>
                ))}
            </Card>
          ))}
      </div>
      <div className="print-workout-footer">
        <div>
          <Button danger icon={<FilePdfOutlined />} onClick={downloadPDF}>
            Экспортировать в PDF
          </Button>
        </div>
        <div>
          <Pagination
            onChange={(e) => paginationHandler(e)}
            size="small"
            defaultCurrent={currentPage}
            defaultPageSize={9}
            total={workouts?.length}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} тренировок`}
          />
        </div>
      </div>
    </Modal>
  );
};

PrintWorkoutComponent.propTypes = {
  workouts: PropTypes.array,
  isModalOpen: PropTypes.bool,
  setPrintOpen: PropTypes.func
};
export default PrintWorkoutComponent;
