import { Button, Divider, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { APP_TEMPLATE_FORM_FIELDS } from '../../../../config';
import { useContext } from 'react';
import { Context } from '../../../../index';
import { selectExercises } from '../../../../utils';

const DynamicField = () => {
  const { exercise } = useContext(Context);
  const { dynamicTemplateFields } = APP_TEMPLATE_FORM_FIELDS;

  return (
    <Form.List name="fields">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key}>
                <Divider className="form-divider">Упражнение №{index + 1}</Divider>
                <div className="dynamic-fields-container">
                  <Form.Item
                    className="dynamic-select-field"
                    name={[index, 'exerciseId']}
                    rules={[{ required: true, message: 'Выберите упражнение' }]}>
                    <Select
                      size="small"
                      allowClear
                      placeholder="Упражнение"
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? '').includes(input)}
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      showSearch
                      options={selectExercises(exercise.exercises)}
                    />
                  </Form.Item>
                  {dynamicTemplateFields.map((item) => (
                    <Form.Item
                      key={item.name}
                      name={[index, item.name]}
                      rules={item.rules}
                      style={item.style}>
                      <Input
                        type={item.type}
                        min={item.min}
                        size="small"
                        placeholder={item.placeholder}
                        className="dynamic-input-field"
                      />
                    </Form.Item>
                  ))}
                  {fields.length > 1 ? (
                    <Button
                      type="danger"
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                      icon={<MinusCircleOutlined className="delete-button-icon" />}>
                      Удалить упражнение
                    </Button>
                  ) : null}
                </div>
              </div>
            ))}
            <Divider />
            <Form.Item>
              <Button type="dashed" onClick={() => add()} className="dynamic-add-button">
                <PlusOutlined /> Добавить упражнение
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export default DynamicField;
