import { Spin } from 'antd';
import './app-spinner.css';

const SmallSpinner = () => {
  return (
    <div className="small-spinner">
      <Spin />
    </div>
  );
};

export default SmallSpinner;
