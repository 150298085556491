import { Space, Spin } from 'antd';

const InformerSpinner = () => {
  return (
    <Space
      style={{
        height: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Spin />
    </Space>
  );
};

export default InformerSpinner;
