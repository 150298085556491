export const selectEmployees = (data) => {
  let employeeData = [];
  if (!data) {
    return employeeData;
  }
  data?.map((employee) => {
    employeeData.push({
      value: employee.id,
      label: `${employee.name} ${employee.surname}`
    });
  });
  return employeeData;
};

export const selectLocations = (data) => {
  let locationData = [];
  if (!data) {
    return locationData;
  }
  data?.map((location) => {
    locationData.push({
      value: location.shortName,
      label: location.name
    });
  });
  return locationData;
};
