import InProgress from '../../components/common/InProgress/InProgress';

const clientFaqItems = [
  {
    key: '1',
    label: 'Календарь',
    children: <InProgress title="Календарь" />
  },
  {
    key: '2',
    label: 'Тренировки',
    children: <InProgress title="Тренировки" />
  },
  {
    key: '3',
    label: 'Соревнования',
    children: <InProgress title="Соревнования" />
  },
  {
    key: '4',
    label: 'Уведомления',
    children: <InProgress title="Уведомления" />
  },
  {
    key: '5',
    label: 'Платежи',
    children: <InProgress title="Платежи" />
  },
  {
    key: '6',
    label: 'Обучение',
    children: <InProgress title="Обучение" />
  }
];

export const APP_CLIENT_FAQ_TABS = { clientFaqItems };
