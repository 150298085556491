// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columnStyle {
  padding: 5px;
  margin: 0px;
}
.columnContentWrapper {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  height: 95vh;
  padding: 20px;
  background: rgb(90, 142, 189);
  background: linear-gradient(
    211deg,
    rgba(90, 142, 189, 1) 0%,
    rgba(127, 195, 220, 1) 35%,
    rgba(172, 236, 223, 1) 100%
  );
}
.columnContentWrapper:hover {
  opacity: 0.9;
}
.contentTitleWrapper {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentImage {
  width: 620px;
}
.copyright {
  height: 1%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 12px;
  font-size: 10px;
  box-shadow: -5px -5px 5px -5px rgba(34, 60, 80, 0.6);
}
.copyrightText {
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/client-auth/preview-section/preview-section.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B;;;;;GAKC;AACH;AACA;EACE,YAAY;AACd;AACA;EACE,SAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,eAAe;EAGf,oDAAoD;AACtD;AACA;EACE,cAAc;AAChB","sourcesContent":[".columnStyle {\n  padding: 5px;\n  margin: 0px;\n}\n.columnContentWrapper {\n  background-color: #fff;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background-size: cover;\n  background-position: center;\n  border-radius: 20px;\n  height: 95vh;\n  padding: 20px;\n  background: rgb(90, 142, 189);\n  background: linear-gradient(\n    211deg,\n    rgba(90, 142, 189, 1) 0%,\n    rgba(127, 195, 220, 1) 35%,\n    rgba(172, 236, 223, 1) 100%\n  );\n}\n.columnContentWrapper:hover {\n  opacity: 0.9;\n}\n.contentTitleWrapper {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.contentImage {\n  width: 620px;\n}\n.copyright {\n  height: 1%;\n  display: flex;\n  justify-content: center;\n  align-items: flex-end;\n  margin-bottom: 12px;\n  font-size: 10px;\n  -webkit-box-shadow: -5px -5px 5px -5px rgba(34, 60, 80, 0.6);\n  -moz-box-shadow: -5px -5px 5px -5px rgba(34, 60, 80, 0.6);\n  box-shadow: -5px -5px 5px -5px rgba(34, 60, 80, 0.6);\n}\n.copyrightText {\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
