import { Popover, Image } from 'antd';
import PropTypes from 'prop-types';
import PopoverMessage from '../../common/PopoverMessage/PopoverMessage';
import { customView } from '../../../utils';
import { logoMini } from '../../../assets/images';
import './custom-event.css';

const CustomEvent = ({ event }) => {
  const currentType = customView(event.event.type);
  return (
    <Popover
      className="popover-container"
      placement="rightTop"
      content={
        <PopoverMessage text={event.event.description} image={event.event.img} icon={false} />
      }
      title={<p className="popover-title">{event.title}</p>}
    >
      <span className="custom-event-container" style={{ backgroundColor: currentType.color }}>
        {currentType.icon} {event.title}{' '}
        <Image src={logoMini} width={20} preview={false} alt="test" />
      </span>
    </Popover>
  );
};

CustomEvent.propTypes = {
  event: PropTypes.object
};

export default CustomEvent;
