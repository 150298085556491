export const standards = {
  one: [
    {
      subject: 'DYN',
      A: 30,
      B: 30,
      C: 45,
      fullMark: 60
    },
    {
      subject: 'DNF',
      A: 0,
      B: 0,
      C: 25,
      fullMark: 60
    },
    {
      subject: 'CWT',
      A: 10,
      B: 12,
      C: 0,
      fullMark: 60
    },
    {
      subject: 'CNF',
      A: 0,
      B: 0,
      C: 0,
      fullMark: 60
    },
    {
      subject: 'FIM',
      A: 0,
      B: 12,
      C: 0,
      fullMark: 60
    }
  ],
  two: [
    {
      subject: 'DYN',
      A: 40,
      B: 50,
      C: 45,
      fullMark: 60
    },
    {
      subject: 'DNF',
      A: 0,
      B: 35,
      C: 25,
      fullMark: 60
    },
    {
      subject: 'CWT',
      A: 12,
      B: 24,
      C: 0,
      fullMark: 60
    },
    {
      subject: 'CNF',
      A: 0,
      B: 15,
      C: 0,
      fullMark: 60
    },
    {
      subject: 'FIM',
      A: 0,
      B: 24,
      C: 0,
      fullMark: 60
    }
  ],
  three: [
    {
      subject: 'DYN',
      A: 55,
      B: 75,
      C: 45,
      fullMark: 80
    },
    {
      subject: 'DNF',
      A: 0,
      B: 50,
      C: 25,
      fullMark: 80
    },
    {
      subject: 'CWT',
      A: 21,
      B: 34,
      C: 0,
      fullMark: 80
    },
    {
      subject: 'CNF',
      A: 0,
      B: 25,
      C: 0,
      fullMark: 80
    },
    {
      subject: 'FIM',
      A: 0,
      B: 34,
      C: 0,
      fullMark: 80
    }
  ],
  four: [
    {
      subject: 'DYN',
      A: 70,
      B: 100,
      C: 45,
      fullMark: 110
    },
    {
      subject: 'DNF',
      A: 0,
      B: 75,
      C: 25,
      fullMark: 110
    },
    {
      subject: 'CWT',
      A: 32,
      B: 50,
      C: 0,
      fullMark: 110
    },
    {
      subject: 'CNF',
      A: 0,
      B: 35,
      C: 0,
      fullMark: 110
    },
    {
      subject: 'FIM',
      A: 0,
      B: 50,
      C: 0,
      fullMark: 110
    }
  ]
};

export const staStandards = {
  one: {
    aida: '1:30',
    molchanovs: '1:30'
  },
  two: {
    aida: '2:00',
    molchanovs: '2:30'
  },
  three: {
    aida: '2:45',
    molchanovs: '3:30'
  },
  four: {
    aida: '3:30',
    molchanovs: '5:00'
  }
};
