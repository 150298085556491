// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-item-card {
  border-width: 2px;
  border-color: #5a8ebd;
  width: 100%;
}
.template-item-title {
  font-size: 0.6vw;
  margin: 0 5px;
}
.template-item-level {
  width: 80px;
  font-size: 0.6vw;
  font-weight: bold;
  border: 0.5px solid #ddd;
  border-radius: 10px;
  padding: 5px;
}
.template-item-content {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.template-item-decription {
  font-size: 0.7vw;
  font-weight: bold;
  margin-right: 5px;
}
.template-item-text {
  font-size: 0.6vw;
}
@media only screen and (max-width: 997px) {
  .template-item-title {
    font-size: 1.2vw;
  }
  .template-item-level {
    font-size: 1vw;
  }
  .template-item-decription {
    font-size: 1.2vw;
  }
  .template-item-text {
    font-size: 1.2vw;
  }
  .template-item-decription {
    font-size: 1.2vw;
  }
}

@media only screen and (max-width: 500px) {
  .template-item-title {
    font-size: 1.8vw;
  }
  .template-item-level {
    font-size: 1.5vw;
  }
  .template-item-decription {
    font-size: 1.8vw;
  }
  .template-item-text {
    font-size: 1.8vw;
  }
  .template-item-decription {
    font-size: 1.8vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/training/template-settings/template-list/template-list-item/template-list-item.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;EACxB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,cAAc;EAChB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".template-item-card {\n  border-width: 2px;\n  border-color: #5a8ebd;\n  width: 100%;\n}\n.template-item-title {\n  font-size: 0.6vw;\n  margin: 0 5px;\n}\n.template-item-level {\n  width: 80px;\n  font-size: 0.6vw;\n  font-weight: bold;\n  border: 0.5px solid #ddd;\n  border-radius: 10px;\n  padding: 5px;\n}\n.template-item-content {\n  display: flex;\n  justify-content: flex-start;\n  margin-bottom: 10px;\n}\n.template-item-decription {\n  font-size: 0.7vw;\n  font-weight: bold;\n  margin-right: 5px;\n}\n.template-item-text {\n  font-size: 0.6vw;\n}\n@media only screen and (max-width: 997px) {\n  .template-item-title {\n    font-size: 1.2vw;\n  }\n  .template-item-level {\n    font-size: 1vw;\n  }\n  .template-item-decription {\n    font-size: 1.2vw;\n  }\n  .template-item-text {\n    font-size: 1.2vw;\n  }\n  .template-item-decription {\n    font-size: 1.2vw;\n  }\n}\n\n@media only screen and (max-width: 500px) {\n  .template-item-title {\n    font-size: 1.8vw;\n  }\n  .template-item-level {\n    font-size: 1.5vw;\n  }\n  .template-item-decription {\n    font-size: 1.8vw;\n  }\n  .template-item-text {\n    font-size: 1.8vw;\n  }\n  .template-item-decription {\n    font-size: 1.8vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
