export const getStaResult = (data) => {
  let results = [];
  data.map((item) => {
    item.exercises.map((item) => {
      if (item.exercise.name === 'STA') {
        results.push(item.fact);
      }
    });
  });

  if (results.length < 1) {
    return '0:00';
  }
  const time = toTime(Math.max(...results));
  return time;
};

function toTime(sec = 0) {
  return nilFirst(Math.trunc(sec / 60)) + ':' + nilFirst(sec % 60);
}

function nilFirst(t) {
  if (t.toString().length === 1) return t;
  return t.toString();
}
