import { Menu } from 'antd';
import { useContext } from 'react';
import { APP_MENU } from '../../../../config';
import { Context } from '../../../../index';
import './app-menu.css';

const AppMenu = () => {
  const { userMenu } = useContext(Context);
  const onClick = (e) => {
    localStorage.setItem('page', `/${e.key}`);
    userMenu.setActiveItem(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[userMenu.activeItem]}
      mode="horizontal"
      items={APP_MENU.generalMenuItems}
      className="general-menu"
    />
  );
};

export default AppMenu;
