const HOME_CLIENT_ROUTE = '/';
const ABOUT_ROUTE = '/about';
const USER_AGREEMENT_ROUTE = '/policy';
const CONTACT_INFO_ROUTE = '/contacts';

export const COMMON_PATHS = {
  ABOUT_ROUTE,
  USER_AGREEMENT_ROUTE,
  CONTACT_INFO_ROUTE,
  HOME_CLIENT_ROUTE
};
