import { makeAutoObservable } from 'mobx';
import { TicketService } from '../../services';

export default class TicketStore {
  isLoading = false;
  _tickets = [];
  _ticket = {};

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setTickets(tickets) {
    this._tickets = tickets;
  }
  setTicket(ticket) {
    this._ticket = ticket;
  }
  get tickets() {
    return this._tickets;
  }
  get ticket() {
    return this._ticket;
  }

  async createTicket(type, title, message, userId) {
    try {
      const res = await TicketService.createTicket(type, title, message, userId);
      await this.getAllTickets();
      this.setTicket(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async getAllTickets() {
    this.setLoading(true);
    try {
      const res = await TicketService.getAllTickets();
      this.setTickets(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getTicketById(id) {
    try {
      const res = await TicketService.getTicketById(id);
      this.setTicket(res.data);
    } catch (error) {
      return error;
    }
  }
  async updateTicket(id, status) {
    try {
      const res = await TicketService.updateTicket(id, status);
      this.setTicket(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
}
