import React, { useContext, useEffect } from 'react';
import { Card, Form, Input, Button, Checkbox } from 'antd';
import PageLabel from '../../../../common/PageLabel/PageLabel';
import { FormOutlined, PlusOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { Context } from '../../../../..';
import { openNotification, getCatalogFields, getCatalogTitle } from '../../../../../utils';
import { add } from '../../../../../assets/icons';
import PropTypes from 'prop-types';
import './catalog-item-form.css';

const CatalogItemForm = ({ type, edit = false, data, setIsModalOpen }) => {
  const { exercise, location, workoutProfile } = useContext(Context);
  const [form] = Form.useForm();

  useEffect(() => {
    if (edit) {
      switch (type) {
        case 'exercise':
          form.setFieldsValue({
            name: data.name,
            description: data.description,
            type: data.type,
            control: data.control
          });
          break;
        case 'location':
          form.setFieldsValue({
            name: data.name,
            shortName: data.shortName,
            address: data.address
          });
          break;
        case 'workout-profiles':
          form.setFieldsValue({
            name: data.name,
            description: data.description,
            appointment: data.appointment
          });
      }
    }
  }, [data]);

  const label = getCatalogTitle(type);
  const formType = getCatalogFields(type);

  const onFinish = async (values) => {
    let res;
    if (!edit) {
      switch (type) {
        case 'exercise':
          res = await exercise.createExercise(
            values.name,
            values.type,
            values.description,
            values.control
          );
          break;
        case 'location':
          res = await location.createLocation(values.name, values.shortName, values.address);
          break;
        case 'workout-profiles':
          res = await workoutProfile.createWorkoutProfile(
            values.name,
            values.description,
            values.appointment
          );
      }
    } else {
      switch (type) {
        case 'exercise':
          res = await exercise.updateExercise(
            data.id,
            values.name,
            values.type,
            values.description,
            values.control
          );
          break;
        case 'location':
          res = await location.updateLocation(
            data.id,
            values.name,
            values.shortName,
            values.address
          );
          break;
        case 'workout-profiles':
          res = await workoutProfile.updateWorkoutProfile(
            data.id,
            values.name,
            values.description,
            values.appointment
          );
      }
      setIsModalOpen(false);
    }
    if (res?.status === 200 || res?.status === 201) {
      form.resetFields();
    }
    openNotification(res);
  };

  const deleteHandler = async () => {
    if (!edit) {
      return;
    }
    let res;
    switch (type) {
      case 'exercise':
        res = await exercise.deleteExercise(data.id);
        break;
      case 'location':
        res = await location.deleteLocation(data.id);
        break;
      case 'workout-profiles':
        res = await workoutProfile.deleteWorkoutProfile(data.id);
    }
    openNotification(res);
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <Card className="catalog-form-card" title={!edit ? <PageLabel img={add} text={label} /> : ''}>
      <Form
        form={form}
        onFinish={onFinish}
        className="catalog-form"
        layout={!edit ? 'inline' : 'vertical'}>
        {formType.map((item, index) => (
          <Form.Item
            key={index}
            className={!edit ? 'catalog-form-item' : 'catalog-form-edit-item'}
            name={item.name}
            rules={[item.rules]}>
            <Input
              size="small"
              prefix={<FormOutlined className="site-form-item-icon" />}
              placeholder={item.placeholder}
            />
          </Form.Item>
        ))}
        {type === 'exercise' && (
          <Form.Item
            initialValue={edit ? data?.control : false}
            className="catalog-form-checkbox"
            name="control"
            valuePropName="checked">
            <Checkbox>Контроль</Checkbox>
          </Form.Item>
        )}
        <Form.Item className="form-edit-style">
          {edit && (
            <Button
              size="small"
              danger
              type="primary"
              icon={<DeleteOutlined />}
              disabled={false}
              onClick={deleteHandler}
              className="catalog-form-del-btn">
              Удалить
            </Button>
          )}
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            icon={edit ? <SaveOutlined /> : <PlusOutlined />}
            disabled={false}
            className="catalog-form-btn">
            {edit ? 'Сохранить' : 'Добавить'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

CatalogItemForm.propTypes = {
  type: PropTypes.string,
  edit: PropTypes.bool,
  data: PropTypes.object,
  setIsModalOpen: PropTypes.func
};

export default CatalogItemForm;
