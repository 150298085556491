// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.image-container {
  margin: 5px;
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 5px;
  font-size: 11px;
}
.text-image-container {
  max-width: 20vw;
  width: 100%;
  display: flex;
  justify-content: center;
}
.popover-image {
  border-radius: 8px;
}
.text-container p {
  max-width: 20vw;
  width: 100%;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/PopoverMessage/popover-message.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,WAAW;EACX,eAAe;AACjB;AACA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,WAAW;EACX,qBAAqB;EACrB,aAAa;EACb,oBAAoB;EACpB,4BAA4B;EAC5B,gBAAgB;AAClB","sourcesContent":[".message-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n}\n.image-container {\n  margin: 5px;\n}\n.text-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  margin: 5px;\n  font-size: 11px;\n}\n.text-image-container {\n  max-width: 20vw;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n.popover-image {\n  border-radius: 8px;\n}\n.text-container p {\n  max-width: 20vw;\n  width: 100%;\n  -webkit-line-clamp: 5;\n  line-clamp: 5;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
