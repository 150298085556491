// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-alert-container {
  margin-top: 10px;
  font-size: 11px;
}
.timeline-content-wrapper {
  padding: 10px;
  max-height: 64vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/client-training/workout-list/workout-list.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".comment-alert-container {\n  margin-top: 10px;\n  font-size: 11px;\n}\n.timeline-content-wrapper {\n  padding: 10px;\n  max-height: 64vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
