import { useContext } from 'react';
import { Avatar, Divider } from 'antd';
import { aida, molchanovs } from '../../../../../assets/images';
import { Context } from '../../../../../index';
import PropTypes from 'prop-types';
import './chart-tooltip.css';

// eslint-disable-next-line react/prop-types
const ChartTooltip = ({ active, payload, label }) => {
  const { user } = useContext(Context);
  // eslint-disable-next-line no-unused-vars,no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;

  const getIntroOfPage = (label) => {
    if (label === 'DYN') {
      return 'динамическое апноэ в ластах';
    }
    if (label === 'DNF') {
      return 'динамическое апноэ без ласт';
    }
    if (label === 'CWT') {
      return 'погружение с постоянным весом в ластах';
    }
    if (label === 'FIM') {
      return 'свободное погружение';
    }
    if (label === 'CNF') {
      return 'погружение с постоянным весом без ласт';
    }
    return '';
  };

  const getPayloadValue = (payload) => {
    switch (payload.name) {
      case 'AIDA':
        return payload.payload.A;
      case 'Molchanovs':
        return payload.payload.B;
      default:
        return payload.payload.C;
    }
  };
  const getAvatarValue = (payload) => {
    switch (payload.name) {
      case 'AIDA':
        return aida;
      case 'Molchanovs':
        return molchanovs;
      default:
        return `${URL}static/avatars/${user.user.client?.img}`;
    }
  };

  if (active && payload && payload.length) {
    return (
      <div className="tooltip-wrapper">
        <p className="label">
          <b>{label} :</b> {getIntroOfPage(label)}
        </p>
        <Divider className="tooltip-divider" />
        <div className="tooltip-content">
          <ul className="tooltip-content-list">
            {payload &&
              payload.map((item) => (
                <li className="tooltip-list-item" key={item.dataKey}>
                  <Avatar className="list-item-avatar" src={getAvatarValue(item)} />
                  {item.name}: {getPayloadValue(item)} м
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
  return null;
};

ChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string
};

export default ChartTooltip;
