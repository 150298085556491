import { useContext, useEffect, useState } from 'react';
import { Button, Card, Checkbox, DatePicker, Form, Input, Select } from 'antd';
import { REC_FORM_CONFIG } from '../../../config';
import { Context } from '../../../index';
import SuccessMessage from '../success-message/SuccessMessage';
import { checkDate, openNotification } from '../../../utils';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { rec } from '../../../assets/images';
import { getTrainerPhoto } from '../../../utils';
import { selectEmployees, selectLocations } from '../../../utils/select-employee';
import dayjs from 'dayjs';
import './recording-form.css';

const { TextArea } = Input;

const RecordingForm = () => {
  const { recording, employee, location } = useContext(Context);
  const [checked, setChecked] = useState(false);
  const [success, setSuccess] = useState(false);
  const [trainers, setTrainers] = useState(null);
  const [trainerPhoto, setTrainerPhoto] = useState(null);
  const [locationData, setLocationData] = useState(null);
  useEffect(() => {
    const getAllEmployees = async () => {
      await employee.getAllEmployees();
      await location.getAllLocations();
    };
    getAllEmployees()
      .then(() => {
        setTrainers(selectEmployees(employee.employees));
        setLocationData(selectLocations(location.locations));
      })
      .catch((e) => console.log(e));
  }, []);

  const onFinish = async (values) => {
    const res = await recording.createClientRecording(
      values.phone,
      values.employeeId,
      dayjs(values.date),
      values.time,
      values.location,
      values.comment ? values.comment : 'Нет пожеланий'
    );
    if (res.status === 201) {
      setTrainerPhoto(getTrainerPhoto(employee.employees, values.employeeId));
      setSuccess(true);
    } else {
      console.log(res);
      openNotification(res);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if (success) {
    return (
      <SuccessMessage
        trainerPhoto={trainerPhoto}
        setChecked={setChecked}
        successHandler={setSuccess}
      />
    );
  }

  return (
    <Card
      className="rec-form-card"
      cover={<img alt="example" src={rec} className="rec-card-img" />}>
      <Form
        className="rec-form"
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          hasFeedback
          validateDebounce={1000}
          name="phone"
          rules={REC_FORM_CONFIG.recRules.phoneRules}>
          <Input allowClear placeholder="Номер телефона - 375..." />
        </Form.Item>
        <Form.Item
          rules={REC_FORM_CONFIG.recRules.trainerRules}
          className="rec-form-item"
          name="employeeId">
          <Select placeholder="Выберите тренера" allowClear options={trainers} />
        </Form.Item>
        <Form.Item rules={REC_FORM_CONFIG.recRules.dateRules} className="rec-form-item" name="date">
          <DatePicker
            allowClear={true}
            className="rec-form-item"
            disabledDate={(current) => checkDate(current)}
            locale={locale}
            format="DD-MM-YYYY"
          />
        </Form.Item>
        <Form.Item rules={REC_FORM_CONFIG.recRules.timeRules} className="rec-form-item" name="time">
          <Select placeholder="Выберите время" allowClear options={REC_FORM_CONFIG.selectType} />
        </Form.Item>
        <Form.Item
          rules={REC_FORM_CONFIG.recRules.locationRules}
          className="rec-form-item"
          name="location">
          <Select placeholder="Выберите локацию" allowClear options={locationData} />
        </Form.Item>
        <Form.Item name="comment">
          <TextArea placeholder="Пожелания к тренировке" rows={2} />
        </Form.Item>
        <Form.Item name="agreement" valuePropName="checked">
          <Checkbox className="rec-form-checkbox" onChange={() => setChecked(!checked)}>
            Согласие на обработку персональных данных
          </Checkbox>
        </Form.Item>
        <Form.Item className="rec-form-item">
          <Button disabled={!checked} className="rec-form-btn" type="primary" htmlType="submit">
            Записаться
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default RecordingForm;
