import React from 'react';
import { Descriptions, Typography } from 'antd';
import { safety } from '../../assets/icons';
import PageLabel from '../../components/common/PageLabel/PageLabel';

const { Link } = Typography;

const text = (
  <Descriptions style={{ maxHeight: '70vh', height: '100%', overflowY: 'auto' }}>
    <div>
      <ul style={{ textAlign: 'left', fontSize: 12 }}>
        <li style={{ marginBottom: 5 }}>
          <b>√</b> Противопоказания (любые формы психических заболеваний, эпилепсия, наличие в
          анамнезе преходящих нарушений мозгового кровообращения (транзиторные ишемические атаки
          головного мозга), последствия черепно-мозговых травм с наличием синдрома внутричерепной
          гипертензии, все виды новообразований, хронические инфекции, стойкие нарушения функции
          внутренних органов, заболевания сердечно-сосудистой системы (включая гипертоническую
          болезнь), заболевания легких с функциональными нарушениями дыхательной системы,
          хронические синуситы, отиты, лабиринтопатия, глаукома, выраженное нарушение остроты
          зрения): отсутствуют.
        </li>
        <li style={{ marginBottom: 5 }}>
          <b>√</b> Я полностью осознаю, что фридайвинг – это экстремальный вид деятельности,
          требующий от обучающегося и занимающегося им ответственности и осторожности, и я полностью
          принимаю на себя ответственность за негативные последствия, которые могут наступить в
          случае несоблюдения указаний инструкторов и нарушения техники безопасности. Я полностью
          принимаю на себя ответственность за соответствие уровня моего здоровья возможности
          заниматься фридайвингом. Настоящим также подтверждаю, что полностью осведомлен о риске,
          связанном с занятием фридайвингом. Полностью беру на себя ответственность за возможные
          травмы или ущерб иного рода, причиненные моему здоровью в результате обучения фридайвингу
          и участия в данных занятиях фридайвингом.
        </li>
        <li style={{ marginBottom: 5 }}>
          <b>√</b> Согласен на обработку персональных данных (cм.{' '}
          <Link
            href="https://drive.google.com/file/d/1k5KnzSpSUVAThWNWevRcIZuHIcJrrSbW/view"
            target="_blank"
            rel="noreferrer"
            className="agreement-link">
            Политика конфиденциальности
          </Link>
          ).
        </li>
      </ul>
    </div>
  </Descriptions>
);

const termsList = [
  {
    key: '1',
    showArrow: false,
    label: <PageLabel img={safety} text="Памятка безопасных тренировок" />,
    children: <>{text}</>
  }
];

export const USE_OF_TERMS = { termsList };
