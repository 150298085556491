import $api from '../../http';

export default class WorkoutService {
  static async createWorkout(employeeId, clientId, location, date, time, type) {
    return $api.post('/workouts/', { employeeId, clientId, location, date, time, type });
  }
  static async getAllWorkouts() {
    return $api.get('/workouts/');
  }
  static async getWorkoutById(id) {
    return $api.get(`/workouts/${id}`);
  }
  static async getAllClientWorkouts(id) {
    return $api.get(`/workouts/my-workouts/${id}`);
  }
  static async getAllEmployeeWorkouts(id) {
    return $api.get(`/workouts/employee-workouts/${id}`);
  }
  static async updateWorkout(id, status, comment) {
    return $api.patch(`/workouts/${id}`, {
      status,
      comment
    });
  }
  static async confirmedWorkout(id, confirmed) {
    return $api.patch(`/workouts/confirmed/${id}`, { confirmed });
  }
}
