import { FireOutlined, GlobalOutlined, SoundOutlined, TrophyOutlined } from '@ant-design/icons';

export const customView = (type) => {
  switch (type) {
    case 'NEWS':
      return { color: '#71e1b0', icon: <SoundOutlined /> };
    case 'COMPETITION':
      return { color: '#2196f3', icon: <TrophyOutlined /> };
    case 'TRAVELING':
      return { color: '#3f51b5', icon: <GlobalOutlined /> };
    case 'SALE':
      return { color: '#e76576', icon: <FireOutlined /> };
    default:
      return;
  }
};
