import $api, { API_URL } from '../../http';

export default class CalendarService {
  static async createEvent(title, description, type, start, end, img, employeeId) {
    return $api.post('/events/', { title, description, type, start, end, img, employeeId });
  }

  static saveImage = async (formData) => {
    const res = await fetch(`${API_URL}/events/upload-image/`, {
      method: 'POST',
      body: formData,
      headers: { Authorization: localStorage.getItem('token') }
    });
    return res;
  };
  static async getAllEvents() {
    return $api.get('/events/');
  }
  static async getEventById(id) {
    return $api.get(`/events/${id}`);
  }
  static async updateEvent(id, title, description, start, end) {
    return $api.patch(`/users/${id}`, { title, description, start, end });
  }
  static async deleteEventById(id) {
    return $api.delete(`/events/${id}`);
  }
}
