// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.informer-wrapper {
  width: 100%;
}
.informer-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
.informer-card {
  margin: 5px;
  flex: 1 1;
  transition: 0.3s linear all;
}
.informer-card:hover {
  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/training/partner-training/InformerBlock/informer-block.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,SAAO;EACP,2BAA2B;AAC7B;AACA;EAGE,gDAAgD;AAClD","sourcesContent":[".informer-wrapper {\n  width: 100%;\n}\n.informer-column {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.informer-card {\n  margin: 5px;\n  flex: 1;\n  transition: 0.3s linear all;\n}\n.informer-card:hover {\n  -webkit-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);\n  -moz-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);\n  box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
