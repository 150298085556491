import { EditOutlined, FormOutlined } from '@ant-design/icons';

const createSupportFields = [
  {
    name: 'title',
    rules: { required: true, message: 'Укажите тему сообщения!' },
    icon: <EditOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Тема сообщения'
  },
  {
    textarea: true,
    name: 'message',
    rules: { required: true, message: 'Добавьте текст сообщения!' },
    icon: <FormOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    placeholder: 'Текст сообщения'
  }
];

const supportSelectType = [
  { value: 'ERROR', label: 'Ошибка' },
  { value: 'QUESTION', label: 'Вопрос' },
  { value: 'PROPOSAL', label: 'Предложение' }
];

export const APP_SUPPORT_FORM_FIELDS = { createSupportFields, supportSelectType };
