import React from 'react';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const MobileMenuButton = ({ openHandler }) => {
  return (
    <Button icon={<MenuOutlined />} onClick={openHandler}>
      Меню
    </Button>
  );
};

MobileMenuButton.propTypes = {
  openHandler: PropTypes.func
};

export default MobileMenuButton;
