import React from 'react';
import { Image } from 'antd';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const DefaultItem = ({ item, clickHandler }) => {
  return (
    <NavLink to={item.disabled ? '#' : item.to} disabled={item.disabled}>
      <div
        className={`outer-menu-item-wrapper ${item.disabled && 'inactive'}`}
        onClick={clickHandler}>
        <div className={`inner-menu-item-wrapper ${item.active ? 'active' : ''}`}>
          <Image preview={false} src={item.img} width={35} />
          {!item.collapsed && <div style={{ marginTop: 5 }}>{item.label}</div>}
        </div>
      </div>
    </NavLink>
  );
};

DefaultItem.propTypes = {
  item: PropTypes.object,
  clickHandler: PropTypes.func
};

export default DefaultItem;
