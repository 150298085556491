import { useContext } from 'react';
import { Avatar, Button, Modal, Space } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Context } from '../../../../../index';
import { openNotification } from '../../../../../utils';
import { RECORDING_STATUS } from '../../../../../constants';
import { sv } from '../../../../../assets/images';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import dayjs from 'dayjs';
import './recording-modal.css';

const RecordingModal = ({ setIsModalOpen, isModalOpen, data }) => {
  const { recording, user } = useContext(Context);

  // eslint-disable-next-line no-undef,no-unused-vars
  const URL = process.env.REACT_APP_API_STATIC_URL;
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const cancelHandler = async () => {
    const res = await recording.updateClientRecording(data?.id, RECORDING_STATUS.CANCEL);
    await recording.getAllRecByEmployeeIdOrClientId(user.user.id);
    handleCancel();
    openNotification(res);
  };

  return (
    <Modal
      title={<h6>Дата записи: {dayjs(data?.clientData.createdAt).format('DD/MM/YYYY')}</h6>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}>
      {!data?.clientData ? (
        <h5>Нет данных...</h5>
      ) : (
        <div className="recording-data-container">
          <Space className="recording-info-container">
            <div>
              <Avatar
                className="recording-avatar"
                src={
                  data?.clientData?.client?.client?.img
                    ? `${URL}static/avatars/${data?.clientData?.client?.client?.img}`
                    : sv
                }
                size={64}
              />
            </div>
            <div>
              <div>{data?.clientData?.client?.client?.name}</div>
              <div>{data?.clientData?.client?.client?.surname}</div>
            </div>
            <div>
              <div style={{ textAlign: 'center' }}>
                {data.date} {data?.time}
              </div>
            </div>
            <div>
              <div>
                <EnvironmentOutlined /> {data?.location}
              </div>
            </div>
            <div>
              <Button onClick={cancelHandler} danger className="recording-btn" size="small">
                Отменить
              </Button>
            </div>
          </Space>
          <div className="recording-comment-container">
            {data.clientData.comment ?? 'Пожеланий нет'}
          </div>
        </div>
      )}
    </Modal>
  );
};

RecordingModal.propTypes = {
  setIsModalOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
  data: PropTypes.object
};

export default RecordingModal;
