import { Button } from 'antd';
import PropTypes from 'prop-types';

const ConfirmedModalFooter = ({ data, confirmHandler, rejectHandler }) => {
  return (
    <>
      <div className="conf-workout-modal-footer">
        <b>Комментарий:</b> {data?.clientData?.comment}
      </div>
      <div>
        <div className="conf-workout-modal-btn-group">
          <Button onClick={confirmHandler} className="conf-workout-modal-btn-item" size="small">
            Подтвердить
          </Button>
          <Button
            onClick={rejectHandler}
            danger
            className="conf-workout-modal-btn-item"
            size="small"
          >
            Отклонить
          </Button>
        </div>
      </div>
    </>
  );
};

ConfirmedModalFooter.propTypes = {
  data: PropTypes.object,
  confirmHandler: PropTypes.func,
  rejectHandler: PropTypes.func
};

export default ConfirmedModalFooter;
