import React from 'react';
import { Card, Modal } from 'antd';
import PropTypes from 'prop-types';
import { Context } from '../../../../../index';
import { useContext } from 'react';
import EditExerciseForm from './EditExerciseForm';
import AddExerciseForm from './AddExerciseForm';
import './exercise-modal.css';

const ExerciseModal = ({ isModalOpen, setIsModalOpen, handleCancel }) => {
  const { templateElement } = useContext(Context);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={
        <p style={{ fontSize: 12 }}>
          {templateElement.templateElement.id
            ? `Редактирование ${templateElement.templateElement.exercise?.name ?? 'упраженения'}`
            : `Упражнение`}
        </p>
      }
      footer={false}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}>
      <Card>
        {templateElement.templateElement.id ? (
          <EditExerciseForm
            editExercise={templateElement.templateElement}
            setIsModalOpen={setIsModalOpen}
            handleCancel={handleCancel}
          />
        ) : (
          <AddExerciseForm setIsModalOpen={setIsModalOpen} handleCancel={handleCancel} />
        )}
      </Card>
    </Modal>
  );
};

ExerciseModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  handleCancel: PropTypes.func
};

export default ExerciseModal;
