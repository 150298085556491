import $api from '../../http';

export default class TemplateService {
  static async createTemplate(title, description, type, level) {
    return $api.post('/templates/', { title, description, type, level });
  }
  static async getAllTemplates() {
    return $api.get('/templates/');
  }
  static async getTemplateById(id) {
    return $api.get(`/templates/${id}`);
  }
  static async updateTemplate(id, title, description, type, level) {
    return $api.patch(`/templates/${id}`, { title, description, type, level });
  }
}
