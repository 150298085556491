import React from 'react';
import { Col, Tabs } from 'antd';
import { APP_TEMPLATE_TABS } from '../../../config';
import { getTabsPosition } from '../../../utils';

const TemplateSettings = () => {
  return (
    <Col flex="auto" style={{ padding: '0 10px' }}>
      <Tabs tabPosition={getTabsPosition()} items={APP_TEMPLATE_TABS.templateTabItems} />
    </Col>
  );
};

export default TemplateSettings;
