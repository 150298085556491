import React from 'react';
import InformerSpinner from '../../../common/Layout/Spinner/InformerSpinner';
import { Card, Col, Statistic } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const InformerItem = ({ loading, data, title, color, size }) => {
  return (
    <Col
      className="informer-column"
      xl={{ span: size }}
      lg={{ span: size }}
      md={{ span: size }}
      sm={{ span: 12 }}
      xs={{ span: 24 }}>
      {loading ? (
        <InformerSpinner />
      ) : (
        <Card bordered={true} className="informer-card">
          <Statistic
            title={title}
            value={data}
            precision={0}
            valueStyle={{ color: color, fontSize: 18 }}
            prefix={<BarChartOutlined />}
            suffix="шт"
          />
        </Card>
      )}
    </Col>
  );
};

InformerItem.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.number,
  title: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number
};

export default InformerItem;
