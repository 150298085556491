import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { logoMini } from '../../../../../assets/images';
import './training-card-header.css';

const TrainingCardHeader = ({ data, location }) => {
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;

  return (
    <div className="t-card-wrapper">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}>
        <div className="t-card-content">
          <Avatar
            className={!data?.img ? 't-card-avatar-default' : 't-card-avatar'}
            src={data?.img ? `${URL}static/avatars/${data?.img}` : logoMini}
            size={{ xs: 20, sm: 24, md: 32, lg: 40, xl: 60, xxl: 80 }}
          />
        </div>
        <div>
          <div>
            <b>Тренер</b>
          </div>
          <div style={{ fontSize: 12 }}>
            {data?.name ? data?.name + ' ' + data?.surname : 'Нет данных'}
          </div>
        </div>
      </div>
      <div>
        <div>
          <EnvironmentOutlined /> <b>Локация</b>
        </div>
        <div style={{ fontSize: 12 }}>{location ?? 'Неизвестно'}</div>
      </div>
    </div>
  );
};

TrainingCardHeader.propTypes = {
  data: PropTypes.object,
  location: PropTypes.string
};

export default TrainingCardHeader;
