import auth from './auth.avif';
import login from './login.avif';
import card from './card.avif';
import partner from './partner.avif';
import trainer from './treiner.avif';
import avatar from './avatar.avif';
import logo from './logo.png';
import logoMini from './logo-mini.png';
import partnerLogo from './partner-logo.png';
import cardExample from './card-example.avif';
import templ from './temp.png';
import certificate from './certificate.avif';
import profileBackground from './profile-background.png';
import sv from './sv.png';
import aida from './aida.png';
import molchanovs from './molchanovs.png';
import rec from './rec.png';
import oops from './oops.jpg';
import svl from './svl.png';
import instructor from './instructor.png';
import sportsman from './sportsman.png';
import temp from './temp.png';
import noAvatar from './no-avatar.jpg';

export {
  sv,
  templ,
  auth,
  login,
  card,
  partner,
  trainer,
  avatar,
  logo,
  logoMini,
  partnerLogo,
  cardExample,
  certificate,
  profileBackground,
  aida,
  molchanovs,
  rec,
  oops,
  svl,
  instructor,
  sportsman,
  temp,
  noAvatar
};
