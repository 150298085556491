// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-drawer-btn {
  color: #6b7a99;
  font-size: 12px;
}
.app-drawer-row {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.app-drawer-title {
  text-align: center;
  margin-top: 10;
  color: #5a8ebd;
}
.app-drawer-col {
  width: 100%;
}
.app-drawer-form-btn {
  background-color: #769ccd;
  width: 100%;
}
.app-drawer-form-btn:hover {
  background-color: #ffffff !important;
  border-color: #5a8ebd !important;
  color: #5a8ebd !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Layout/AppDrawer/app-drawer.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,cAAc;AAChB;AACA;EACE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,oCAAoC;EACpC,gCAAgC;EAChC,yBAAyB;AAC3B","sourcesContent":[".app-drawer-btn {\n  color: #6b7a99;\n  font-size: 12px;\n}\n.app-drawer-row {\n  height: 70vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 12px;\n}\n.app-drawer-title {\n  text-align: center;\n  margin-top: 10;\n  color: #5a8ebd;\n}\n.app-drawer-col {\n  width: 100%;\n}\n.app-drawer-form-btn {\n  background-color: #769ccd;\n  width: 100%;\n}\n.app-drawer-form-btn:hover {\n  background-color: #ffffff !important;\n  border-color: #5a8ebd !important;\n  color: #5a8ebd !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
