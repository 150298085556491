import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import './page-label.css';

const PageLabel = ({ img, text }) => {
  return (
    <div className="page-label-wrapper">
      <Avatar className="page-label-avatar" size={40} src={img} />
      <p className="page-label-text">{text}</p>
    </div>
  );
};

PageLabel.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string
};
export default PageLabel;
