import { Image, Card, Col, Row } from 'antd';
import React from 'react';
import { faqPage, techWork } from '../../../assets/icons';
import PageLabel from '../PageLabel/PageLabel';
import PropTypes from 'prop-types';
import './in-progress.css';

const InProgress = ({ title, img, maxHeight }) => {
  return (
    <Row className="in-progress-row" style={{ height: `${maxHeight}dvh` }}>
      <Col className="in-progress-col" style={{ height: `${maxHeight * 0.95}dvh` }} span={24}>
        <Card
          className="in-progress-card"
          style={{ height: `${maxHeight * 0.9}dvh` }}
          title={<PageLabel img={img ?? faqPage} text={title} />}>
          <div className="in-progress-content">
            <Image src={techWork} preview={false} width={128} />
            <h3>Раздел находится в разработке...</h3>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

InProgress.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  maxHeight: PropTypes.number
};

export default InProgress;
