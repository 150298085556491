// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partner-form-card {
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
}
.partner-form-cover {
  max-width: 450px;
  width: 100%;
}
.partner-form-btn {
  width: 100%;
  background-color: #5a8ebd !important;
  font-size: 13px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/partner-auth/partner-auth-form.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,WAAW;EACX,oCAAoC;EACpC,0BAA0B;AAC5B","sourcesContent":[".partner-form-card {\n  max-width: 90%;\n  width: 100%;\n  margin: 0 auto;\n}\n.partner-form-cover {\n  max-width: 450px;\n  width: 100%;\n}\n.partner-form-btn {\n  width: 100%;\n  background-color: #5a8ebd !important;\n  font-size: 13px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
