import { Button, Select } from 'antd';
import { saveExcel } from '../../../../utils/export-excel';
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { selectUserDates } from '../../../../utils';
import { selectUserLocations, selectUserTimes } from '../../../../utils/select-userdata';
import PropTypes from 'prop-types';
import React from 'react';

const WorkoutFilter = ({
  openPrintModal,
  dataHandler,
  resetHandler,
  timeHandler,
  locationHandler,
  currentData,
  data
}) => {
  const selectDates = selectUserDates(data);
  const selectTime = selectUserTimes(data);
  const selectLocations = selectUserLocations(data);

  return (
    <div className="confirmed-workout-filter-wrapper">
      <div className="workout-filter-wrapper">
        <Select
          size="small"
          className="workout-filter-select"
          onSelect={(e) => dataHandler(e)}
          onChange={() => resetHandler('date')}
          options={selectDates}
          placeholder="Выбрать дату"
          allowClear
        />
      </div>
      <div className="workout-filter-wrapper">
        <Select
          size="small"
          className="workout-filter-select"
          onSelect={(e) => timeHandler(e)}
          onChange={() => resetHandler('time')}
          options={selectTime}
          placeholder="Выбрать время"
          allowClear
        />
      </div>
      <div className="workout-filter-wrapper">
        <Select
          size="small"
          className="workout-filter-select"
          onSelect={(e) => locationHandler(e)}
          onChange={() => resetHandler('location')}
          options={selectLocations}
          placeholder="Выбрать локацию"
          allowClear
        />
      </div>
      <div className="workout-filter-wrapper">
        <Button
          disabled={data.length === 0}
          onClick={() => (currentData ? saveExcel(currentData) : saveExcel(data))}
          icon={<FileExcelOutlined />}
          size="small"
          className="workout-filter-excel">
          Excel
        </Button>
      </div>
      <div className="workout-filter-wrapper">
        <Button
          disabled={data.length === 0}
          onClick={openPrintModal}
          icon={<FilePdfOutlined />}
          size="small"
          className="workout-filter-pdf">
          PDF
        </Button>
      </div>
    </div>
  );
};

WorkoutFilter.propTypes = {
  openPrintModal: PropTypes.func,
  dataHandler: PropTypes.func,
  resetHandler: PropTypes.func,
  timeHandler: PropTypes.func,
  locationHandler: PropTypes.func,
  currentData: PropTypes.array,
  data: PropTypes.array
};

export default WorkoutFilter;
