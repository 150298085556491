import { Card, Col, Row, Select } from 'antd';
import React from 'react';
import { selectTempLevel, selectWorkoutProfileLabel } from '../../../../../utils';
import PropTypes from 'prop-types';
import './template-list-filter.css';

function TemplateListFilter({ levelHandler, typeHandler, resetHandler, data, profiles }) {
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="template-filter-col">
        <Card className="template-filter-card">
          <Select
            allowClear
            size="small"
            placeholder="Уровень"
            className="template-filter-selsect"
            onSelect={(e) => levelHandler(e)}
            onChange={() => resetHandler('level')}
            options={selectTempLevel(data)}
          />
          <Select
            className="template-filter-selsect"
            allowClear
            size="small"
            placeholder="Профиль тренировки"
            onSelect={(e) => typeHandler(e)}
            onChange={() => resetHandler('type')}
            options={selectWorkoutProfileLabel(data, profiles)}
          />
        </Card>
      </Col>
    </Row>
  );
}

TemplateListFilter.propTypes = {
  levelHandler: PropTypes.func,
  typeHandler: PropTypes.func,
  resetHandler: PropTypes.func,
  data: PropTypes.array,
  profiles: PropTypes.array
};

export default TemplateListFilter;
