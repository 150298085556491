import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../index';
import { Button, Col, Drawer, Row, Tooltip, Tabs } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import EditPasswordForm from '../../EditPasswordForm/EditPasswordForm';
import { observer } from 'mobx-react-lite';
import AvatarForm from '../../AvatarForm/AvatarForm';
import ProfileForm from '../../ProfileForm/ProfileForm';
import PageLabel from '../../../PageLabel/PageLabel';
import { profile } from '../../../../../assets/icons';
import './user-data.css';
import React from 'react';

const UserData = () => {
  const { auth, user } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (localStorage.getItem('type') === 'partner') {
      setCurrentUser(auth.partner.employee ?? user.user.employee);
      setEmail(auth.partner.email ?? user.user.email);
    } else {
      setCurrentUser(auth.client.client ?? user.user.client);
      setEmail(auth.client.email ?? user.user.email);
    }
  }, [user.user.client, user.user.employee]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = async () => {
    await user.getUserByEmailOrPhone(email);
    if (localStorage.getItem('type') === 'partner') {
      setCurrentUser(user.user.employee);
    } else {
      setCurrentUser(user.user.client);
    }
    setOpen(false);
  };

  return (
    <>
      <div className="user-data-wrapper">
        <div>
          <div className="user-data-header">
            <h4 className="user-data-name">
              {localStorage.getItem('type') === 'partner' ? 'Инструктор' : 'Спортсмен'}
            </h4>
          </div>
          <p className="user-data-name">
            {currentUser?.name} {currentUser?.surname}
          </p>
        </div>
        <Tooltip
          placement="right"
          color="#a5c3df"
          title={
            <p className="user-data-tooltip">
              Изменение текущего email осуществляется администратором
            </p>
          }>
          <span className="user-data-email">{email}</span>
        </Tooltip>
        <Button className="user-data-button" onClick={showDrawer} icon={<UserOutlined />}>
          Профиль
        </Button>
      </div>
      <Drawer
        title={<PageLabel text="Профиль пользователя" img={profile} />}
        placement="top"
        onClose={onClose}
        open={open}
        width={560}>
        <Row className="user-drawer-container">
          <Col className="user-data-avatar-container" flex={1}>
            <AvatarForm id={currentUser?.id} />
          </Col>
          <Col className="user-data-profile-container" flex={4}>
            <Tabs
              size="small"
              type="card"
              items={[
                {
                  key: '1',
                  label: <p className="user-data-tab-title">Общие сведения</p>,
                  children: (
                    <ProfileForm
                      currentUser={currentUser}
                      setOpen={setOpen}
                      setCurrentUser={setCurrentUser}
                    />
                  )
                },
                {
                  key: '2',
                  label: <p className="user-data-tab-title">Смена пароля</p>,
                  children: <EditPasswordForm type="edit" />
                }
              ]}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default observer(UserData);
