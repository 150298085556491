// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-info-wrapper {
  padding: 0 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.t-card-avatar {
  width: 8%;
  height: auto;
  aspect-ratio: 1 / 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/partner-training/scheduled-workouts/row-info/row-info.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,SAAS;EACT,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".row-info-wrapper {\n  padding: 0 5px;\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n.t-card-avatar {\n  width: 8%;\n  height: auto;\n  aspect-ratio: 1 / 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
