// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-wrapper {
  padding: 10px;
  font-size: 11px;
  border-radius: 10px;
  border: 1px solid #dddddd;
  background-color: rgba(255, 255, 255, 0.7);
}
.tooltip-divider {
  margin: 5px;
}
.tooltip-content {
  display: flex;
  justify-content: flex-start;
}
.tooltip-content-list {
  text-align: left;
}
.tooltip-list-item {
  list-style-type: none;
  margin-bottom: 5px;
}
.list-item-avatar {
  margin-right: 5px;
  border: 1px solid #dddddd;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/client-training/charts/tooltip/chart-tooltip.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,yBAAyB;EACzB,0CAA0C;AAC5C;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":[".tooltip-wrapper {\n  padding: 10px;\n  font-size: 11px;\n  border-radius: 10px;\n  border: 1px solid #dddddd;\n  background-color: rgba(255, 255, 255, 0.7);\n}\n.tooltip-divider {\n  margin: 5px;\n}\n.tooltip-content {\n  display: flex;\n  justify-content: flex-start;\n}\n.tooltip-content-list {\n  text-align: left;\n}\n.tooltip-list-item {\n  list-style-type: none;\n  margin-bottom: 5px;\n}\n.list-item-avatar {\n  margin-right: 5px;\n  border: 1px solid #dddddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
