import { Segmented } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Context } from '../../../../..';
import { getMaxResults, subjectFilter } from '../../../../../utils';
import { observer } from 'mobx-react-lite';

const AppSegmented = ({ data, dataHandler, type }) => {
  const { workout } = useContext(Context);
  const levelsData = ['1*/1Wave', '2*/2Wave', '3*/3Wave', '4*/4Wave'];

  const maxValues = getMaxResults(workout.workouts);
  const defaultData = maxValues ?? { DYN: 0, DNF: 0, FIM: 0, CNF: 0, CWT: 0 };

  const changeDataHandler = (e) => {
    switch (e) {
      case '1*/1Wave':
        dataHandler(type === 'time' ? data.one : subjectFilter(data.one, defaultData));
        break;
      case '2*/2Wave':
        dataHandler(type === 'time' ? data.two : subjectFilter(data.two, defaultData));
        break;
      case '3*/3Wave':
        dataHandler(type === 'time' ? data.three : subjectFilter(data.three, defaultData));
        break;
      case '4*/4Wave':
        dataHandler(type === 'time' ? data.four : subjectFilter(data.four, defaultData));
        break;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Segmented
        block
        className="segmented-menu"
        onChange={(e) => changeDataHandler(e)}
        options={levelsData}
      />
    </div>
  );
};

AppSegmented.propTypes = {
  data: PropTypes.any,
  dataHandler: PropTypes.func,
  type: PropTypes.string
};

export default observer(AppSegmented);
