import { makeAutoObservable } from 'mobx';
import { AuthService } from '../../services';
export default class AuthStore {
  _isAuth = false;
  isLoading = false;
  _client = {};
  _partner = {};
  _type = '';

  constructor() {
    makeAutoObservable(this);
  }

  setIsAuth(bool) {
    this._isAuth = bool;
  }
  setClient(client) {
    this._client = client;
  }
  setPartner(partner) {
    this._partner = partner;
  }
  setType(type) {
    this._type = type;
  }
  setLoading(bool) {
    this.isLoading = bool;
  }

  get isAuth() {
    return this._isAuth;
  }
  get client() {
    return this._client;
  }
  get partner() {
    return this._partner;
  }
  get type() {
    return this._type;
  }
  async signupClient(email, phone, password, partnerId) {
    this.setLoading(true);
    try {
      const res = await AuthService.signupClient(email, phone, password, partnerId);
      if (res.status === 201) {
        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('ddu', JSON.stringify(res.data.user));
        this.setIsAuth(true);
        this.setClient(res.data.user);
        this.setType('client');
      }
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async signinClient(email, password) {
    this.setLoading(true);
    try {
      const res = await AuthService.signinClient(email, password);
      localStorage.setItem('token', res.data.accessToken);
      this.saveUserData(res.data.user);
      this.setIsAuth(true);
      this.setClient(res.data.user);
      this.setType('client');
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async signinPartner(phone, password) {
    try {
      const res = await AuthService.signinPartner(phone, password);
      localStorage.setItem('token', res.data.accessToken);
      this.setPartner(res.data.user);
      this.saveUserData(res.data.user);
      this.setIsAuth(true);
      this.setType('partner');
    } catch (error) {
      return error;
    }
  }

  async googleAuth() {
    try {
      const res = await AuthService.googleAuth();
      localStorage.setItem('token', res.data.accessToken);
      this.setIsAuth(true);
      this.setClient(res.data.user);
      this.setType('client');
    } catch (error) {
      return { status: error.response?.data.statusCode, message: error.response?.data?.message };
    }
  }

  async yandexAuth() {
    try {
      const res = await AuthService.yandexAuth();
      localStorage.setItem('token', res.data.accessToken);
      this.setIsAuth(true);
      this.setClient(res.data.user);
      this.setType('client');
    } catch (error) {
      return { status: error.response?.data.statusCode, message: error.response?.data?.message };
    }
  }

  async logout() {
    try {
      const res = await AuthService.logout();
      if (res.status === 200) {
        localStorage.removeItem('token');

        sessionStorage.removeItem('ddu');
        this.setIsAuth(false);
        this.setClient({});
      }
    } catch (error) {
      return { status: error.response?.data.statusCode, message: error.response?.data?.message };
    }
  }

  async checkAuth() {
    this.setLoading(true);
    try {
      const res = await AuthService.authStatus();
      if (res.status === 200) {
        localStorage.setItem('token', res.data.accessToken);
        this.setIsAuth(true);
      }
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async resetPassword(email) {
    try {
      return await AuthService.resetPassword(email);
    } catch (error) {
      return error;
    }
  }
  async updatePassword(id, password) {
    try {
      return await AuthService.updatePassword(id, password);
    } catch (error) {
      return error;
    }
  }

  saveUserData(res) {
    const data = res.email.split('').reverse();
    return sessionStorage.setItem('ddu', JSON.stringify(data));
  }
}
