// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trainings-card {
  height: 55vh;
  max-height: 55vh;
  overflow-y: auto;
  text-align: left;
  margin-bottom: 10px;
  overflow-x: auto;
}
.trainings-card .ant-table-cell {
  cursor: pointer;
}
.list-pagination {
  font-size: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/client-training/trainings-list/training-list.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".trainings-card {\n  height: 55vh;\n  max-height: 55vh;\n  overflow-y: auto;\n  text-align: left;\n  margin-bottom: 10px;\n  overflow-x: auto;\n}\n.trainings-card .ant-table-cell {\n  cursor: pointer;\n}\n.list-pagination {\n  font-size: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
