const workoutTimes = [
  { value: '6:45', label: '6:45' },
  { value: '18:15', label: '18:15' },
  { value: '19:15', label: '19:15' }
];
const workoutType = [
  {
    value: 1,
    label: 'Тренировка на выносливость'
  },
  {
    value: 2,
    label: 'Тренировка на гипаксию'
  }
];
const workoutLocation = [
  {
    value: 'СОК Фристайл',
    label: 'СОК Фристайл'
  },
  {
    value: 'Дом гребли',
    label: 'Дом гребли'
  }
];

export const WORKOUT_SELECT = { workoutTimes, workoutType, workoutLocation };
