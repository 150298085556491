import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Context } from '../../../../index';
import { observer } from 'mobx-react-lite';

import { Button, Card, Divider, Form, Input } from 'antd';
import { CLIENT_PATHS } from '../../../../constants';

import { openNotification } from '../../../../utils';
import { LogoBlock, ReferenceBlock } from '../index';
import { APP_AUTH_FORM_FIELDS } from '../../../../config';
import './signin-form.css';

const SignInForm = ({ showDrawer }) => {
  const { auth } = useContext(Context);
  const { signInClientFields } = APP_AUTH_FORM_FIELDS;
  localStorage.removeItem('type');
  const onFinish = async ({ email, password }) => {
    const res = await auth.signinClient(email.toLowerCase(), password);
    if (res) {
      openNotification(res);
    }
  };

  return (
    <>
      <div className="columnFormWrapper">
        <LogoBlock />
        <Card title="Авторизация" bordered={true} className="auth-card">
          <div className="auth-card-content">
            <Form
              name="normal_login"
              className="sign-in-form"
              onFinish={onFinish}
              autoComplete="off">
              {signInClientFields &&
                signInClientFields.map((field) => (
                  <Form.Item
                    hasFeedback
                    validateDebounce={1000}
                    key={field.name}
                    name={field.name}
                    rules={[field.rules]}>
                    <Input
                      allowClear
                      prefix={field.icon}
                      type={field.type}
                      placeholder={field.placeholder}
                    />
                  </Form.Item>
                ))}
              <Form.Item>
                <div className="form-button-container">
                  <ReferenceBlock
                    link={CLIENT_PATHS.SIGNUP_CLIENT_ROUTE}
                    linkedText="Регистрация"
                    text="Нет аккаунта?"
                  />
                  <Button type="primary" htmlType="submit" className="sign-in-form-button">
                    Войти
                  </Button>
                </div>
              </Form.Item>
              <Divider className="sign-in-divider" />
              <p className="sign-in-reset-pass" onClick={showDrawer}>
                Забыли<span className="sign-in-form-link"> пароль?</span>
              </p>
            </Form>
          </div>
        </Card>
      </div>
    </>
  );
};

SignInForm.propTypes = {
  showDrawer: PropTypes.func
};

export default observer(SignInForm);
