import React, { useContext, useEffect, useState } from 'react';
import { Col, Image } from 'antd';
import WorkoutTemplateCard from '../WorkoutTemplateCard/WorkoutTemplateCard';
import WorkoutTemplateWrapper from '../WorkoutTemplateWrapper/WorkoutTemplateWrapper';
import WorkoutList from '../WorkoutList/WorkoutList';
import { Context } from '../../../../../index';
import { observer } from 'mobx-react-lite';
import ExerciseModal from '../ExerciseModal/ExerciseModal';
import { SmallSpinner } from '../../../../index';
import { template as templateIcon } from '../../../../../assets/icons';
import './create-workout-component.css';

const CreateWorkoutComponent = () => {
  // eslint-disable-next-line no-unused-vars
  const { template, templateElement, recording } = useContext(Context);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    const getBaseDate = async () => {
      setLoading(true);
      await template.getAllTemplates();
      templateElement.setTemplateElements([]);
      setLoading(false);
    };
    getBaseDate().catch((error) => console.log(error));
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    templateElement.setTemplateElement({});
    setIsModalOpen(false);
  };

  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <WorkoutTemplateWrapper>
          {template.currentTemplates.length > 0 ? (
            template.currentTemplates.map((item) => (
              <WorkoutTemplateCard
                key={item.id}
                item={item}
                activeTemplate={activeTemplate}
                setActiveTemplate={setActiveTemplate}
              />
            ))
          ) : (
            <div className="template-container">
              <Image src={templateIcon} width={50} alt="icon" preview={false} />
              <p className="template-container-text"> Шаблоны не выбраны...</p>
            </div>
          )}
        </WorkoutTemplateWrapper>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {isLoading ? (
          <SmallSpinner />
        ) : (
          <WorkoutList showModal={showModal} setIsModalOpen={setIsModalOpen} />
        )}
      </Col>
      <ExerciseModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default observer(CreateWorkoutComponent);
