import React, { useState, useContext, useEffect } from 'react';
import { Pagination, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../..';

import TemplateListItem from './template-list-item/TemplateListItem';

import PropTypes from 'prop-types';
import './template-list.css';

const TemplateList = ({ data }) => {
  const { template, workoutProfile } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(6);

  useEffect(() => {
    const getTemplates = async () => {
      await template.getAllTemplates();
      await workoutProfile.getAllWorkoutProfiles();
    };
    getTemplates().catch((e) => console.log(e));
  }, []);

  const paginationHandler = (e) => {
    setCurrentPage(e);
  };

  return (
    <>
      <Row gutter={[10, 10]} className="templates-content-row">
        {data.slice(currentPage * limit - limit, currentPage * limit).map((item, index) => (
          <TemplateListItem key={index} item={item} />
        ))}
      </Row>
      <Row className="templates-pagination-row">
        <Pagination
          onChange={(e) => paginationHandler(e)}
          size="small"
          defaultPageSize={limit}
          total={data?.length}
        />
      </Row>
    </>
  );
};

TemplateList.propTypes = {
  data: PropTypes.array
};

export default observer(TemplateList);
