import { Row } from 'antd';
import PropTypes from 'prop-types';
import UserMenuItem from './UserMenuItem/UserMenuItem';
import { APP_MENU } from '../../../../config';
import { observer } from 'mobx-react-lite';
import './user-menu.css';

const UserMenu = ({ collapsed, active, setOpen }) => {
  const { clientMenuItems, partnerMenuItems } = APP_MENU;
  const menu = localStorage.getItem('type') === null ? clientMenuItems : partnerMenuItems;
  let spanValue = collapsed ? 24 : 11;

  return (
    <>
      <Row className="user-menu-wrapper">
        {menu.map((item) => (
          <UserMenuItem
            key={item.key}
            active={active === item.label}
            collapsed={collapsed}
            spanValue={spanValue}
            img={active === item.label ? item.activeIcon : item.icon}
            label={item.label}
            to={item.to}
            disabled={item.disabled}
            setOpen={setOpen}
          />
        ))}
      </Row>
    </>
  );
};

UserMenu.propTypes = {
  collapsed: PropTypes.bool,
  active: PropTypes.string,
  setOpen: PropTypes.func
};

export default observer(UserMenu);
