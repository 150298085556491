import { Button, Form, Input } from 'antd';
import { openNotification } from '../../../../../utils';
import { useContext, useEffect } from 'react';
import { Context } from '../../../../../index';
import PropTypes from 'prop-types';

const TrainingModalForm = ({ data, handleCancel, setCurrentExercise, setIsModalOpen }) => {
  const { workoutElement } = useContext(Context);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ description: data.description, plan: data.plan, fact: data.fact });
  }, [data]);
  const onFinish = async (values) => {
    const res = await workoutElement.updateWorkoutElement(data.id, +values.fact);
    if (res.status === 200) {
      form.resetFields();
      setCurrentExercise({});
      setIsModalOpen(false);
    } else {
      openNotification(res);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item name="description">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="plan">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item name="fact">
        <Input type="number" />
      </Form.Item>
      <Form.Item className="btn-container">
        <Button onClick={handleCancel} className="cancel-btn">
          Отмена
        </Button>
        <Button htmlType="submit" className="ok-btn">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
};

TrainingModalForm.propTypes = {
  data: PropTypes.object,
  setIsModalOpen: PropTypes.func,
  setCurrentExercise: PropTypes.func,
  handleCancel: PropTypes.func
};

export default TrainingModalForm;
