import { Alert, Collapse } from 'antd';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import React from 'react';

const WorkoutCardCollapse = ({ item }) => {
  return (
    <Collapse
      bordered={false}
      className="card-collapse-wrapper"
      size="small"
      items={[
        {
          key: '1',
          label: <p style={{ fontSize: 10 }}>Список упражнений</p>,
          children: (
            <div style={{ padding: 5 }}>
              <ol>
                {item.exercises.length > 0 ? (
                  item.exercises.map((exercise) => (
                    <li className="card-list-item" key={exercise.id}>
                      <b>{exercise.exercise?.name}: </b> {exercise.description} - {exercise.plan}
                    </li>
                  ))
                ) : (
                  <Alert
                    className="card-collapse-alert"
                    message="Все упражениня удалены из шаблона"
                    type="warning"
                  />
                )}
              </ol>
            </div>
          )
        }
      ]}
    />
  );
};

WorkoutCardCollapse.propTypes = {
  item: PropTypes.object
};

export default observer(WorkoutCardCollapse);
