import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import CatalogItemForm from '../catalog-item-form/CatalogItemForm';

const CatalogItemModal = ({ setIsModalOpen, isModalOpen, data, type }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      style={{ height: '10vh' }}
      footer={false}
      title={data?.name}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}>
      <CatalogItemForm type={type} edit={true} data={data} setIsModalOpen={setIsModalOpen} />
    </Modal>
  );
};
CatalogItemModal.propTypes = {
  setIsModalOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
  data: PropTypes.object,
  type: PropTypes.string
};

export default CatalogItemModal;
