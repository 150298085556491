import dayjs from 'dayjs';

export const selectUserData = (data) => {
  let usersData = [];
  if (!data) {
    return [];
  }
  data.map((user) => {
    if (user.employee === null) {
      usersData.push({ value: user.id, label: `${user.client.name} ${user.client.surname}` });
    }
  });
  return usersData;
};

export const selectUserDates = (data, convert = false) => {
  let userDates = [];
  if (!data) {
    return userDates;
  }
  data?.map((user) => {
    const currentDate = convert ? dayjs(user.date).format('DD/MM/YYYY') : user.date;
    const uniqueDate = userDates.filter((item) => {
      return item.label === currentDate;
    });
    if (uniqueDate.length === 0) {
      userDates.push({
        value: user.date,
        label: currentDate
      });
    }
  });
  return userDates;
};

export const selectUserTimes = (data) => {
  let userTimes = [];
  data?.map((user) => {
    const uniqueTime = userTimes.filter((item) => item.value === user.time);
    if (uniqueTime.length === 0) {
      userTimes.push({
        value: user.time,
        label: user.time
      });
    }
  });
  return userTimes;
};

export const selectUserLocations = (data) => {
  let userLocations = [];
  data?.map((user) => {
    const uniqueLocations = userLocations.filter((item) => item.value === user.location);
    if (uniqueLocations.length === 0) {
      userLocations.push({
        value: user.location,
        label: user.location
      });
    }
  });
  return userLocations;
};
