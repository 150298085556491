import { makeAutoObservable } from 'mobx';
import { ClientService } from '../../services';

export default class ClientStore {
  isLoading = false;
  _updateAvatar = {};
  _clients = [];
  _client = {};

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setClients(clients) {
    this._clients = clients;
  }
  setClient(client) {
    this._client = client;
  }
  setUpdateAvatar(updateAvatarObj) {
    this._updateAvatar = updateAvatarObj;
  }
  get clients() {
    return this._clients;
  }
  get client() {
    return this._client;
  }
  get updateAvatar() {
    return this._updateAvatar;
  }

  async createClient(name, surname, level, userId) {
    try {
      const res = await ClientService.createClient(name, surname, level, userId);
      await this.getAllClients();
      this.setClient(res.data);
    } catch (error) {
      return error;
    }
  }
  async getAllClients() {
    this.setLoading(true);
    try {
      const res = await ClientService.getAllClients();
      this.setClients(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getClientById(id) {
    try {
      const res = await ClientService.getClientById(id);
      this.setClient(res.data);
    } catch (error) {
      return error;
    }
  }
  async updateClientAvatar(id, formData) {
    try {
      const res = await ClientService.updateClientAvatar(id, formData);
      this.setUpdateAvatar(await res.json());
      return this._updateAvatar;
    } catch (error) {
      return error;
    }
  }

  async updateClient(id, name, middleName, surname, level) {
    try {
      const res = await ClientService.updateClient(id, name, middleName, surname, level);
      this.setClient(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
}
