// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agreement-paragraph {
  max-height: 67vh;
  overflow-y: auto;
  padding: 10px;
  border-radius: 10px;
  box-shadow:
    inset -5px -5px 10px 0px rgba(255, 255, 255, 0.5),
    inset 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
}
.agreement-text {
  font-size: 12px;
  height: 100%;
}
.agreement-link {
  font-size: 12px;
}
.agreement-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.agreement-checkbox {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
}
.agreement-btn {
  background-color: #5a8ebd;
  font-size: 12px;
}
.agreement-btn:hover {
  background-color: #ffffff !important;
  color: #5a8ebd !important;
  border-color: #5a8ebd;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/AgreementModal/agreement-modal.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB;;6CAE2C;AAC7C;AACA;EACE,eAAe;EACf,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,eAAe;AACjB;AACA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":[".agreement-paragraph {\n  max-height: 67vh;\n  overflow-y: auto;\n  padding: 10px;\n  border-radius: 10px;\n  box-shadow:\n    inset -5px -5px 10px 0px rgba(255, 255, 255, 0.5),\n    inset 5px 5px 10px 0px rgba(0, 0, 0, 0.3);\n}\n.agreement-text {\n  font-size: 12px;\n  height: 100%;\n}\n.agreement-link {\n  font-size: 12px;\n}\n.agreement-btn-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n.agreement-checkbox {\n  margin-left: 10px;\n  font-size: 12px;\n  font-weight: bold;\n}\n.agreement-btn {\n  background-color: #5a8ebd;\n  font-size: 12px;\n}\n.agreement-btn:hover {\n  background-color: #ffffff !important;\n  color: #5a8ebd !important;\n  border-color: #5a8ebd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
