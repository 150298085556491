import { makeAutoObservable } from 'mobx';

export default class WorkoutTemplateStore {
  isLoading = false;
  _templates = [
    {
      id: 1,
      title: 'Тренировка на выносливость',
      type: 1,
      level: '1',
      description: 'Включает 6-ть упражнений. Время выполнения - 60мин.',
      exercises: [
        {
          id: 1,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 2,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 3,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 4,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 5,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 6,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        }
      ]
    },
    {
      id: 2,
      title: 'Тренировка на гипаксию',
      type: 2,
      level: '2',
      description: 'Включает 6-ть упражнений. Время выполнения - 45мин.',
      exercises: [
        {
          id: 1,
          idEx: 2,
          title: 'Статика',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 2,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 3,
          idEx: 2,
          title: 'Статика',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 4,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 5,
          idEx: 2,
          title: 'Статика',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 6,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        }
      ]
    },
    {
      id: 3,
      title: 'Тренировка на выносливость',
      type: 1,
      level: '1',
      description: 'Включает 6-ть упражнений. Время выполнения - 60мин.',
      exercises: [
        {
          id: 1,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 2,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 3,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 4,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 5,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 6,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        }
      ]
    },
    {
      id: 4,
      title: 'Тренировка на гипаксию',
      level: '2',
      type: 2,
      description: 'Включает 6-ть упражнений. Время выполнения - 45мин.',
      exercises: [
        {
          id: 1,
          idEx: 2,
          title: 'Статика',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 2,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 3,
          idEx: 2,
          title: 'Статика',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 4,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 5,
          idEx: 2,
          title: 'Статика',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        },
        {
          id: 6,
          idEx: 2,
          title: 'Плавание кроль',
          description: 'плавание свободным стилем',
          plan: '60м',
          fact: ''
        }
      ]
    }
  ];
  _currentTemplates = [];
  _template = {};
  _editExercise = {};

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setCurrentTemplates(currentTemplates) {
    this._currentTemplates = currentTemplates;
  }
  setTemplate(template) {
    this._template = template;
  }
  setEditExercise(editExercise) {
    this._editExercise = editExercise;
  }
  get templates() {
    return this._templates;
  }
  get template() {
    return this._template;
  }
  get currentTemplates() {
    return this._currentTemplates;
  }
  get editExercise() {
    return this._editExercise;
  }

  addExercise(exercise) {
    const newExercises = [...this._template.exercises, exercise];
    this._template.exercises = newExercises;
  }

  removeExercise(idExercise) {
    const newExercises = this._template.exercises.filter((item) => item.id !== idExercise);
    this._template.exercises = newExercises;
  }

  editCurrentExercise(edExercise) {
    this._template.exercises = [...this._template.exercises, edExercise];
  }
  selectTemplateType(type) {
    console.log(type);
    const currentTemplates = this._templates.filter((item) => item.type === +type);
    console.log('currentTemplates', currentTemplates);
    this._currentTemplates = currentTemplates;
  }
}
