import { Avatar, notification } from 'antd';
import { errorIcon, successIcon } from '../assets/icons';
import React from 'react';

export const notificationMessage = (title, description, icon) => {
  return notification.success({
    message: <div style={{ fontSize: 13 }}>{title}</div>,
    description: <p style={{ fontSize: 12 }}>{description}</p>,
    icon: <Avatar src={icon} />,
    duration: 2
  });
};

export const openNotification = (res) => {
  if (res?.status === 200 || res?.status === 201) {
    return notificationMessage('Выполнено', 'Операция выполнена успешно', successIcon);
  } else if (res?.response?.status === 400 || res?.response?.data?.statusCode === 400) {
    const messageValue =
      res?.response.data.error !== 'Bad Request' ? res?.response.data.error : 'Некорректные данные';
    return notificationMessage('Ошибка', messageValue, errorIcon);
  } else if (res?.response?.data?.statusCode === 409 || res?.response?.data?.statusCode === 409) {
    return notificationMessage('Ошибка', res?.response.data.error, errorIcon);
  } else if (res?.response?.data?.statusCode === 404) {
    return notificationMessage('Ошибка', res?.response?.data?.error, errorIcon);
  } else {
    return notificationMessage('Ошибка', 'Непредвиденная ошибка', errorIcon);
  }
};
