import React, { useContext } from 'react';
import { openNotification } from '../../../../utils';
import { Button, Form, Input } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { WORKOUT_STATUS } from '../../../../constants';
import { Context } from '../../../../index';
import { observer } from 'mobx-react-lite';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const TrainingCommentForm = ({ actualWorkout }) => {
  const { workout, user } = useContext(Context);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ comment: sessionStorage.getItem('comment') ?? '' });
  }, []);

  const onFinish = async (values) => {
    if (actualWorkout?.id) {
      const res = await workout.updateWorkout(
        actualWorkout.id,
        WORKOUT_STATUS.DONE,
        values.comment
      );
      await workout.getEmployeeWorkouts(user.user.id);
      sessionStorage.removeItem('comment');
      form.resetFields();
      openNotification(res);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const preSaveHandler = useDebouncedCallback((e) => {
    sessionStorage.setItem('comment', e.target.value);
  }, 1000);

  return (
    <div className="comment-form-wrapper">
      <Form
        form={form}
        className="comment-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item className="comment-form-input-wrapper" name="comment">
          <Input.TextArea
            onChange={(e) => preSaveHandler(e)}
            rows={4}
            className="comment-form-input"
            placeholder="Опишите результаты тренировки"
            disabled={actualWorkout?.id ? false : true}
          />
        </Form.Item>
        <Form.Item className="comment-form-btn-wrapper">
          <Button
            disabled={actualWorkout?.id ? false : true}
            htmlType="submit"
            icon={<SaveOutlined />}
            className="comment-form-btn">
            Зафиксировать результат
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

TrainingCommentForm.propTypes = {
  actualWorkout: PropTypes.object
};

export default observer(TrainingCommentForm);
