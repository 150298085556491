// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-reference {
  font-size: 11px;
}
.signin-form-link {
  color: #769ccd;
  cursor: pointer;
  margin: 0 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/client-auth/reference-block/reference-block.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;EACf,aAAa;AACf","sourcesContent":[".form-reference {\n  font-size: 11px;\n}\n.signin-form-link {\n  color: #769ccd;\n  cursor: pointer;\n  margin: 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
