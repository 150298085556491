import React from 'react';
import { Avatar, Button, Card } from 'antd';
import { templ } from '../../../../../assets/images';
import WorkoutCardCollapse from './WorkoutCardCollapse/WorkoutCardCollapse';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Context } from '../../../../../index';
import { observer } from 'mobx-react-lite';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import './workout-template-card.css';

const WorkoutTemplateCard = ({ item, setActiveTemplate, activeTemplate }) => {
  const { templateElement } = useContext(Context);
  return (
    <Card className={`template-card ${activeTemplate === item.id ? 'active' : ''}`} key={item.id}>
      <div style={{ marginBottom: 10 }}>
        <div className="template-card-content">
          <Avatar src={templ} className="template-card-avatar" />
          <div>
            <h4 className="template-title">Шаблон: {`"${item.title}"`}</h4>
            <span className="template-description">{item.description}</span>
          </div>
          <div className="template-level">Уровень: {item.level}</div>
        </div>
        <WorkoutCardCollapse item={item} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className="template-accept-btn"
          size="small"
          icon={<CheckOutlined />}
          onClick={async () => {
            await templateElement.getTemplateElementsByTemplateId(item.id);
            setActiveTemplate(item.id);
          }}>
          Применить
        </Button>
        <Button
          className="template-decline-btn"
          onClick={() => {
            templateElement.setTemplateElements([]);
            setActiveTemplate(null);
          }}
          size="small"
          icon={<CloseOutlined />}>
          Отмена
        </Button>
      </div>
    </Card>
  );
};

WorkoutTemplateCard.propTypes = {
  item: PropTypes.object,
  activeTemplate: PropTypes.number,
  setActiveTemplate: PropTypes.func
};
export default observer(WorkoutTemplateCard);
