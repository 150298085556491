// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.training-container {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  height: 100%;
  max-height: 80vh;
  overflow-y: auto;
}
.t-timeline-container {
  width: 100%;
  padding: 10px;
  max-height: 65vh;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/training/client-training-page/client-training-page.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".training-container {\n  background-color: #ffffff;\n  padding: 10px;\n  border-radius: 10px;\n  height: 100%;\n  max-height: 80vh;\n  overflow-y: auto;\n}\n.t-timeline-container {\n  width: 100%;\n  padding: 10px;\n  max-height: 65vh;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
