import { Card } from 'antd';
import PropTypes from 'prop-types';

const TimeBlockWrapper = ({ children }) => {
  return (
    <div className="card-content-wrapper">
      <Card className="card-content-body">
        <div className="body-standards-wrapper">
          <div className="standards-item-wrapper">{children}</div>
        </div>
      </Card>
    </div>
  );
};

TimeBlockWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
export default TimeBlockWrapper;
