import { Button, Card, Form, Input } from 'antd';
import { openNotification } from '../../../utils';
import { useContext } from 'react';
import { Context } from '../../../index';
import { APP_AUTH_FORM_FIELDS } from '../../../config';
import { partnerLogo } from '../../../assets/images';
import './partner-auth-form.css';

const { Meta } = Card;

const PartnerAuthForm = () => {
  const { auth } = useContext(Context);
  const { signInPartnerFields } = APP_AUTH_FORM_FIELDS;
  localStorage.setItem('type', 'partner');
  const onFinish = async ({ phone, password }) => {
    const res = await auth.signinPartner(phone, password);
    if (res) {
      openNotification(res);
    }
  };

  return (
    <Card
      className="partner-form-card"
      bordered={true}
      cover={<img alt="example" src={partnerLogo} className="partner-form-cover" />}>
      <Meta title="Авторизация" style={{ marginBottom: 10 }}>
        <span>Partner</span>
      </Meta>
      <Form name="normal_login" className="sign-in-form" onFinish={onFinish} autoComplete="off">
        {signInPartnerFields &&
          signInPartnerFields.map((field) => (
            <Form.Item
              hasFeedback
              validateDebounce={1000}
              key={field.name}
              name={field.name}
              rules={field.rules}
              validateTrigger="onBlur">
              <Input
                allowClear
                maxLength={field.maxLength ?? undefined}
                prefix={field.icon}
                type={field.type}
                placeholder={field.placeholder}
              />
            </Form.Item>
          ))}
        <Form.Item>
          <Button className="partner-form-btn" type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default PartnerAuthForm;
