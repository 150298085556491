import { Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../index';
import InformerItem from '../../../partner-training/InformerBlock/InformerItem';
import { observer } from 'mobx-react-lite';
import React from 'react';

const InformerBlock = () => {
  const { recording, workout, user } = useContext(Context);
  const [allWorkouts, setAllWorkouts] = useState(0);
  const [activeWorkouts, setActiveWorkouts] = useState(0);
  const [allRecording, setAllRecording] = useState(0);

  useEffect(() => {
    const getData = async () => {
      const userId = user.user.id;
      if (!userId) {
        return;
      }
      await workout.getClientWorkouts(userId);
      await user.getAllEmployeeUsers();
      await recording.getAllRecByEmployeeIdOrClientId(userId);
    };
    getData()
      .then(() => {
        setAllWorkouts(workout.count);
        setActiveWorkouts(workout.activeCount);
        setAllRecording(recording.clientRecordingCount);
      })
      .catch((e) => console.log(e));
  }, [workout.workouts.length, recording.clientsRecording?.length]);

  return (
    <Row gutter={5} style={{ width: '100%' }}>
      <InformerItem
        size={8}
        loading={recording.isLoading}
        data={allRecording}
        title="Запланированные тренировки"
        color="#5a8ebd"
      />
      <InformerItem
        size={8}
        loading={workout.isLoading}
        data={activeWorkouts}
        title="Незавершенные тренировки"
        color="#FF5733"
      />
      <InformerItem
        size={8}
        loading={workout.isLoading}
        data={allWorkouts}
        title="Проведенные тренировки"
        color="#3f8600"
      />
    </Row>
  );
};

export default observer(InformerBlock);
