import React from 'react';
import { Tooltip, Image } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const CollapsedItem = ({ item, clickHandler }) => {
  return (
    <>
      {!item.setOpen ? (
        <Tooltip title={item.label} placement="right" color="#769CCD">
          <Link to={item.disabled ? '#' : item.to} disabled={item.disabled}>
            <div
              className={`outer-menu-item-wrapper ${item.disabled && 'inactive'}`}
              onClick={clickHandler}>
              <div className={`inner-menu-item-wrapper ${item.active ? 'active' : ''}`}>
                <Image preview={false} src={item.img} width={35} />
                {!item.collapsed && <div>{item.label}</div>}
              </div>
            </div>
          </Link>
        </Tooltip>
      ) : (
        <Link to={item.disabled ? '#' : item.to} disabled={item.disabled}>
          <div
            className={`outer-menu-item-wrapper ${item.disabled && 'inactive'}`}
            onClick={clickHandler}>
            <div className={`inner-menu-item-wrapper ${item.active ? 'active' : ''}`}>
              <Image preview={false} src={item.img} width={35} />
              {!item.collapsed && <div>{item.label}</div>}
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

CollapsedItem.propTypes = {
  item: PropTypes.object,
  clickHandler: PropTypes.func
};

export default CollapsedItem;
