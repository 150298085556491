// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-profile-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.form-profile-column {
  width: 100%;
}
.form-profile-field {
  margin-bottom: 12px;
}
.form-profile-second-column {
  width: 100%;
  margin: 0px 10px 25px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.form-profile-button {
  font-size: 11px;
  color: #ffffff !important;
  border-color: #ffffff !important;
}
.form-profile-button:hover {
  font-size: 11px;
  color: #ffffff !important;
  background-color: #6b7a99 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Layout/ProfileForm/profile-form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,0BAA0B;EAC1B,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,gCAAgC;AAClC;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,oCAAoC;AACtC","sourcesContent":[".form-profile-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n.form-profile-column {\n  width: 100%;\n}\n.form-profile-field {\n  margin-bottom: 12px;\n}\n.form-profile-second-column {\n  width: 100%;\n  margin: 0px 10px 25px 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.form-profile-button {\n  font-size: 11px;\n  color: #ffffff !important;\n  border-color: #ffffff !important;\n}\n.form-profile-button:hover {\n  font-size: 11px;\n  color: #ffffff !important;\n  background-color: #6b7a99 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
