import { useContext, useEffect, useState } from 'react';
import { Col } from 'antd';
import ConfWorkoutList from './ConfWorkoutList';
import { SmallSpinner } from '../../../index';
import { Context } from '../../../../index';
import { workoutList } from '../../../../utils';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { WORKOUT_STATUS } from '../../../../constants';
import './confirmed-workout.css';
import React from 'react';

const ConfWorkout = ({ title, columns, size, confirmed, img }) => {
  const { user, workout } = useContext(Context);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getBaseDate = async () => {
      setLoading(true);
      await workout.getEmployeeWorkouts(user.user.id);
      await user.getAllUsers();
    };
    getBaseDate()
      .then(() => {
        const confirmedData = workout.workouts.filter(
          (item) => item.status === WORKOUT_STATUS.DONE
        );
        const newData = workout.workouts.filter((item) => item.status === WORKOUT_STATUS.NEW);
        const infoDate = confirmed ? confirmedData : newData;
        const info = workoutList(infoDate, user.users);
        setData(info);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [workout.workouts?.rows?.length, workout.workout]);

  return (
    <Col
      xl={{ span: size }}
      lg={{ span: size }}
      md={{ span: size }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}>
      {workout.isLoading || loading ? (
        <SmallSpinner />
      ) : (
        <ConfWorkoutList title={title} columns={columns} data={data} img={img} />
      )}
    </Col>
  );
};

ConfWorkout.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  size: PropTypes.number,
  confirmed: PropTypes.bool,
  img: PropTypes.string
};
export default observer(ConfWorkout);
