import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useContext, useEffect, useState } from 'react';
import { ViewEventModal, CreateEventModal, CustomEvent } from '../../components';
import { Card, Col, Row } from 'antd';
import PageLabel from '../../components/common/PageLabel/PageLabel';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { APP_CALENDAR } from '../../config';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import { calendarIcon } from '../../assets/icons';
// eslint-disable-next-line no-undef
require('moment/locale/ru');
import './calendar-page.css';

const localizer = momentLocalizer(moment);
const CalendarPage = () => {
  const { calendar } = useContext(Context);
  const { defaultMessages, formats } = APP_CALENDAR;

  useEffect(() => {
    const getEvents = async () => await calendar.getAllEvents();
    getEvents().catch(console.error);
  }, []);

  const [openSlot, setOpenSlot] = useState(false);
  const [openEvent, setOpenEvent] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const type = localStorage.getItem('type');
  const slotCancel = () => setOpenSlot(false);
  const eventCancel = () => setOpenEvent(false);
  const slotOk = () => setOpenSlot(false);
  const eventOk = () => setOpenEvent(false);
  const showSlotModal = () => setOpenSlot(true);
  const showEventModal = () => setOpenEvent(true);
  const handleSelect = ({ start, end }) => {
    showSlotModal();
    setStart(start);
    setEnd(end);
  };
  const handleEventSelect = (e) => {
    showEventModal();
    setCurrentEvent(e);
    setStart(e.start);
    setEnd(e.end);
  };

  const myEvent = (event) => <CustomEvent event={event} />;

  return (
    <Row className="informer-container">
      <Col className="informer-item" xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card
          title={<PageLabel img={calendarIcon} text="Мероприятия" />}
          style={{ maxHeight: '80vh', height: '100%' }}>
          <Calendar
            events={calendar.events}
            components={{ event: myEvent }}
            tooltipAccessor={() => ''}
            selectable
            localizer={localizer}
            formats={formats}
            startAccessor={(event) => {
              return new Date(event.start);
            }}
            endAccessor={(event) => {
              return new Date(event.end);
            }}
            style={{ height: 600 }}
            messages={defaultMessages}
            onSelectSlot={type ? handleSelect : undefined}
            onSelectEvent={(e) => handleEventSelect(e)}
          />
        </Card>
        {type === null ? (
          // eslint-disable-next-line react/jsx-no-undef
          <ViewEventModal
            currentEvent={currentEvent}
            handleOk={eventOk}
            handleCancel={eventCancel}
            open={openEvent}
          />
        ) : (
          <>
            <ViewEventModal
              currentEvent={currentEvent}
              handleOk={eventOk}
              handleCancel={eventCancel}
              open={openEvent}
            />
            <CreateEventModal
              start={start}
              end={end}
              open={openSlot}
              handleCancel={slotCancel}
              handleOk={slotOk}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default observer(CalendarPage);
