import React from 'react';
import { Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../index';
import { observer } from 'mobx-react-lite';
import InformerItem from './InformerItem';
import './informer-block.css';

const InformerBlock = () => {
  const { recording, workout, user, auth } = useContext(Context);
  const [allWorkouts, setAllWorkouts] = useState(0);
  const [confirmedWorkouts, setConfirmedWorkouts] = useState(0);
  const [allRecording, setAllRecording] = useState(0);
  const [assignedWorkouts, setAssignedWorkout] = useState(0);
  useEffect(() => {
    const userId = auth.partner.id ?? user.user.id;
    if (!userId) {
      return;
    }
    const getData = async () => {
      await workout.getEmployeeWorkouts(userId);
    };
    getData()
      .then(() => {
        setAllWorkouts(workout.count);
        setConfirmedWorkouts(workout.confirmedCount);
        setAssignedWorkout(workout.assignedCount);
        setAllRecording(recording.clientRecordingCount);
      })
      .catch((e) => console.log(e));
  }, [workout.workouts.length, workout.workout, recording.clientsRecording?.length]);

  return (
    <Row className="informer-wrapper" gutter={5}>
      <InformerItem
        size={6}
        loading={recording.isLoading}
        data={allRecording}
        title="Записи на тренировку"
        color="#5a8ebd"
      />
      <InformerItem
        size={6}
        loading={workout.isLoading}
        data={assignedWorkouts}
        title="Назначенные тренировки"
        color="#643c90"
      />
      <InformerItem
        size={6}
        loading={workout.isLoading}
        data={confirmedWorkouts}
        title="Тренировки без верификации"
        color="#FF5733"
      />
      <InformerItem
        size={6}
        loading={workout.isLoading}
        data={allWorkouts}
        title="Проведенные тренировки"
        color="#3f8600"
      />
    </Row>
  );
};

export default observer(InformerBlock);
