import { Tabs } from 'antd';
import { APP_PARTNER_FAQ_TABS } from '../../../config';
import { getTabsPosition } from '../../../utils';

const PartnerFaqPage = () => {
  // eslint-disable-next-line no-unused-vars
  const onChange = (key) => {
    return;
  };
  
  return (
    <Tabs
      className="client-faq-tabs"
      defaultActiveKey="1"
      items={APP_PARTNER_FAQ_TABS.partnerFaqItems}
      onChange={onChange}
      tabPosition={getTabsPosition()}
    />
  );
};

export default PartnerFaqPage;
