import { makeAutoObservable } from 'mobx';
import { WorkoutService } from '../../services';
import { WORKOUT_STATUS } from '../../constants';

export default class WorkoutStore {
  isLoading = false;
  _count = 0;
  _activeCount = 0;
  _confirmedCount = 0;
  _assignedCount = 0;
  _workout = {};
  _workouts = [];
  _workoutClientInfo = {};

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setCount(count) {
    this._count = count;
  }
  setActiveCount(activeCount) {
    this._activeCount = activeCount;
  }
  setConfirmedCount(confirmedCount) {
    this._confirmedCount = confirmedCount;
  }
  setAssignedCount(assignedCount) {
    this._assignedCount = assignedCount;
  }
  setWorkouts(workouts) {
    this._workouts = workouts;
  }
  setWorkout(workout) {
    this._workout = workout;
  }
  setWorkoutClientInfo(workoutClientInfo) {
    this._workoutClientInfo = workoutClientInfo;
  }
  get workouts() {
    return this._workouts;
  }
  get workout() {
    return this._workout;
  }
  get workoutClientInfo() {
    return this._workoutClientInfo;
  }
  get count() {
    return this._count;
  }
  get activeCount() {
    return this._activeCount;
  }
  get confirmedCount() {
    return this._confirmedCount;
  }
  get assignedCount() {
    return this._assignedCount;
  }

  async createWorkout(employeeId, clientId, location, date, time, status, type) {
    try {
      const res = await WorkoutService.createWorkout(
        employeeId,
        clientId,
        location,
        date,
        time,
        status,
        type
      );
      await this.getAllWorkouts();
      this.setWorkout(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async getAllWorkouts() {
    this.setLoading(true);
    try {
      const res = await WorkoutService.getAllWorkouts();
      this.setWorkouts(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getWorkoutById(id) {
    try {
      const res = await WorkoutService.getWorkoutById(id);
      this.setWorkout(res.data);
    } catch (error) {
      return error;
    }
  }

  async getClientWorkouts(id) {
    this.setLoading(true);
    try {
      const res = await WorkoutService.getAllClientWorkouts(id);
      if (res.data) {
        const completeWorkout = res.data.rows.filter(
          (item) => item.status === WORKOUT_STATUS.CLOSED
        );
        const activeWorkout = res.data.rows.filter((item) => item.status === WORKOUT_STATUS.ASSIGN);
        this.setWorkouts(res.data.rows);
        this.setCount(completeWorkout.length);
        this.setActiveCount(activeWorkout.length);
      }
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }

  async getEmployeeWorkouts(id) {
    this.setLoading(true);
    try {
      const res = await WorkoutService.getAllEmployeeWorkouts(id);
      if (res.data) {
        const completeWorkout = res.data.rows.filter(
          (item) => item.status === WORKOUT_STATUS.CLOSED
        );
        const confirmedWorkout = res.data.rows.filter(
          (item) => item.status === WORKOUT_STATUS.DONE
        );
        const assignedWorkout = res.data.rows.filter(
          (item) => item.status === WORKOUT_STATUS.ASSIGN
        );
        this.setWorkouts(res.data.rows);
        this.setCount(completeWorkout.length);
        this.setConfirmedCount(confirmedWorkout.length);
        this.setAssignedCount(assignedWorkout.length);
      }
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async updateWorkout(id, status, comment) {
    try {
      const res = await WorkoutService.updateWorkout(id, status, comment);
      this.setWorkout(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async confirmedWorkout(id, confirmed) {
    try {
      const res = await WorkoutService.confirmedWorkout(id, confirmed);
      this.setWorkout(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
}
