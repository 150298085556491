import { Card } from 'antd';

const Jotting = () => {
  return (
    <Card className="jotting-card">
      <div className="jotting-card-header">
        <h3 className="jotting-card-title">Дисциплины в фридайвинге</h3>
      </div>
      <div className="jotting-card-content">
        <ul className="jotting-card-list">
          <li>STA – статическое апноэ (на открытой воде или в бассейне);</li>
          <li>CNF – погружение с постоянным весом без ласт (в глубину);</li>
          <li>CWT – погружение с постоянным весом в ластах (или моноласте, в глубину);</li>
          <li>
            CWTB – погружение с постоянным весом в раздельных ластах (только в раздельных ластах, в
            глубину);
          </li>
          <li>FIM – свободное погружение (в глубину по тросу на руках);</li>
          <li> DNF – динамическое апноэ без ласт (в длину в бассейне или на открытой воде);</li>
          <li>
            DYN – динамическое апноэ в ластах (или моноласте, в длину в бассейне или на открытой
            воде);
          </li>
          <li>DYNB – динамическое апноэ в ластах (только в раздельных ластах, в длину);</li>
          <li>NLT – погружение без ограничений (в глубину);</li>
          <li>VWT – погружение с переменным весом (в глубину)</li>
        </ul>
      </div>
    </Card>
  );
};

export default Jotting;
