// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rec-form-card {
  min-height: 50vh;
  background-color: #ffffff;
  max-width: 80%;
  width: 500px;
}
.rec-form-card.ant-card .ant-card-body {
  padding: 10px;
}
.rec-card-img {
  max-width: 500px;
  width: 100%;
}
.rec-form {
  max-width: 500px;
}
.rec-form-item {
  width: 100%;
}
.rec-form-checkbox {
  font-size: 9px !important;
}
.rec-form-btn {
  width: 100%;
  background-color: #769ccd;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/recording/recording-form/recording-form.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;EACd,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".rec-form-card {\n  min-height: 50vh;\n  background-color: #ffffff;\n  max-width: 80%;\n  width: 500px;\n}\n.rec-form-card.ant-card .ant-card-body {\n  padding: 10px;\n}\n.rec-card-img {\n  max-width: 500px;\n  width: 100%;\n}\n.rec-form {\n  max-width: 500px;\n}\n.rec-form-item {\n  width: 100%;\n}\n.rec-form-checkbox {\n  font-size: 9px !important;\n}\n.rec-form-btn {\n  width: 100%;\n  background-color: #769ccd;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
