import { makeAutoObservable } from 'mobx';

export default class UserMenuStore {
  _item = '';

  constructor() {
    makeAutoObservable(this);
  }
  setActiveItem(item) {
    this._item = item;
  }
  get activeItem() {
    return this._item;
  }
}
