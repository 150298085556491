import PropTypes from 'prop-types';
import './workout-template-wrapper.css';

const WorkoutTemplateWrapper = ({ children }) => {
  return (
    <div className="custom-outer-wrapper">
      <div className="custom-inner-wrapper">{children}</div>
    </div>
  );
};

WorkoutTemplateWrapper.propTypes = {
  children: PropTypes.any
};

export default WorkoutTemplateWrapper;
