// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recording-data-container {
  padding: 5px;
  border: 1px solid #dddddd;
  border-radius: 10px;
}
.recording-info-container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 10px;
  font-size: 12px;
}
.recording-avatar {
  border: 3px solid #5a8ebd;
}
.recording-btn {
  font-size: 12px;
}
.recording-comment-container {
  padding: 10px;
  margin-top: 5px;
  min-height: 80px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/partner-training/scheduled-workouts/recording-modal/recording-modal.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".recording-data-container {\n  padding: 5px;\n  border: 1px solid #dddddd;\n  border-radius: 10px;\n}\n.recording-info-container {\n  padding: 10px;\n  display: flex;\n  justify-content: space-between;\n  border: 1px solid #dddddd;\n  border-radius: 10px;\n  font-size: 12px;\n}\n.recording-avatar {\n  border: 3px solid #5a8ebd;\n}\n.recording-btn {\n  font-size: 12px;\n}\n.recording-comment-container {\n  padding: 10px;\n  margin-top: 5px;\n  min-height: 80px;\n  border: 1px solid #dddddd;\n  border-radius: 10px;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
