import React from 'react';
import { LockOutlined } from '@ant-design/icons';

const editProfileFields = [
  {
    name: 'password',
    rules: [
      { required: true, message: 'Пожалуйста, введите свой пароль!' },
      {
        min: 6,
        message: 'Значение должно быть не меньше 6 символов!'
      },
      {
        max: 12,
        message: 'Значение должно быть не больше 12 символов!'
      },
      {
        pattern: new RegExp(
          /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/g
        ),
        message: 'Пожалуйста, введите корректный пароль!'
      }
    ],
    icon: <LockOutlined style={{ marginRight: 10 }} />,
    type: 'password',
    placeholder: 'Пароль'
  },
  {
    name: 'confirm',
    rules: [
      {
        required: true,
        message: 'Пожалуйста повторите свой пароль!'
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Пароли, которые вы ввели, не совпадают!'));
        }
      })
    ],
    icon: <LockOutlined style={{ marginRight: 10 }} />,
    type: 'password',
    placeholder: 'Повторите пароль'
  }
];

export const APP_EDIT_PROFILE_FORM_FIELDS = { editProfileFields };
