import { Avatar } from 'antd';
import { avatar } from '../../../../../assets/images';
import { AntDesignOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../index';
import { observer } from 'mobx-react-lite';
import './user-avatar.css';

const UserAvatar = ({ collapsed }) => {
  const { auth, user } = useContext(Context);
  const [userAvatar, setUserAvatar] = useState(avatar);
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;

  useEffect(() => {
    if (localStorage.getItem('type') === 'partner') {
      const partnerAvatar = user.user.employee?.img;
      if (partnerAvatar) {
        setUserAvatar(`${URL}static/avatars/${partnerAvatar}`);
      }
    } else {
      const clientAvatar = user.user.client?.img;
      if (clientAvatar) {
        setUserAvatar(`${URL}static/avatars/${clientAvatar}`);
      }
    }
  }, [
    auth.client.client?.img,
    auth.partner.employee?.img,
    user.user.employee?.img,
    user.user.client?.img
  ]);

  return (
    <div className="outer-wrapper">
      <div className="inner-wrapper">
        <div className="avatar-container">
          <Avatar
            className="avatar-image"
            style={{ display: collapsed ? 'none' : '' }}
            src={userAvatar}
            icon={<AntDesignOutlined />}
          />
        </div>
      </div>
    </div>
  );
};

UserAvatar.propTypes = {
  collapsed: PropTypes.bool
};
export default observer(UserAvatar);
