// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-card tr > th {
  font-size: 11px;
}
.catalog-card tr > td {
  font-size: 11px;
  cursor: pointer;
}

@media only screen and (max-width: 440px) {
  .catalog-card .ant-card-body {
    padding: 10px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/training/catalogs-settings/catalog-item-content/catalog-item-content.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".catalog-card tr > th {\n  font-size: 11px;\n}\n.catalog-card tr > td {\n  font-size: 11px;\n  cursor: pointer;\n}\n\n@media only screen and (max-width: 440px) {\n  .catalog-card .ant-card-body {\n    padding: 10px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
