const SIGNIN_PARTNER_ROUTE = '/partner/signin';
const TRAINING_PARTNER_ROUTE = '/partner/training';
const COMPETITION_PARTNER_ROUTE = '/partner/competition';
const PAYMENT_PARTNER_ROUTE = '/partner/payment';
const FAQ_PARTNER_ROUTE = '/partner/faq';
const NEWSLETTER_ROUTE = '/newsletter';
const CLIENTS_ROUTE = '/clients';

export const PARTNER_PATHS = {
  NEWSLETTER_ROUTE,
  FAQ_PARTNER_ROUTE,
  CLIENTS_ROUTE,
  PAYMENT_PARTNER_ROUTE,
  TRAINING_PARTNER_ROUTE,
  COMPETITION_PARTNER_ROUTE,
  SIGNIN_PARTNER_ROUTE
};
