import { Spin } from 'antd';
import './app-spinner.css';
const Spinner = () => {
  return (
    <div className="app-spinner-container">
      <Spin />
    </div>
  );
};

export default Spinner;
