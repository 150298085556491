import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import './row-info.css';

const RowInfo = ({ clientInfo }) => {
  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState('');
  // eslint-disable-next-line no-undef,no-unused-vars
  const URL = process.env.REACT_APP_API_STATIC_URL;
  useEffect(() => {
    setAvatar(`${URL}static/avatars/${clientInfo?.client?.img}`);
    setName(`${clientInfo?.client?.name} ${clientInfo?.client?.surname}`);
  }, []);
  return (
    <div className="row-info-wrapper">
      <Avatar src={avatar} className="t-card-avatar" />
      {name}
    </div>
  );
};

RowInfo.propTypes = {
  clientInfo: PropTypes.object
};

export default RowInfo;
