import { Avatar } from 'antd';
import { sv } from '../../../../../assets/images';
import { EnvironmentOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const ConfirmedModalHeader = ({ data }) => {
  // eslint-disable-next-line no-undef,no-unused-vars
  const URL = process.env.REACT_APP_API_STATIC_URL;

  return (
    <div className="conf-workout-modal-header">
      <div>
        <Avatar
          className="conf-workout-modal-avatar"
          src={
            data?.clientData?.client?.client?.img
              ? `${URL}static/avatars/${data?.clientData?.client?.client?.img}`
              : sv
          }
          size={64}
        />
      </div>
      <div>
        <div>{data?.clientData?.client?.client?.name}</div>
        <div>{data?.clientData?.client?.client?.surname}</div>
      </div>
      <div>
        <div>{data?.time}</div>
      </div>
      <div>
        <div>
          <EnvironmentOutlined /> {data?.location}
        </div>
      </div>
    </div>
  );
};

ConfirmedModalHeader.propTypes = {
  data: PropTypes.object
};

export default ConfirmedModalHeader;
