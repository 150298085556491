import $api from '../../http';

export default class WorkoutElementService {
  static async createWorkoutElement(workoutId, exerciseId, plan, fact, description) {
    return $api.post('/workout-elements/', { workoutId, exerciseId, plan, fact, description });
  }
  static async getAllWorkoutElements() {
    return $api.get('/workout-elements/');
  }
  static async getWorkoutElementById(id) {
    return $api.get(`/workout-elements/${id}`);
  }
  static async updateWorkoutElement(id, fact) {
    return $api.patch(`/workout-elements/${id}`, { fact });
  }
}
