import React from 'react';
import { Avatar, Card, Image } from 'antd';
import PropTypes from 'prop-types';
import { swim, swimWhite } from '../../../../../assets/icons';
import { logoMini } from '../../../../../assets/images';
import dayjs from 'dayjs';
import './timeline-card.css';

const TimelineCard = ({ data, setItemHandler, active }) => {
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;

  return (
    <Card
      onClick={() => setItemHandler(data)}
      size="small"
      className={`t-timeline-card ${active ? 'active' : ''}`}
      title={<Image src={active ? swimWhite : swim} width={25} />}
      extra={
        <span style={{ color: active ? '#fff' : '#769CCD', fontSize: 12 }}>
          {data.time} {dayjs(data.date).format('DD-MM-YYYY')}
        </span>
      }>
      <div className="t-timeline-card-wrapper">
        <div className="t-timeline-card-content">
          <div className="t-timeline-trainer-info">
            <Avatar
              size={55}
              src={data?.trainer?.img ? `${URL}static/avatars/${data?.trainer?.img}` : logoMini}
              className="t-card-avatar"
            />
            <div className="t-timeline-trainer-name">
              <p>
                <b>Тренер</b>
              </p>
              <p>
                {data?.trainer?.name} {data?.trainer?.surname}
              </p>
            </div>
          </div>
          <div className="t-timeline-location-info">
            <div>
              <b>Локация</b>
            </div>
            <div>{data?.location}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

TimelineCard.propTypes = {
  data: PropTypes.object,
  setItemHandler: PropTypes.func,
  active: PropTypes.bool
};

export default TimelineCard;
