import { GoogleOutlined } from '@ant-design/icons';
import { YandexIcon } from '../../../common/Icons';
import { useContext } from 'react';
import { Context } from '../../../../index';
import './icons-block.css';

const IconsBlock = () => {
  const { auth } = useContext(Context);
  const googleHandler = () => auth.googleAuth();
  const yandexHandler = () => auth.yandexAuth();

  return (
    <>
      <GoogleOutlined className="auth-icon" onClick={googleHandler} />
      <YandexIcon className="auth-icon" onClick={yandexHandler} />
    </>
  );
};

export default IconsBlock;
