import $api from '../../http';

export default class UserService {
  static async getAllUsers() {
    return $api.get('/users/');
  }
  static async getAllEmployeeUsers() {
    return $api.get('/users/employees/');
  }
  static async getUserById(id) {
    return $api.get(`/users/${id}`);
  }
  static async getUserByEmailOrPhone(emailOrPhone) {
    return await $api.get(`/users/find/${emailOrPhone}`);
  }

  static async updateUser(id, email, phone) {
    return $api.patch(`/users/${id}`, { email, phone });
  }
  static async updateUserPassword(id, password) {
    return $api.patch(`/users/update/${id}`, { password });
  }
  static async updateUserAgreement(id, agreement) {
    return $api.patch(`/users/agreement/${id}`, { agreement });
  }
}
