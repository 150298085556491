export const selectUserTimes = (data) => {
  let userTimes = [];
  data?.map((user) => {
    const uniqueTime = userTimes.filter((item) => item.value === user.time);
    if (uniqueTime.length === 0) {
      userTimes.push({
        value: user.time,
        label: user.time
      });
    }
  });
  return userTimes;
};

export const selectTempLevel = (data) => {
  let levelsData = [];
  data
    .slice()
    .sort((a, b) => a.id - b.id)
    .map((level) => {
      const uniqueLevel = levelsData.filter((item) => item.value === level.level);
      if (uniqueLevel.length === 0) {
        levelsData.push({
          value: level.level,
          label: level.level
        });
      }
    });
  return levelsData;
};
