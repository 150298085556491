import $api from '../../http';

export default class LocationService {
  static async createLocation(name, shortName, address) {
    return $api.post('/locations/', { name, shortName, address });
  }
  static async getAllLocations() {
    return $api.get('/locations/');
  }
  static async getLocationById(id) {
    return $api.get(`/locations/${id}`);
  }
  static async updateLocation(id, name, shortName, address) {
    return $api.patch(`/locations/${id}`, { name, shortName, address });
  }
  static async deleteLocationById(id) {
    return $api.delete(`/locations/${id}`);
  }
}
