const exerciseFields = [
  {
    name: 'name',
    rules: { required: true, message: 'Укажите название упражнения!' },
    type: 'text',
    placeholder: 'Упражнение'
  },
  {
    name: 'description',
    rules: { required: true, message: 'Укажите описание!' },
    class: 'input-icon',
    type: 'text',
    placeholder: 'Описание'
  },
  {
    name: 'type',
    rules: { required: true, message: 'Укажите тип упражнения!' },
    class: 'input-icon',
    type: 'text',
    placeholder: 'Тип упраженения'
  }
];

const workoutProfile = [
  {
    name: 'name',
    rules: { required: true, message: 'Укажите название профиля тренировки!' },
    type: 'text',
    placeholder: 'Профиль'
  },
  {
    name: 'description',
    rules: { required: true, message: 'Укажите описание профиля тренировки!' },
    class: 'input-icon',
    type: 'text',
    placeholder: 'Описание'
  },
  {
    name: 'appointment',
    rules: { required: true, message: 'Укажите назначение профиля тренировки!' },
    class: 'input-icon',
    type: 'text',
    placeholder: 'Назначение'
  }
];

const locationFields = [
  {
    name: 'name',
    rules: { required: true, message: 'Укажите название!' },
    class: 'input-icon',
    type: 'text',
    placeholder: 'Название'
  },
  {
    name: 'shortName',
    rules: { required: true, message: 'Укажите короткое название!' },
    class: 'input-icon',
    type: 'text',
    placeholder: 'Короткое название'
  },
  {
    name: 'address',
    rules: { required: true, message: 'Укажите адрес!' },
    class: 'input-icon',
    type: 'text',
    placeholder: 'Адрес'
  }
];

export const APP_CATALOG_FORM_FIELDS = { exerciseFields, locationFields, workoutProfile };
