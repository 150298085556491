import { Button, Form, message, Popover, Upload } from 'antd';
import { InfoCircleOutlined, LoadingOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { Context } from '../../../../index';
import PropTypes from 'prop-types';
import './avatar-form.css';
import { observer } from 'mobx-react-lite';
import React from 'react';

const AvatarForm = ({ id }) => {
  const { user, employee, client } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const content = (
    <div className="avatar-popover-msg">
      <ul className="avatar-popover-list">
        <li>Необходимо использовать файлы JPG/PNG формата</li>
        <li>Максимальный размер файла 2MB</li>
      </ul>
    </div>
  );

  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      await message.error('Необходимо использовать файлы JPG/PNG формат!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      await message.error('Максимальный размер файла 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.fileList[0];
    }
    return e && e.fileList;
  };

  const onFinish = async (fileList) => {
    if (!fileList.avatar) {
      return;
    }
    const formData = new FormData();
    formData.append('avatar', fileList.avatar[0].originFileObj);

    if (localStorage.getItem('type') === 'partner') {
      const res = await employee.updateEmployeeAvatar(id, formData);
      user.user.employee.img = res?.img;
    } else {
      const res = await client.updateClientAvatar(id, formData);
      user.user.client.img = res?.img;
    }
    setImageUrl('');
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          width: '100%',
          fontSize: 12
        }}>
        Загрузить аватар <br />
        <Popover content={content} placement="topRight" title={<p>Требования к файлам</p>}>
          <InfoCircleOutlined />
        </Popover>
      </div>
    </div>
  );

  return (
    <Form onFinish={onFinish} encType="multipart/form-data" className="form-container">
      <Form.Item name="avatar" valuePropName="fileList" getValueFromEvent={normFile}>
        <Upload
          className="avatar-upload"
          accept="image/png, image/jpeg"
          maxCount={1}
          listType="picture-circle"
          showUploadList={false}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}>
          {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
      </Form.Item>
      <Button
        className="form-button"
        type="primary"
        ghost
        htmlType="submit"
        icon={<SaveOutlined />}>
        Сохранить аватар
      </Button>
    </Form>
  );
};

AvatarForm.propTypes = {
  id: PropTypes.string
};

export default observer(AvatarForm);
