import { Image } from 'antd';
import { info } from '../../../assets/icons';
import PropTypes from 'prop-types';
import './popover-message.css';
import React from 'react';

const PopoverMessage = ({ text, icon, image }) => {
  // eslint-disable-next-line no-undef
  const URL = process.env.REACT_APP_API_STATIC_URL;

  return (
    <div className="message-container">
      {icon && (
        <div className="image-container">
          <Image src={info} preview={false} alt="attantion" width={30} />
        </div>
      )}
      <div className="text-container">
        {image && (
          <div className="text-image-container">
            {image !== 'no-image.jpg' && (
              <Image
                src={`${URL}static/events/${image}`}
                className="popover-image"
                preview={false}
                alt="description"
              />
            )}
          </div>
        )}
        <p>{text}</p>
        {!icon && (
          <p>
            <b>Подробнее...</b>
          </p>
        )}
      </div>
    </div>
  );
};

PopoverMessage.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.bool,
  image: PropTypes.string
};

export default PopoverMessage;
