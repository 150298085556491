import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'antd';
import EditPasswordForm from '../../components/common/Layout/EditPasswordForm/EditPasswordForm';
import PageLabel from '../../components/common/PageLabel/PageLabel';
import { reset } from '../../assets/icons';
import { AppCopyright } from '../../components';
import './reset-password-page.css';

const ResetPasswordPage = () => {
  const [activeError, setActiveError] = useState(false);
  const { userId } = useParams();
  useEffect(() => {
    if (userId === '') {
      setActiveError(true);
    }
  }, []);
  console.log('USER_ID', userId);
  return (
    <div className="res-pass-container">
      <div className="res-pass-card-wrapper">
        <Card title={<PageLabel text="Смена пароля" img={reset} />} className="res-pass-card">
          <EditPasswordForm type="reset" disabledForm={activeError} currentUser={userId} />
        </Card>
      </div>
      <AppCopyright />
    </div>
  );
};

export default ResetPasswordPage;
