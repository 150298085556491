import $api from '../../http';

export default class TemplateElementService {
  static async createTemplateElement(templateId, exerciseId, plan, description) {
    return $api.post('/template-elements/', { templateId, exerciseId, plan, description });
  }
  static async getAllTemplateElements() {
    return $api.get('/template-elements/');
  }
  static async getTemplateElementById(id) {
    return $api.get(`/template-elements/${id}`);
  }
  static async getTemplateElementsByTemplateId(templateId) {
    return $api.get(`/template-elements/find/${templateId}`);
  }
  static async updateTemplateElement(id, plan, description) {
    return $api.patch(`/template-elements/${id}`, { plan, description });
  }
}
