import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { APP_CONTEXT } from './config';
import './index.css';

export const Context = createContext({ APP_CONTEXT });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Context.Provider
      value={{
        auth: APP_CONTEXT.authClientStore,
        calendar: APP_CONTEXT.calendarStore,
        client: APP_CONTEXT.clientStore,
        employee: APP_CONTEXT.employeeStore,
        exercise: APP_CONTEXT.exerciseStore,
        template: APP_CONTEXT.templateStore,
        templateElement: APP_CONTEXT.templateElementStore,
        user: APP_CONTEXT.userStore,
        userMenu: APP_CONTEXT.userMenuStore,
        workout: APP_CONTEXT.workoutStore,
        workoutElement: APP_CONTEXT.workoutElementStore,
        workoutTemplate: APP_CONTEXT.workoutTemplateStore,
        recording: APP_CONTEXT.clientRecordingStore,
        ticket: APP_CONTEXT.ticketStore,
        location: APP_CONTEXT.locationStore,
        workoutProfile: APP_CONTEXT.workoutProfileStore
      }}>
      <App />
    </Context.Provider>
  </React.StrictMode>
);
reportWebVitals();
