import { FontColorsOutlined, MenuOutlined, RiseOutlined } from '@ant-design/icons';
const windowInnerWidth = document.documentElement.clientWidth;

const staticTemplateFields = [
  {
    name: 'level',
    rules: [{ required: true, message: 'Укажите уровень' }],
    icon: <RiseOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'number',
    min: '0',
    style: {
      width: `${windowInnerWidth > 540 ? '11%' : '100%'} `,
      marginRight: +`${windowInnerWidth > 540 ? 5 : 0}`
    },
    placeholder: 'Уровень'
  },
  {
    name: 'title',
    rules: [{ required: true, message: 'Укажите название' }],
    icon: <FontColorsOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    style: {
      width: `${windowInnerWidth > 540 ? '20%' : '100%'} `,
      marginRight: +`${windowInnerWidth > 540 ? 5 : 0}`
    },
    placeholder: 'Название'
  },
  {
    name: 'description',
    rules: [{ required: true, message: 'Добавьте описание' }],
    icon: <MenuOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'text',
    style: { width: `${windowInnerWidth > 540 ? '50%' : '100%'} ` },
    placeholder: 'Описание'
  }
];

const dynamicTemplateFields = [
  {
    name: 'plan',
    rules: [{ required: true, message: 'Укажите значение' }],
    icon: <RiseOutlined style={{ marginRight: 10 }} />,
    class: 'input-icon',
    type: 'number',
    min: 0,
    style: {
      width: `${windowInnerWidth > 540 ? '11%' : '100%'} `,
      marginRight: +`${windowInnerWidth > 540 ? 5 : 0}`
    },
    placeholder: 'План'
  },
  {
    name: 'description',
    rules: [{ required: true, message: 'Опишите порядок выполнения' }],
    class: 'input-icon',
    type: 'text',
    style: { width: '100%' },
    placeholder: 'Порядок выполнения'
  }
];

export const APP_TEMPLATE_FORM_FIELDS = { staticTemplateFields, dynamicTemplateFields };
