import { Button } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const SiderCollButton = ({ collapsed, setCollapsed }) => {
  return (
    <>
      <Button
        style={{ minWidth: 32 }}
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={setCollapsed}
      />
    </>
  );
};

SiderCollButton.propTypes = {
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func
};
export default SiderCollButton;
