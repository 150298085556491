import React from 'react';
import CatalogIItemLabel from '../../components/training/catalogs-settings/catalog-item-label/CatalogIItemLabel';
import CatalogItemContent from '../../components/training/catalogs-settings/catalog-item-content/CatalogItemContent';
import { location as locationIcon, exercise as exerciseIcon, wProfiles } from '../../assets/icons';

const catalogsTabItems = [
  {
    key: '1',
    label: <CatalogIItemLabel img={exerciseIcon} text="Упражнения" />,
    children: <CatalogItemContent img={exerciseIcon} type="exercise" />
  },
  {
    key: '2',
    label: <CatalogIItemLabel img={wProfiles} text="Профили тренировки" />,
    children: <CatalogItemContent img={wProfiles} type="workout-profiles" />
  },
  {
    key: '3',
    label: <CatalogIItemLabel img={locationIcon} text="Локации" />,
    children: <CatalogItemContent img={locationIcon} type="location" />
  }
];

export const APP_CATALOGS_TABS = { catalogsTabItems };
