import React from 'react';
import { Card, Col } from 'antd';
import GeneralChart from '../../charts/GeneralChart';
import CardHeader from '../../card-header/CardHeader';
import PropTypes from 'prop-types';
import { standards } from '../../../../../config/segments/levels-standards';
import { observer } from 'mobx-react-lite';
import './visual-chart-block.css';

const VisualChartBlock = ({ data, setData }) => {
  return (
    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
      <Card>
        <CardHeader type="chart" title="Нормативы" data={standards} dataHandler={setData} />
        <div className="card-chart-content">
          <GeneralChart data={data} />
        </div>
      </Card>
    </Col>
  );
};

VisualChartBlock.propTypes = {
  data: PropTypes.any,
  setData: PropTypes.func
};

export default observer(VisualChartBlock);
