import { makeAutoObservable, runInAction } from 'mobx';
import { ClientRecordingService } from '../../services';

export default class ClientRecordingStore {
  _isLoading = false;
  _clientsRecording = [];
  _clientRecording = {};
  _clientRecordingCount = 0;

  constructor() {
    makeAutoObservable(this);
  }
  setIsLoading(bool) {
    this._isLoading = bool;
  }
  setClientsRecording(clientsRecording) {
    this._clientsRecording = clientsRecording;
  }
  setClientRecording(clientRecording) {
    this._clientRecording = clientRecording;
  }
  setClientRecordingCount(clientRecordingCount) {
    this._clientRecordingCount = clientRecordingCount;
  }
  get clientsRecording() {
    return this._clientsRecording;
  }
  get clientRecording() {
    return this._clientRecording;
  }
  get clientRecordingCount() {
    return this._clientRecordingCount;
  }
  get isLoading() {
    return this._isLoading;
  }

  async createClientRecording(phone, employeeId, date, time, location, comment) {
    try {
      const res = await ClientRecordingService.createClientRecording(
        phone,
        employeeId,
        date,
        time,
        location,
        comment
      );
      await this.getAllClientsRecording();
      this.setClientRecording(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async getAllClientsRecording() {
    this.setIsLoading(true);
    try {
      const res = await ClientRecordingService.getAllClientsRecording();
      this.setClientsRecording(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setIsLoading(false);
    }
  }
  async getClientRecordingById(id) {
    try {
      const res = await ClientRecordingService.getClientRecordingById(id);
      this.setClientRecording(res.data);
    } catch (error) {
      return error;
    }
  }
  async getAllRecByEmployeeIdOrClientId(employeeIdOrClientId) {
    this.setIsLoading(true);
    try {
      const res =
        await ClientRecordingService.getAllRecByEmployeeIdOrClientId(employeeIdOrClientId);
      runInAction(() => {
        this.setClientsRecording(res.data.rows);
        this.setClientRecordingCount(res.data.count);
        return res;
      });
    } catch (error) {
      return error;
    } finally {
      this.setIsLoading(false);
    }
  }

  async updateClientRecording(id, status) {
    try {
      const res = await ClientRecordingService.updateClientRecording(id, status);
      this.setClientRecording(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
}
