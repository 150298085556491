import React from 'react';
import AppSegmented from '../analitic-block/app-segmented/AppSegmented';
import PropTypes from 'prop-types';
import './card-header.css';

const CardHeader = ({ data, dataHandler, title, type }) => {
  return (
    <div className="card-header">
      <div className="card-header-title">
        <h5>{title}</h5>
      </div>
      <AppSegmented type={type} data={data} dataHandler={dataHandler} />
    </div>
  );
};

CardHeader.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any,
  dataHandler: PropTypes.func,
  type: PropTypes.string
};

export default CardHeader;
