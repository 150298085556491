import $api from '../../http';

export default class TicketService {
  static async createTicket(type, title, message, userId) {
    return $api.post('/tickets/', { type, title, message, userId });
  }
  static async getAllTickets() {
    return $api.get('/tickets/');
  }
  static async getTicketById(id) {
    return $api.get(`/tickets/${id}`);
  }
  static async updateTicket(id, status) {
    return $api.patch(`/tickets/${id}`, { status });
  }
}
