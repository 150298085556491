import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import './catalog-item-label.css';

const CatalogIItemLabel = ({ img, text }) => {
  return (
    <div className="catalog-label-container">
      <Avatar shape="square" src={img} size={40} />
      <div className="catalog-label-text">{text}</div>
    </div>
  );
};

CatalogIItemLabel.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string
};

export default CatalogIItemLabel;
