import { standards } from '../config/segments/levels-standards';

export const subjectFilter = (data, results) => {
  if (!data) {
    return standards;
  }
  const newData = data.map((item) => {
    switch (item.subject) {
      case 'DYN':
        item.C = results.DYN;
        break;
      case 'DNF':
        item.C = results.DNF;
        break;
      case 'FIM':
        item.C = results.FIM;
        break;
      case 'CNF':
        item.C = results.CNF;
        break;
      case 'CWT':
        item.C = results.CWT;
        break;
      default:
        return;
    }
    return item;
  });
  return newData;
};
