// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #ffffff;
}
.avatar-upload.ant-upload.ant-upload-select {
  width: 125px !important;
  height: 125px !important;
  padding: 10px;
}
.avatar-upload .ant-upload.ant-upload-select {
  border: 2px solid #ffffff !important;
  overflow: hidden !important;
}
.form-button {
  font-size: 11px;
  color: #ffffff !important;
  border-color: #ffffff !important;
}
.form-button:hover {
  font-size: 11px;
  color: #ffffff !important;
  background-color: #6b7a99 !important;
}
.avatar-popover-msg {
  padding: 0 10px;
}
.avatar-popover-list {
  font-size: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Layout/AvatarForm/avatar-form.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,+BAA+B;AACjC;AACA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,aAAa;AACf;AACA;EACE,oCAAoC;EACpC,2BAA2B;AAC7B;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,gCAAgC;AAClC;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,oCAAoC;AACtC;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".form-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  border-right: 1px solid #ffffff;\n}\n.avatar-upload.ant-upload.ant-upload-select {\n  width: 125px !important;\n  height: 125px !important;\n  padding: 10px;\n}\n.avatar-upload .ant-upload.ant-upload-select {\n  border: 2px solid #ffffff !important;\n  overflow: hidden !important;\n}\n.form-button {\n  font-size: 11px;\n  color: #ffffff !important;\n  border-color: #ffffff !important;\n}\n.form-button:hover {\n  font-size: 11px;\n  color: #ffffff !important;\n  background-color: #6b7a99 !important;\n}\n.avatar-popover-msg {\n  padding: 0 10px;\n}\n.avatar-popover-list {\n  font-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
