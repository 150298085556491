import { CLIENT_PATHS, PARTNER_PATHS, COMMON_PATHS } from '../../constants';
import {
  AboutPage,
  ClientAuthPage,
  PartnerAuthPage,
  CalendarPage,
  ClientsPage,
  ClientCompetitionPage,
  HomePage,
  ClientFaqPage,
  ClientNotificationPage,
  ClientPaymentPage,
  ClientSettingsPage,
  ClientTrainingPage,
  PartnerTrainingPage,
  PartnerCompetitionPage,
  PartnerNotificationPage,
  PartnerPaymentPage,
  PartnerFaqPage,
  UserAgreementPage,
  ContactInfoPage,
  RecordingPage,
  ResetPasswordPage
} from '../../pages';

const defaultRoutes = [
  { path: CLIENT_PATHS.SIGNUP_CLIENT_ROUTE, element: <ClientAuthPage /> },
  { path: CLIENT_PATHS.SIGNIN_CLIENT_ROUTE, element: <ClientAuthPage /> },
  { path: PARTNER_PATHS.SIGNIN_PARTNER_ROUTE, element: <PartnerAuthPage /> },
  { path: CLIENT_PATHS.RECORDING_ROUTE, element: <RecordingPage /> },
  { path: CLIENT_PATHS.RESET_ROUTE, element: <ResetPasswordPage /> }
];

const clientRoutes = [
  { path: COMMON_PATHS.HOME_CLIENT_ROUTE, element: <HomePage /> },
  { path: CLIENT_PATHS.CALENDAR_CLIENT_ROUTE, element: <CalendarPage /> },
  { path: CLIENT_PATHS.TRAINING_CLIENT_ROUTE, element: <ClientTrainingPage /> },
  { path: CLIENT_PATHS.COMPETITION_CLIENT_ROUTE, element: <ClientCompetitionPage /> },
  { path: CLIENT_PATHS.NOTIFICATION_CLIENT_ROUTE, element: <ClientNotificationPage /> },
  { path: CLIENT_PATHS.PAYMENT_CLIENT_ROUTE, element: <ClientPaymentPage /> },
  { path: CLIENT_PATHS.LEARNING_ROUTE, element: <ClientSettingsPage /> },
  { path: CLIENT_PATHS.FAQ_CLIENT_ROUTE, element: <ClientFaqPage /> },
  { path: COMMON_PATHS.ABOUT_ROUTE, element: <AboutPage /> },
  { path: COMMON_PATHS.USER_AGREEMENT_ROUTE, element: <UserAgreementPage /> },
  { path: COMMON_PATHS.CONTACT_INFO_ROUTE, element: <ContactInfoPage /> }
];

const partnerRoutes = [
  { path: COMMON_PATHS.HOME_CLIENT_ROUTE, element: <HomePage /> },
  { path: CLIENT_PATHS.CALENDAR_CLIENT_ROUTE, element: <CalendarPage /> },
  { path: PARTNER_PATHS.TRAINING_PARTNER_ROUTE, element: <PartnerTrainingPage /> },
  { path: PARTNER_PATHS.COMPETITION_PARTNER_ROUTE, element: <PartnerCompetitionPage /> },
  { path: PARTNER_PATHS.NEWSLETTER_ROUTE, element: <PartnerNotificationPage /> },
  { path: PARTNER_PATHS.PAYMENT_PARTNER_ROUTE, element: <PartnerPaymentPage /> },
  { path: PARTNER_PATHS.CLIENTS_ROUTE, element: <ClientsPage /> },
  { path: PARTNER_PATHS.FAQ_PARTNER_ROUTE, element: <PartnerFaqPage /> },
  { path: COMMON_PATHS.ABOUT_ROUTE, element: <AboutPage /> },
  { path: COMMON_PATHS.USER_AGREEMENT_ROUTE, element: <UserAgreementPage /> },
  { path: COMMON_PATHS.CONTACT_INFO_ROUTE, element: <ContactInfoPage /> }
];

export const APP_ROUTES = { defaultRoutes, clientRoutes, partnerRoutes };
