// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.print-workout-area {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
  grid-template-rows: 300px 300px 300px;
  grid-gap: 1em;
  height: 80vh;
  overflow-y: auto;
}
.print-workout-card {
  height: auto;
  padding: 5px !important;
  border: 1px solid #000000;
  color: #000000;
  font-size: 12px;
}
.print-workout-content {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.print-workout-avatar {
  margin-right: 5px;
}
.print-workout-divider {
  margin: 5px;
  background-color: #000000;
}
.print-workout-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.print-exercise-row {
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/partner-training/confirmed-workout/PrintWorkoutComponent/print-workout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6DAA6D;EAC7D,qCAAqC;EACrC,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;EACd,eAAe;AACjB;AACA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".print-workout-area {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(400px, 400px));\n  grid-template-rows: 300px 300px 300px;\n  grid-gap: 1em;\n  height: 80vh;\n  overflow-y: auto;\n}\n.print-workout-card {\n  height: auto;\n  padding: 5px !important;\n  border: 1px solid #000000;\n  color: #000000;\n  font-size: 12px;\n}\n.print-workout-content {\n  height: 30px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n.print-workout-avatar {\n  margin-right: 5px;\n}\n.print-workout-divider {\n  margin: 5px;\n  background-color: #000000;\n}\n.print-workout-footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n}\n.print-exercise-row {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
