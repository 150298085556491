// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outer-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  justify-content: center;
  align-items: center;
}
.inner-wrapper {
  height: 135px;
  width: 135px;
  padding: 3px;
  border: 2px solid #769ccd;
  border-radius: 70px;
}
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125px;
  width: 125px;
  padding: 1px;
  border-radius: 70px;
  border: 3px solid #acecdf;
}
.avatar-image {
  width: 115px;
  height: 115px;
  border-radius: 70px;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Layout/UserInfo/UserAvatar/user-avatar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".outer-wrapper {\n  display: flex;\n  flex-direction: column;\n  margin-top: 5vh;\n  justify-content: center;\n  align-items: center;\n}\n.inner-wrapper {\n  height: 135px;\n  width: 135px;\n  padding: 3px;\n  border: 2px solid #769ccd;\n  border-radius: 70px;\n}\n.avatar-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 125px;\n  width: 125px;\n  padding: 1px;\n  border-radius: 70px;\n  border: 3px solid #acecdf;\n}\n.avatar-image {\n  width: 115px;\n  height: 115px;\n  border-radius: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
