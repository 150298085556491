// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-filter-col {
  display: flex;
  justify-content: flex-end;
  padding: 10px !important;
}
.template-filter-card {
  width: 100%;
  max-width: 540px;
}
.template-filter-selsect {
  width: 45%;
  margin: 0 5px;
}
.template-filter-selsect .ant-select-selector,
.ant-select-single {
  font-size: 11px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/template-settings/template-list/template-list-filter/template-list-filter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,wBAAwB;AAC1B;AACA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,aAAa;AACf;AACA;;EAEE,0BAA0B;AAC5B","sourcesContent":[".template-filter-col {\n  display: flex;\n  justify-content: flex-end;\n  padding: 10px !important;\n}\n.template-filter-card {\n  width: 100%;\n  max-width: 540px;\n}\n.template-filter-selsect {\n  width: 45%;\n  margin: 0 5px;\n}\n.template-filter-selsect .ant-select-selector,\n.ant-select-single {\n  font-size: 11px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
