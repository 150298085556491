import { APP_ROUTES } from './routes/app-routes.config';
import { APP_CONTEXT } from './context/app-context.config';
import { APP_AUTH_FORM_FIELDS } from './forms/auth-forms';
import { APP_MENU } from './menu/app-menu';
import { APP_CALENDAR } from './calendar';
import { APP_TABLE_COLUMNS } from './tables/training-card-tables';
import { APP_CLIENT_TRAINING_TABS } from './tabs/client-training-page-tabs';
import { APP_PARTNER_TRAINING_TABS } from './tabs/partner-training-page-tabs';
import { APP_TIMELINES_ITEMS } from './timelines/training-timline-items';
import { APP_EVENT_FORM_FIELDS } from './forms/event-form';
import { APP_SUPPORT_FORM_FIELDS } from './forms/support-form';
import { APP_PROFILE_FORM_FIELDS } from './forms/profile-form';
import { APP_TEMPLATE_FORM_FIELDS } from './forms/template-form';
import { APP_CATALOG_FORM_FIELDS } from './forms/catalog-forms';
import { APP_EDIT_PROFILE_FORM_FIELDS } from './forms/edit-rofile-form';
import { REC_FORM_CONFIG } from './forms/rec-form';
import { WORKOUT_SELECT } from './select/workout-select';
import { APP_CATALOGS_TABS } from './tabs/catalog-settings-tabs';
import { APP_TEMPLATE_TABS } from './tabs/template-tabs';
import { APP_CLIENT_FAQ_TABS } from './tabs/client-faq-page-tabs';
import { APP_PARTNER_FAQ_TABS } from './tabs/partner-faq-page-tabs';

export {
  APP_ROUTES,
  APP_CONTEXT,
  APP_AUTH_FORM_FIELDS,
  APP_MENU,
  APP_CALENDAR,
  APP_TABLE_COLUMNS,
  APP_CLIENT_TRAINING_TABS,
  APP_PARTNER_TRAINING_TABS,
  APP_TIMELINES_ITEMS,
  APP_EVENT_FORM_FIELDS,
  APP_SUPPORT_FORM_FIELDS,
  APP_PROFILE_FORM_FIELDS,
  APP_TEMPLATE_FORM_FIELDS,
  APP_CATALOG_FORM_FIELDS,
  REC_FORM_CONFIG,
  WORKOUT_SELECT,
  APP_CATALOGS_TABS,
  APP_EDIT_PROFILE_FORM_FIELDS,
  APP_TEMPLATE_TABS,
  APP_CLIENT_FAQ_TABS,
  APP_PARTNER_FAQ_TABS
};
