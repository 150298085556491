// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/Comfortaa-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Comfortaa&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgb(243, 243, 243) rgba(90, 142, 189, 0.45);
  font-family: 'Comfortaa', sans-serif !important;
}
:root {
  --blue: #1e90ff;
  --white: #ffffff;
}
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  background: rgba(22, 119, 255, 0.71);
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #3360a8;
}

@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: normal;
  src:
    local('Comfortaa'),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Comfortaa', sans-serif !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,qBAAqB;EACrB,4DAA4D;EAC5D,+CAA+C;AACjD;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,oCAAoC;AACtC;AACA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,mBAAmB;EACnB;;8DAE4D;AAC9D;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,+CAA+C;AACjD","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  scrollbar-width: thin;\n  scrollbar-color: rgb(243, 243, 243) rgba(90, 142, 189, 0.45);\n  font-family: 'Comfortaa', sans-serif !important;\n}\n:root {\n  --blue: #1e90ff;\n  --white: #ffffff;\n}\n*::-webkit-scrollbar {\n  width: 4px;\n  height: 4px;\n}\n*::-webkit-scrollbar-track {\n  background: rgba(22, 119, 255, 0.71);\n}\n*::-webkit-scrollbar-thumb {\n  border-radius: 10px;\n  background-color: #3360a8;\n}\n\n@font-face {\n  font-family: 'Comfortaa';\n  font-style: normal;\n  font-weight: normal;\n  src:\n    local('Comfortaa'),\n    url(assets/fonts/Comfortaa-Regular.ttf) format('truetype');\n}\n\nbody {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  font-family: 'Comfortaa', sans-serif !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
