import { makeAutoObservable } from 'mobx';
import { LocationService } from '../../services';

export default class LocationStore {
  isLoading = false;
  _locations = [];
  _location = {};

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setLocations(locations) {
    this._locations = locations;
  }
  setLocation(location) {
    this._location = location;
  }
  get locations() {
    return this._locations;
  }
  get location() {
    return this._location;
  }

  async createLocation(name, shortName, address) {
    try {
      const res = await LocationService.createLocation(name, shortName, address);
      await this.getAllLocations();
      this.setLocation(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async getAllLocations() {
    this.setLoading(true);
    try {
      const res = await LocationService.getAllLocations();
      this.setLocations(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getLocationById(id) {
    try {
      const res = await LocationService.getLocationById(id);
      this.setLocation(res.data);
    } catch (error) {
      return error;
    }
  }
  async updateLocation(id, name, shortName, address) {
    try {
      const res = await LocationService.updateLocation(id, name, shortName, address);
      this.setLocation(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async deleteLocation(id) {
    try {
      const res = await LocationService.deleteLocationById(id);
      this.setLocation({});
      return res;
    } catch (error) {
      console.log(error);
    }
  }
}
