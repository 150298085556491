const trainerType = [{ value: '21547983-5e6d-492e-8130-416e64384f77', label: 'Антон Адамович' }];

const selectType = [
  { value: '6:45', label: '6:45' },
  { value: '18:15', label: '18:15' },
  { value: '19:15', label: '19:15' }
];

const selectLocation = [
  {
    value: 'СОК Фристайл',
    label: 'СОК Фристайл'
  },
  {
    value: 'Дом гребли',
    label: 'Дом гребли'
  }
];

const recRules = {
  phoneRules: [
    {
      pattern: new RegExp(/^[0-9]*$/),
      message: 'Некорректный номер телефона!'
    },
    {
      required: true,
      message: 'Пожалуйста, введите ваш номер телефона!'
    },
    {
      max: 12,
      message: 'Максимальная длина - 12 символов'
    }
  ],
  trainerRules: [
    {
      required: true,
      message: 'Пожалуйста, выберите тренера!'
    }
  ],
  dateRules: [
    {
      required: true,
      message: 'Пожалуйста, выберите дату!'
    }
  ],
  timeRules: [
    {
      required: true,
      message: 'Пожалуйста, выберите время!'
    }
  ],
  locationRules: [
    {
      required: true,
      message: 'Пожалуйста, выберите локацию!'
    }
  ]
};

export const REC_FORM_CONFIG = { trainerType, selectLocation, selectType, recRules };
