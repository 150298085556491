import React, { useContext, useEffect } from 'react';
import { Card } from 'antd';
import CreateTemplateForm from './CreateTemplateForm';
import PageLabel from '../../../common/PageLabel/PageLabel';
import { Context } from '../../../..';
import { add } from '../../../../assets/icons';
import './create-template.css';
import { observer } from 'mobx-react-lite';

const CreateTemplate = () => {
  const { template, workoutProfile } = useContext(Context);

  useEffect(() => {
    const getTemplates = async () => {
      await template.getAllTemplates();
      await workoutProfile.getAllWorkoutProfiles();
    };
    getTemplates().catch((e) => console.log(e));
  }, [template.temlpate, template.temlpates, workoutProfile.workoutProfiles]);

  return (
    <Card className="create-template-card" title={<PageLabel img={add} text="Создание шаблона" />}>
      <CreateTemplateForm />
    </Card>
  );
};

export default observer(CreateTemplate);
