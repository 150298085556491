import React, { useContext } from 'react';
import { Button, Form, Input, Select } from 'antd';
import DynamicField from './DynamicField';
import { APP_TEMPLATE_FORM_FIELDS } from '../../../../config';
import { Context } from '../../../../index';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { openNotification, selectWorkoutProfile } from '../../../../utils';

const CreateTemplateForm = () => {
  const { template, templateElement, workoutProfile } = useContext(Context);
  const [form] = Form.useForm();
  const { staticTemplateFields } = APP_TEMPLATE_FORM_FIELDS;

  async function handleFinish(values) {
    if (!values.fields) {
      openNotification({ description: 'Добавьте упражения' });
      return;
    }
    const res = await template.createTemplate(
      values.title,
      values.description,
      +values.type,
      +values.level
    );

    if (res.status === 201 && res.data?.id && values.fields) {
      for (let i = 0; i < values.fields.length; i++) {
        await templateElement.createTemplateElement(
          res.data?.id,
          values.fields[i].exerciseId,
          +values.fields[i].plan,
          values.fields[i].description
        );
      }
      form.resetFields();
    }
    openNotification(res);
  }

  return (
    <Form className="create-template-form" form={form} onFinish={handleFinish}>
      <div className="form-fields-wrapper">
        <Form.Item name="type" className="form-static-field">
          <Select
            size="small"
            dropdownStyle={{ fontSize: 11 }}
            placeholder="Профиль тренировки"
            allowClear
            options={selectWorkoutProfile(workoutProfile.workoutProfiles)}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            showSearch
          />
        </Form.Item>
        {staticTemplateFields.map((field) => (
          <Form.Item
            hasFeedback
            validateDebounce={1000}
            key={field.name}
            name={field.name}
            rules={field.rules}
            style={field.style}>
            <Input min={field.min} type={field.type} size="small" placeholder={field.placeholder} />
          </Form.Item>
        ))}
      </div>
      <div className="dynamic-fields-wrapper">
        <DynamicField />
      </div>
      <Form.Item className="create-template-form-btn">
        <Button
          className="form-create-btn"
          icon={<AppstoreAddOutlined />}
          type="primary"
          htmlType="submit">
          Создать шаблон
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateTemplateForm;
