import { makeAutoObservable } from 'mobx';
import { EmployeeService } from '../../../services';

export default class EmployeeStore {
  isLoading = false;
  _employees = [];
  _employee = {};

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setEmployees(employees) {
    this._employees = employees;
  }
  setEmployee(employee) {
    this._employee = employee;
  }
  get employees() {
    return this._employees;
  }
  get employee() {
    return this._employee;
  }

  async createEmployee(name, surname, position, level, userId) {
    try {
      const res = await EmployeeService.createEvent(name, surname, position, level, userId);
      await this.getAllEmployees();
      this.setEmployee(res.data);
    } catch (error) {
      return error;
    }
  }
  async getAllEmployees() {
    this.setLoading(true);
    try {
      const res = await EmployeeService.getAllEmployees();
      this.setEmployees(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getEmployeeById(id) {
    try {
      const res = await EmployeeService.getEmployeeById(id);
      this.setEmployee(res.data);
    } catch (error) {
      return error;
    }
  }
  async getEmployeeByUserId(userId) {
    try {
      const res = await EmployeeService.getEmployeeByUserId(userId);
      console.log('RES: ', res);
      this.setEmployee(res.data);
    } catch (error) {
      return error;
    }
  }

  async updateEmployeeAvatar(id, formData) {
    try {
      const res = await EmployeeService.updateEmployeeAvatar(id, formData);
      return await res.json();
    } catch (error) {
      return error;
    }
  }

  async updateEmployee(id, name, middleName, surname, position, level) {
    try {
      const res = await EmployeeService.updateEmployee(
        id,
        name,
        middleName,
        surname,
        position,
        level
      );
      this.setEmployee(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
}
