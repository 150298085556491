// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templates-content-row {
  height: 50vh;
  width: 100%;
  margin: 10;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
}
.templates-pagination-row {
  display: flex;
  justify-content: end;
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/template-settings/template-list/template-list.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,UAAU;EACV,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,oBAAoB;EACpB,aAAa;AACf","sourcesContent":[".templates-content-row {\n  height: 50vh;\n  width: 100%;\n  margin: 10;\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n  overflow-y: auto;\n}\n.templates-pagination-row {\n  display: flex;\n  justify-content: end;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
