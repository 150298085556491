import React from 'react';
import { Button, Form, Select, Space } from 'antd';
import { WORKOUT_SELECT } from '../../../../../config';
import { openNotification, selectUserData, selectWorkoutProfile } from '../../../../../utils';
import { useContext, useState } from 'react';
import { Context } from '../../../../../index';
import { PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { RECORDING_STATUS, WORKOUT_TYPE } from '../../../../../constants';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

const WorkoutListForm = ({ setUserAvatar, userDates, showModal, setLoading }) => {
  const { template, templateElement, workout, workoutElement, workoutProfile, recording, user } =
    useContext(Context);

  const [dateSelector, setDateSelector] = useState(null);
  const [timeSelector, setTimeSelector] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [userData, setUserData] = useState([]);
  const [form] = Form.useForm();
  const defaultPlan = 0;

  const recComplete = recording.clientsRecording.filter((recording) => {
    return (
      recording.clientId === workout.workoutClientInfo.id &&
      recording.date === workout.workoutClientInfo.date &&
      recording.time === workout.workoutClientInfo.time
    );
  });

  const onFinish = async (values) => {
    if (templateElement.templateElements?.length < 1) {
      openNotification({ description: 'Добавьте упражения' });
      return;
    }
    setDisabled(true);
    setLoading(true);
    const res = await workout.createWorkout(
      user.user.id,
      values.clientId,
      recComplete[0].location,
      values.date,
      values.time,
      WORKOUT_TYPE.GROUP
    );
    if (res.status !== 201) {
      openNotification(res);
      return;
    }

    for (let i = 0; i < templateElement.templateElements.length; i++) {
      await workoutElement.createWorkoutElement(
        res.data.id,
        templateElement.templateElements[i].exerciseId,
        templateElement.templateElements[i].plan,
        defaultPlan,
        templateElement.templateElements[i].description
      );
    }
    templateElement.setTemplateElements([]);
    template.selectTemplateType(0);
    openNotification(res);
    setUserAvatar(false);
    setTimeSelector(null);
    form.resetFields();
    await recording.updateClientRecording(recComplete[0].id, RECORDING_STATUS.CLOSED);
    await recording.getAllRecByEmployeeIdOrClientId(user.user.id);
    setLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log('Failed:', errorInfo);
  };
  const dateHandler = (e) => {
    setDateSelector(e);
  };
  const filterUsers = (e) => {
    return recording.clientsRecording
      .filter((client) => client.date === dateSelector && client.time === e)
      .map((item) => item.clientId);
  };
  const timeHandler = (e) => {
    setTimeSelector(e);
    const clients = filterUsers(e);
    const selectUsers = user.users.filter((item) => {
      if (clients.includes(item.id) === true) {
        return item;
      }
    });
    setUserData(selectUsers);
  };

  const changeAvatar = async (e) => {
    const currentUser = userData.filter((item) => item.id === e);
    setUserAvatar(currentUser[0].client.img);
    await workout.setWorkoutClientInfo({
      id: e,
      date: dateSelector,
      time: timeSelector,
      name: currentUser[0].client.name,
      surname: currentUser[0].client.surname,
      img: currentUser[0].client.img
    });
    setDisabled(false);
  };
  const templateHandler = (e) => template.selectTemplateType(e);

  return (
    <Form
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <Form.Item
        rules={[{ required: true, message: 'Выберите дату' }]}
        name="date"
        className="header-selector-wrapper">
        <Select
          size="small"
          onSelect={(e) => dateHandler(e)}
          className="header-selector-item"
          placeholder="Дата тренировки"
          allowClear
          value={dateSelector}
          options={userDates}
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          showSearch
        />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: 'Выберите время' }]}
        name="time"
        className="header-selector-wrapper">
        <Select
          size="small"
          disabled={!dateSelector}
          onSelect={(e) => timeHandler(e)}
          className="header-selector-item"
          placeholder="Время тренировки"
          allowClear
          value={timeSelector}
          options={WORKOUT_SELECT.workoutTimes}
        />
      </Form.Item>
      <div
        className="header-selectors"
        style={{ width: '100%', display: timeSelector ? 'flex' : 'none' }}>
        <Form.Item
          rules={[{ required: true, message: 'Выберите спортсмена' }]}
          name="clientId"
          className="header-selector-wrapper">
          <Select
            size="small"
            onSelect={(e) => changeAvatar(e)}
            className="header-selector-item"
            placeholder="Выбрать спортсмена"
            allowClear
            options={selectUserData(userData)}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            showSearch
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Выберите шаблон' }]}
          name="template"
          className="header-selector-wrapper">
          <Select
            size="small"
            className="header-selector-item"
            onSelect={(e) => templateHandler(e)}
            dropdownStyle={{ fontSize: 12 }}
            placeholder="Выбрать профиль тренировки"
            allowClear
            options={selectWorkoutProfile(workoutProfile.workoutProfiles)}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            showSearch
          />
        </Form.Item>
      </div>
      <Form.Item
        style={{
          width: '100%'
        }}>
        <Space className="exercise-list-footer">
          <Button
            size="small"
            style={{ fontSize: 11 }}
            className="exercise-list-add-button"
            onClick={showModal}
            icon={<PlusOutlined />}>
            Добавить
          </Button>
          <Button
            size="small"
            style={{ fontSize: 11 }}
            disabled={disabled}
            htmlType="submit"
            className="exercise-list-accept-button"
            icon={<UserAddOutlined />}>
            Hазначить
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

WorkoutListForm.propTypes = {
  userDates: PropTypes.array,
  cleanList: PropTypes.bool,
  showModal: PropTypes.func,
  setUserAvatar: PropTypes.func,
  setLoading: PropTypes.func
};

export default observer(WorkoutListForm);
