// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  display: flex;
  justify-content: flex-end;
}
.ok-btn {
  margin: 2px;
  font-size: 11px;
  background-color: #598dbd;
  color: #ffffff;
}
.ok-btn:hover {
  background-color: #9fb9dc;
  color: #ffffff;
  border-color: #6b7a99;
}
.cancel-btn {
  margin: 2px;
  font-size: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/client-training/training-card/training-modal/training-modal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,eAAe;EACf,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".btn-container {\n  display: flex;\n  justify-content: flex-end;\n}\n.ok-btn {\n  margin: 2px;\n  font-size: 11px;\n  background-color: #598dbd;\n  color: #ffffff;\n}\n.ok-btn:hover {\n  background-color: #9fb9dc;\n  color: #ffffff;\n  border-color: #6b7a99;\n}\n.cancel-btn {\n  margin: 2px;\n  font-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
