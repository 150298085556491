export const selectExercises = (data) => {
  let exerciseList = [];
  if (!data) {
    return exerciseList;
  }
  data?.map((exercise) => {
    exerciseList.push({
      value: exercise.id,
      label: exercise.name
    });
  });
  return exerciseList;
};
