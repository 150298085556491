import { Button, DatePicker, Form, Input, message, Modal, Select, Upload } from 'antd';
import { APP_EVENT_FORM_FIELDS } from '../../../../config';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Context } from '../../../../index';
import { useContext } from 'react';
import { checkDate, openNotification } from '../../../../utils';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

dayjs.locale('ru');

const CreateEventModal = (props) => {
  const { calendar, user } = useContext(Context);
  const [form] = Form.useForm();
  const { createEventFields, eventSelectType } = APP_EVENT_FORM_FIELDS;
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Необходимо использовать файлы JPG/PNG формат!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Максимальный размер файла 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const saveImg = async (fileList) => {
    if (!fileList) {
      return;
    }
    const formData = new FormData();
    formData.append('event', fileList[0].originFileObj);
    return await calendar.saveImg(formData);
  };
  const onFinish = async (values) => {
    const imgName = await saveImg(values.event);
    const res = await calendar.createEvent(
      values.title,
      values.description,
      values.type,
      values.startDate,
      values.endDate,
      imgName ? imgName.filename : 'no-image.jpg',
      user.user.employee.id
    );
    openNotification(res);
    form.resetFields();
    props.handleCancel();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e.fileList[0];
    }
    return e && e.fileList;
  };

  return (
    <Modal
      title="Создать событие"
      open={props.open}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer=""
    >
      <Form
        form={form}
        encType="multipart/form-data"
        name="normal_login"
        className="sign-in-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item name="type">
          <Select placeholder="Выберите тип события">
            {eventSelectType &&
              eventSelectType.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        {createEventFields &&
          createEventFields.map((field) => (
            <Form.Item
              hasFeedback
              validateDebounce={1000}
              key={field.name}
              name={field.name}
              rules={[field.rules]}
            >
              {field.textarea ? (
                <Input.TextArea
                  rows={5}
                  allowClear
                  prefix={field.icon}
                  type={field.type}
                  placeholder={field.placeholder}
                />
              ) : (
                <Input
                  allowClear
                  prefix={field.icon}
                  type={field.type}
                  placeholder={field.placeholder}
                />
              )}
            </Form.Item>
          ))}
        <Form.Item form={form} name="event" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload listType="picture" customRequest={dummyRequest} beforeUpload={beforeUpload}>
            <Button icon={<UploadOutlined />}>Загрузить изображение (520x400)</Button>
          </Upload>
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item name="startDate">
            <DatePicker
              disabledDate={(current) => checkDate(current)}
              showTime
              locale={locale}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item name="endDate">
            <DatePicker
              disabledDate={(current) => checkDate(current)}
              showTime
              locale={locale}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </div>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          htmlType="submit"
          style={{ backgroundColor: '#769ccd' }}
        >
          Создать событие
        </Button>
      </Form>
    </Modal>
  );
};

CreateEventModal.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  start: PropTypes.any
};

export default CreateEventModal;
