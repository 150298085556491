// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 1600px) {
  .contentTitle {
    font-size: 110px;
  }
}
@media only screen and (max-width: 1200px) {
  .contentImage {
    width: 600px;
  }
}
@media only screen and (max-width: 992px) {
  .contentImage {
    width: 480px;
  }
}
@media only screen and (max-width: 768px) {
  .rbc-toolbar .rbc-btn-group {
    margin: 5px !important;
  }
  .segmented-menu {
    font-size: 10px;
  }
  .standards-item-description {
    font-size: 8px;
    word-wrap: break-word;
    word-break: break-all;
  }
}
@media only screen and (max-width: 576px) {
  .rbc-calendar .rbc-toolbar.rbc-btn-group {
    margin: 5px !important;
  }
  .segmented-menu {
    font-size: 9px;
  }
  .standards-item-description {
    font-size: 8px;
    word-wrap: break-word;
    word-break: break-all;
  }
}
@media only screen and (max-width: 480px) {
  .copyright {
    font-size: 12px;
  }
  .form-button-container {
    flex-direction: column;
  }
  .rbc-calendar .rbc-toolbar.rbc-btn-group {
    margin: 5px !important;
  }
  .sig-up-form-button,
  .sign-in-form-button {
    width: 100%;
    margin-top: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,eAAe;EACjB;EACA;IACE,cAAc;IACd,qBAAqB;IACrB,qBAAqB;EACvB;AACF;AACA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,cAAc;EAChB;EACA;IACE,cAAc;IACd,qBAAqB;IACrB,qBAAqB;EACvB;AACF;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;EACA;;IAEE,WAAW;IACX,gBAAgB;EAClB;AACF","sourcesContent":["@media only screen and (max-width: 1600px) {\n  .contentTitle {\n    font-size: 110px;\n  }\n}\n@media only screen and (max-width: 1200px) {\n  .contentImage {\n    width: 600px;\n  }\n}\n@media only screen and (max-width: 992px) {\n  .contentImage {\n    width: 480px;\n  }\n}\n@media only screen and (max-width: 768px) {\n  .rbc-toolbar .rbc-btn-group {\n    margin: 5px !important;\n  }\n  .segmented-menu {\n    font-size: 10px;\n  }\n  .standards-item-description {\n    font-size: 8px;\n    word-wrap: break-word;\n    word-break: break-all;\n  }\n}\n@media only screen and (max-width: 576px) {\n  .rbc-calendar .rbc-toolbar.rbc-btn-group {\n    margin: 5px !important;\n  }\n  .segmented-menu {\n    font-size: 9px;\n  }\n  .standards-item-description {\n    font-size: 8px;\n    word-wrap: break-word;\n    word-break: break-all;\n  }\n}\n@media only screen and (max-width: 480px) {\n  .copyright {\n    font-size: 12px;\n  }\n  .form-button-container {\n    flex-direction: column;\n  }\n  .rbc-calendar .rbc-toolbar.rbc-btn-group {\n    margin: 5px !important;\n  }\n  .sig-up-form-button,\n  .sign-in-form-button {\n    width: 100%;\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
