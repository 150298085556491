import React, { useState, useContext } from 'react';
import { Button, Modal, Typography, Checkbox } from 'antd';
import PropsTypes from 'prop-types';
import PageLabel from '../PageLabel/PageLabel';
import { safety } from '../../../assets/icons';
import { Context } from '../../..';
import { EditOutlined } from '@ant-design/icons';
import './agreement-modal.css';

const { Paragraph, Text, Link } = Typography;

const AgreementModal = ({ modalHandler, open }) => {
  const { user } = useContext(Context);
  const [disabledButton, setDisabledButton] = useState(true);

  const chackedHandler = () => {
    setDisabledButton(!disabledButton);
  };
  const agreementHandler = async () => {
    if (!user.user.id) {
      return;
    }
    await user.updateUserAgreement(user.user.id, Date.now());
    modalHandler(false);
  };

  return (
    <Modal
      width={840}
      closeIcon={false}
      footer={false}
      title={<PageLabel text="Политика конфиденциальности" img={safety} />}
      open={open}
    >
      <Paragraph className="agreement-paragraph">
        <Text className="agreement-text">
          <p>
            <b>Политика конфиденциальности</b> (положение о порядке обработки и хранения
            персональных данных Пользователей) интернет-сайтов, сервисов домена{' '}
            <b>sportenvironment.com</b> (Далее – <b>Платформа</b>).
          </p>
          <p>
            Настоящая <b>Политика конфиденциальности</b> определяет деятельность <b>Платформы</b> и
            направлена на регулирование вопроса обработки персональных данных и конфиденциальности
            персональных данных (далее - <b>Политика</b>), действует в отношении всей информации
            размещённой̆ на домене в сети Интернет по адресу: <b>sportenvironment.com</b>, которую
            могут получить владелец доменного имени <b>sportenvironment.com</b> и/или владелец
            сайта, расположенного на домене <b>sportenvironment.com</b> «Условия использования», в
            период использования <b>Пользователем Платформы</b>.
          </p>{' '}
          <p>
            <b>Политика</b> является общедоступной и размещается на официальном интернет-портале{' '}
            <b>Платформы</b>.
          </p>
          <p>
            Регистрация <b>Пользователя</b> на <b>Платформе</b> и/или использование сервисов{' '}
            <b>Платформы</b>, означает безоговорочное согласие <b>Пользователя</b> с настоящей{' '}
            <b>Политикой</b> и указанными в ней условиями обработки его персональных данных.{' '}
            <b>
              В случае несогласия с этими условиями Пользователь должен воздержаться от регистрации
              на сайтах домена и/или воздержаться от использования его сервисов и покинуть
              домен/сайт.
            </b>
          </p>
          <p>
            Получение согласия <b>Пользователя</b> на обработку <b>Персональной информации</b> может
            быть выражено <b>Пользователем</b> в форме совершения конклюдентных действий, например:
          </p>{' '}
          <ul>
            <li>проставления отметок, заполнения соответствующих полей в формах, бланках;</li>
            <li>поддержания электронной переписки, в которой говорится об обработке;</li>
            <li>
              иных действий, совершаемых <b>Пользователем</b>, по которым можно судить о его
              волеизъявлении.{' '}
            </li>
          </ul>
          <p>
            Полный текст документа доступен по ссылке{' '}
            <Link
              href="https://drive.google.com/file/d/1k5KnzSpSUVAThWNWevRcIZuHIcJrrSbW/view"
              target="_blank"
              rel="noreferrer"
              className="agreement-link"
            >
              Политика конфиденциальности
            </Link>
          </p>
        </Text>
      </Paragraph>
      <div className="agreement-btn-container">
        <Checkbox onChange={chackedHandler} className="agreement-checkbox">
          Согласен на обработку персональных данных
        </Checkbox>
        <Button
          icon={<EditOutlined />}
          onClick={agreementHandler}
          disabled={disabledButton}
          type="primary"
          className="agreement-btn"
        >
          Подтвердить
        </Button>
      </div>
    </Modal>
  );
};

AgreementModal.propTypes = {
  modalHandler: PropsTypes.func,
  open: PropsTypes.bool
};

export default AgreementModal;
