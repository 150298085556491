import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import './copyright.css';

const AppCopyright = ({ color }) => {
  return (
    <Row className="copyright-row" justify="center" align="bottom">
      <Col className="copyright-col" span={24}>
        <span className="copyright-span" style={{ color: color ?? '#fff' }}>
          ©{new Date().getFullYear()} by Sports Environment. All Rights Reserved.
        </span>
      </Col>
    </Row>
  );
};

AppCopyright.propTypes = {
  color: PropTypes.string
};

export default AppCopyright;
