import React, { useContext, useEffect, useState } from 'react';
import { getMaxResults, subjectFilter, getStaResult } from '../../../../utils';
import { Context } from '../../../..';
import { standards } from '../../../../config/segments/levels-standards';
import VisualTimeBlock from './visual-time-block/VisualTimeBlock';
import VisualChartBlock from './visual-chart-block/VisualChartBlock';
import { observer } from 'mobx-react-lite';

const AnaliticBlock = () => {
  const { workout } = useContext(Context);
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);

  const maxValues = getMaxResults(workout.workouts);
  const defaultData = maxValues ?? { DYN: 0, DNF: 0, FIM: 0, CNF: 0, CWT: 0 };
  const staData = getStaResult(workout.workouts);

  useEffect(() => {}, []);
  return (
    <>
      <VisualTimeBlock result={staData ?? '0:00'} />
      <VisualChartBlock
        data={data.length > 1 ? data : subjectFilter(standards.one, defaultData)}
        setData={setData}
      />
    </>
  );
};

export default observer(AnaliticBlock);
