import { useId } from 'react';

export const getClientWorkoutData = (data, status) => {
  let rowId = 1;
  const actualWorkoutInfo = data.filter((item) => item.status === status);
  const actualWorkout = actualWorkoutInfo[actualWorkoutInfo.length - 1];
  const exercisesData = actualWorkout?.exercises
    .slice()
    .sort((a, b) => a.id - b.id)
    .map((item) => {
      item['key'] = rowId;
      item['title'] = item.exercise.name;
      rowId += 1;
      return item;
    });
  return { actualWorkout, exercisesData };
};

export const getTrainerData = (users, employeeId) => {
  return users.filter((item) => item.id === employeeId)[0];
};

export const getExercises = (data) => {
  if (!data.exercises) {
    return;
  }
  return data?.exercises.map((item) => {
    item['key'] = useId();
    item['title'] = item.exercise.name;
    return item;
  });
};
