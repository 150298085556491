import React from 'react';
import CatalogIItemLabel from '../../components/training/catalogs-settings/catalog-item-label/CatalogIItemLabel';
import CreateTemplate from '../../components/training/template-settings/create-template/CreateTemplate';
import { template, add } from '../../assets/icons';
import TemplateListWrapper from '../../components/training/template-settings/template-list/template-list-wrapper/TemplateListWrapper';

const templateTabItems = [
  {
    key: '1',
    label: <CatalogIItemLabel img={add} text="Создать шаблон" />,
    children: <CreateTemplate />
  },
  {
    key: '2',
    label: <CatalogIItemLabel img={template} text="Список шаблонов" />,
    children: <TemplateListWrapper />
  }
];

export const APP_TEMPLATE_TABS = { templateTabItems };
