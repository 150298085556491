import { Row } from 'antd';
import { APP_TABLE_COLUMNS } from '../tables/training-card-tables';
import InformerBlock from '../../components/training/partner-training/InformerBlock/InformerBlock';
import ScheduledWorkouts from '../../components/training/partner-training/scheduled-workouts/ScheduledWorkouts';
import CreateWorkoutComponent from '../../components/training/partner-training/create-workout/CreateWorkoutComponent/CreateWorkoutComponent';
import CreatePersonalWorkout from '../../components/training/partner-training/create-personal-workout/CreatePersonalWorkout';
import ConfWorkout from '../../components/training/partner-training/confirmed-workout/ConfWorkout';
import CatalogSettings from '../../components/training/catalogs-settings/CatalogSettings';
import { approve, schedule, workouts } from '../../assets/icons';
import React from 'react';
import TemplateSettings from '../../components/training/template-settings/TemplateSettings';

const tabItems = [
  {
    key: '1',
    label: 'Аналитика',
    children: (
      <Row gutter={10}>
        <InformerBlock />
        <ScheduledWorkouts
          title="Запись на тренировку"
          columns={APP_TABLE_COLUMNS.partnerRecordingTC}
          size={14}
          img={schedule}
        />
        <ConfWorkout
          title="Подтверждение результатов"
          columns={APP_TABLE_COLUMNS.partnerReviewWorkoutsTC}
          size={10}
          confirmed
          img={approve}
        />
      </Row>
    )
  },
  {
    key: '2',
    label: 'Групповые тренировки',
    children: (
      <Row gutter={10}>
        <CreateWorkoutComponent />
      </Row>
    )
  },
  {
    key: '3',
    label: 'Индивидуальные тренировки',
    children: (
      <Row gutter={10}>
        <CreatePersonalWorkout />
      </Row>
    )
  },
  {
    key: '4',
    label: 'Созданные тренировки',
    children: (
      <Row gutter={10}>
        <ConfWorkout title="Созданные тренировки" size={24} img={workouts} />
      </Row>
    )
  },
  {
    key: '5',
    label: 'Шаблоны тренировки',
    children: <TemplateSettings />
  },
  {
    key: '6',
    label: 'Справочники',
    children: <CatalogSettings />
  }
];

export const APP_PARTNER_TRAINING_TABS = { tabItems };
