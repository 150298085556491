import $api from '../../http';

export default class ClientRecordingService {
  static async createClientRecording(phone, employeeId, date, time, location, comment) {
    return $api.post('/client-recording/', { phone, employeeId, date, time, location, comment });
  }
  static async getAllClientsRecording() {
    return $api.get('/client-recording/');
  }
  static async getAllRecByEmployeeIdOrClientId(employeeIdOrClientId) {
    return $api.get(`/client-recording/find/${employeeIdOrClientId}`);
  }
  static async getClientRecordingById(id) {
    return $api.get(`/client-recording/${id}`);
  }
  static async updateClientRecording(id, status) {
    return $api.patch(`/client-recording/${id}`, { status });
  }
}
