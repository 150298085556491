import IconsBlock from './icons-block/IconsBlock';
import LogoBlock from './logo-block/LogoBlock';
import PassResetDrawer from './pass-reset-drawer/PassResetDrawer';
import PreviewSection from './preview-section/PreviewSection';
import ReferenceBlock from './reference-block/ReferenceBlock';
import SignInForm from './signin-form/SignInForm';
import SignUpForm from './signup-form/SignUpForm';

export {
  IconsBlock,
  LogoBlock,
  PassResetDrawer,
  PreviewSection,
  ReferenceBlock,
  SignInForm,
  SignUpForm
};
