// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `popover-container {
  max-width: 20vw;
  width: 100%;
  overflow: hidden;
}
.custom-event-container {
  padding: 5px 10px;
  max-width: 20vw;
  width: 100%;
  border-radius: 8px;
}
.popover-title {
  max-width: 20vw;
  width: clamp(20ch, 100%, 60ch);
}
`, "",{"version":3,"sources":["webpack://./src/components/calendar/custom-event/custom-event.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,8BAA8B;AAChC","sourcesContent":["popover-container {\n  max-width: 20vw;\n  width: 100%;\n  overflow: hidden;\n}\n.custom-event-container {\n  padding: 5px 10px;\n  max-width: 20vw;\n  width: 100%;\n  border-radius: 8px;\n}\n.popover-title {\n  max-width: 20vw;\n  width: clamp(20ch, 100%, 60ch);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
