export const getMaxResults = (data) => {
  if (data.length < 1) {
    return null;
  }
  const DYN = [];
  const DNF = [];
  const FIM = [];
  const CNF = [];
  const CWT = [];

  data.map((workout) => {
    workout.exercises.map((item) => {
      if (item.exercise.control) {
        switch (item.exercise.name) {
          case 'DYN':
            DYN.push(item.fact);
            break;
          case 'DNF':
            DNF.push(item.fact);
            break;
          case 'FIM':
            FIM.push(item.fact);
            break;
          case 'CNF':
            CNF.push(item.fact);
            break;
          case 'CWT':
            CWT.push(item.fact);
            break;
          default:
            return;
        }
      }
    });
  });
  return {
    DYN: DYN.length > 0 ? Math.max(...DYN) : 0,
    DNF: DNF.length > 0 ? Math.max(...DNF) : 0,
    FIM: FIM.length > 0 ? Math.max(...FIM) : 0,
    CNF: CNF.length > 0 ? Math.max(...CNF) : 0,
    CWT: CWT.length > 0 ? Math.max(...CWT) : 0
  };
};
