// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-form-card {
  margin-bottom: 10px;
  width: 100%;
}
.catalog-form {
  width: 100%;
}
.catalog-form .catalog-form-item input,
.catalog-form-edit-item input {
  font-size: 11px !important;
}
.catalog-form .catalog-form-item {
  width: 24%;
  margin-bottom: 5px;
}
.catalog-form-checkbox .ant-form-item-control-input label {
  font-size: 11px !important;
  margin-bottom: 5px;
}
.catalog-form .catalog-form-edit-item {
  width: 100%;
  margin-bottom: 10px;
}
.catalog-form-btn {
  font-size: 11px;
  background-color: #5a8ebd;
}
.catalog-form-btn:hover {
  background-color: #ffffff !important;
  border-color: #5a8ebd;
  color: #5a8ebd !important;
}
.catalog-form-del-btn {
  margin: 0 5px;
  font-size: 11px;
}
.catalog-form-del-btn:hover {
  background-color: #ffffff !important;
  border-color: #ff4d4f;
  color: #ff4d4f !important;
}
.form-edit-style {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 440px) {
  .catalog-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .catalog-form .catalog-form-item {
    width: 100%;
  }
  .catalog-form-btn {
    margin-top: 10px;
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/training/catalogs-settings/catalog-item-content/catalog-item-form/catalog-item-form.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;;EAEE,0BAA0B;AAC5B;AACA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,oCAAoC;EACpC,qBAAqB;EACrB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,oCAAoC;EACpC,qBAAqB;EACrB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;EACA;IACE,gBAAgB;IAChB,sBAAsB;EACxB;AACF","sourcesContent":[".catalog-form-card {\n  margin-bottom: 10px;\n  width: 100%;\n}\n.catalog-form {\n  width: 100%;\n}\n.catalog-form .catalog-form-item input,\n.catalog-form-edit-item input {\n  font-size: 11px !important;\n}\n.catalog-form .catalog-form-item {\n  width: 24%;\n  margin-bottom: 5px;\n}\n.catalog-form-checkbox .ant-form-item-control-input label {\n  font-size: 11px !important;\n  margin-bottom: 5px;\n}\n.catalog-form .catalog-form-edit-item {\n  width: 100%;\n  margin-bottom: 10px;\n}\n.catalog-form-btn {\n  font-size: 11px;\n  background-color: #5a8ebd;\n}\n.catalog-form-btn:hover {\n  background-color: #ffffff !important;\n  border-color: #5a8ebd;\n  color: #5a8ebd !important;\n}\n.catalog-form-del-btn {\n  margin: 0 5px;\n  font-size: 11px;\n}\n.catalog-form-del-btn:hover {\n  background-color: #ffffff !important;\n  border-color: #ff4d4f;\n  color: #ff4d4f !important;\n}\n.form-edit-style {\n  display: flex;\n  justify-content: flex-end;\n}\n\n@media only screen and (max-width: 440px) {\n  .catalog-form {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  .catalog-form .catalog-form-item {\n    width: 100%;\n  }\n  .catalog-form-btn {\n    margin-top: 10px;\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
