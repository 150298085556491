import { makeAutoObservable } from 'mobx';
import { TemplateService } from '../../services';

export default class TemplateStore {
  isLoading = false;
  _template = {};
  _templates = [];
  _currentTemplates = [];

  constructor() {
    makeAutoObservable(this);
  }
  setLoading(bool) {
    this.isLoading = bool;
  }
  setTemplates(templates) {
    this._templates = templates;
  }
  setTemplate(template) {
    this._template = template;
  }
  setCurrentTemplates(currentTemplates) {
    this._currentTemplates = currentTemplates;
  }
  get templates() {
    return this._templates;
  }
  get template() {
    return this._template;
  }
  get currentTemplates() {
    return this._currentTemplates;
  }

  async createTemplate(title, description, type, level) {
    try {
      const res = await TemplateService.createTemplate(title, description, type, level);
      console.log(res);
      await this.getAllTemplates();
      this.setTemplate(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  async getAllTemplates() {
    this.setLoading(true);
    try {
      const res = await TemplateService.getAllTemplates();
      this.setTemplates(res.data);
    } catch (error) {
      return error;
    } finally {
      this.setLoading(false);
    }
  }
  async getTemplateById(id) {
    try {
      const res = await TemplateService.getTemplateById(id);
      this.setTemplate(res.data);
    } catch (error) {
      return error;
    }
  }
  async updateTemplate(id, title, description, type, level) {
    try {
      const res = await TemplateService.updateTemplate(id, title, description, type, level);
      this.setTemplate(res.data);
      return res;
    } catch (error) {
      return error;
    }
  }
  selectTemplateType(type) {
    const currentTemplates = this._templates.filter((item) => item.type === +type);
    this.setCurrentTemplates(currentTemplates);
  }
}
