import $api from '../../http';

export default class ExerciseService {
  static async createExercise(name, type, description, control) {
    return $api.post('/exercises/', { name, type, description, control });
  }
  static async getAllExercises() {
    return $api.get('/exercises/');
  }
  static async getExerciseById(id) {
    return $api.get(`/exercises/${id}`);
  }
  static async updateExercise(id, name, type, description, control) {
    return $api.patch(`/exercises/${id}`, { name, type, description, control });
  }
  static async deleteExerciseById(id) {
    return $api.delete(`/exercises/${id}`);
  }
}
