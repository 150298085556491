// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-in-form {
  width: 100%;
  font-size: 12px;
}
.sign-in-form-button {
  font-size: 13px;
  background-color: #769ccd;
  width: 40%;
}
.sign-in-divider {
  margin: 10px 0;
}
.sign-in-reset-pass {
  text-align: center;
  font-size: 10px;
  cursor: pointer;
  color: #5a8ebd;
  transition: all 0.2s ease-in;
}
.input-icon {
  margin-right: 5px;
}
.sign-in-reset-pass:hover {
  color: #598dbd;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/client-auth/signin-form/signin-form.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,cAAc;EACd,4BAA4B;AAC9B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":[".sign-in-form {\n  width: 100%;\n  font-size: 12px;\n}\n.sign-in-form-button {\n  font-size: 13px;\n  background-color: #769ccd;\n  width: 40%;\n}\n.sign-in-divider {\n  margin: 10px 0;\n}\n.sign-in-reset-pass {\n  text-align: center;\n  font-size: 10px;\n  cursor: pointer;\n  color: #5a8ebd;\n  transition: all 0.2s ease-in;\n}\n.input-icon {\n  margin-right: 5px;\n}\n.sign-in-reset-pass:hover {\n  color: #598dbd;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
