const workoutProfiles = [
  {
    value: 1,
    label: 'Тренировка на выносливость'
  },
  {
    value: 2,
    label: 'Тренировка на гипаксию'
  }
];
export const TEMPLATE_SELECTS = {
  workoutProfiles
};
