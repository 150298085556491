import React, { useContext, useState } from 'react';
import TemplateListFilter from '../template-list-filter/TemplateListFilter';
import TemplateList from '../TemplateList';
import { Context } from '../../../../..';

function TemplateListWrapper() {
  const { template, workoutProfile } = useContext(Context);
  const [currentData, setCurrentData] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const cards = [...template.templates];

  const levelHandler = (e) => {
    const currentLevel = currentType
      ? currentData.filter((item) => item.level === e)
      : cards.filter((item) => item.level === e);
    setCurrentData(currentLevel);
    setCurrentLevel(e);
  };

  const typeHandler = (e) => {
    const currentType = currentLevel
      ? currentData.filter((item) => item.type === e)
      : cards.filter((item) => item.type === e);
    setCurrentData(currentType);
    setCurrentType(e);
  };

  const resetHandler = (type) => {
    if (type === 'level') {
      setCurrentLevel(null);
      if (!currentType) {
        setCurrentData(cards);
      } else {
        let data = cards.filter((item) => item.type === currentType);
        setCurrentData(data);
      }
    } else {
      setCurrentType(null);
      if (!currentLevel) {
        setCurrentData(cards);
      } else {
        let data = cards.filter((item) => item.level === currentLevel);
        setCurrentData(data);
      }
    }
  };

  return (
    <>
      <TemplateListFilter
        data={cards}
        profiles={workoutProfile.workoutProfiles}
        levelHandler={levelHandler}
        typeHandler={typeHandler}
        resetHandler={resetHandler}
      />
      <TemplateList data={currentData ?? cards} />
    </>
  );
}

export default TemplateListWrapper;
