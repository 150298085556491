// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-user-container {
  display: flex;
  justify-content: flex-start;
}
.history-user-name {
  margin: 0 5px;
}
.history-user-content {
  max-height: 70vh;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 10px;
}
.history-no-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.history-pagination-container {
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/training/partner-training/create-workout/WorkoutList/history-drawer/history-drawer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;AAC7B;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,YAAY;AACd","sourcesContent":[".history-user-container {\n  display: flex;\n  justify-content: flex-start;\n}\n.history-user-name {\n  margin: 0 5px;\n}\n.history-user-content {\n  max-height: 70vh;\n  height: 100%;\n  overflow-y: auto;\n  margin-bottom: 10px;\n}\n.history-no-content {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.history-pagination-container {\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
