// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-menu-item {
  width: 100%;
  height: 95%;
  border: 1px solid #adb8cc;
  border-radius: 10px;
  margin: 2px;
  transition: 0.2s all ease-in;
}
.user-menu-item:hover {
  cursor: pointer;
  background-color: #f3f3f3;
  border: 1px solid #1e90ff;
}
.user-menu-item.inactive:hover {
  cursor: pointer;
  background-color: #f3f3f3;
  border: 1px solid #727272;
}
.outer-menu-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2px;
}
.inner-menu-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  font-size: 12px;
  color: #769ccd;
}
.inner-menu-item-wrapper.active {
  background: linear-gradient(
    211deg,
    rgba(90, 142, 189, 1) 0%,
    rgba(127, 195, 220, 1) 35%,
    rgba(172, 236, 223, 1) 100%
  );
  color: #ffffff;
}
.outer-menu-item-wrapper.inactive {
  filter: grayscale(var(--value, 100%));
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Layout/UserMenu/UserMenuItem/user-meu-item.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,4BAA4B;AAC9B;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,YAAY;AACd;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;AACA;EACE;;;;;GAKC;EACD,cAAc;AAChB;AACA;EACE,qCAAqC;AACvC","sourcesContent":[".user-menu-item {\n  width: 100%;\n  height: 95%;\n  border: 1px solid #adb8cc;\n  border-radius: 10px;\n  margin: 2px;\n  transition: 0.2s all ease-in;\n}\n.user-menu-item:hover {\n  cursor: pointer;\n  background-color: #f3f3f3;\n  border: 1px solid #1e90ff;\n}\n.user-menu-item.inactive:hover {\n  cursor: pointer;\n  background-color: #f3f3f3;\n  border: 1px solid #727272;\n}\n.outer-menu-item-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  padding: 2px;\n}\n.inner-menu-item-wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  border-radius: 10px;\n  font-size: 12px;\n  color: #769ccd;\n}\n.inner-menu-item-wrapper.active {\n  background: linear-gradient(\n    211deg,\n    rgba(90, 142, 189, 1) 0%,\n    rgba(127, 195, 220, 1) 35%,\n    rgba(172, 236, 223, 1) 100%\n  );\n  color: #ffffff;\n}\n.outer-menu-item-wrapper.inactive {\n  filter: grayscale(var(--value, 100%));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
