import { Col, Card } from 'antd';
import React from 'react';
import WorkoutCardCollapse from '../../../partner-training/create-workout/WorkoutTemplateCard/WorkoutCardCollapse/WorkoutCardCollapse';
import PropTypes from 'prop-types';
import './template-list-item.css';

const TemplateListItem = ({ item }) => {
  return (
    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
      <Card
        className="template-item-card"
        title={<h4 className="template-item-title">Шаблон: {item.title}</h4>}
        extra={<div className="template-item-level">Уровень {item.level}</div>}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <div>
            <div className="template-item-content">
              <div className="template-item-decription">Описание:</div>
              <div className="template-item-text">{item.description}</div>
            </div>
          </div>
        </div>
        <WorkoutCardCollapse item={item} />
      </Card>
    </Col>
  );
};

TemplateListItem.propTypes = {
  item: PropTypes.object
};

export default TemplateListItem;
