import $api, { API_URL } from '../../../http';

export default class EmployeeService {
  static async createEmployee(name, surname, position, level, userId) {
    return $api.post('/employees/', { name, surname, position, level, userId });
  }
  static async getAllEmployees() {
    return $api.get('/employees/');
  }
  static async getEmployeeById(id) {
    return $api.get(`/employees/${id}`);
  }

  static async updateEmployeeAvatar(id, formData) {
    const res = await fetch(`${API_URL}/employees/update-avatar/${id}`, {
      method: 'PATCH',
      body: formData,
      headers: { Authorization: localStorage.getItem('token') }
    });
    return res;
  }

  static async getEmployeeByUserId(userId) {
    const employee = await $api.get(`/employees/find/${userId}`);
    return employee;
  }

  static async updateEmployee(id, name, middleName, surname, position, level) {
    return $api.patch(`/employees/${id}`, { name, middleName, surname, position, level });
  }
}
