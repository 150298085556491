import React, { useContext, useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import CatalogItemForm from './catalog-item-form/CatalogItemForm';
import PageLabel from '../../../common/PageLabel/PageLabel';
import CatalogItemModal from './catalog-item-modal/CatalogItemModal';
import { dataPreparation, getCatalogColumn, getCatalogTitle } from '../../../../utils';
import { Context } from '../../../..';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import './catalog-item-content.css';

const CatalogItemContent = ({ img, type }) => {
  const { exercise, location, workoutProfile } = useContext(Context);
  const [currentRow, setCurrentRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getLocations = async () => {
      await location.getAllLocations();
      await exercise.getAllExercises();
      await workoutProfile.getAllWorkoutProfiles();
    };
    getLocations().catch((e) => console.log(e));
  }, [exercise.exercise, location.location, workoutProfile.workoutProfile]);

  const label = getCatalogTitle(type);
  const columns = getCatalogColumn(type);

  const data = (type) => {
    switch (type) {
      case 'exercise':
        return exercise.exercises;
      case 'location':
        return location.locations;
      case 'workout-profiles':
        return workoutProfile.workoutProfiles;
      default:
        return [];
    }
  };

  const tableHandler = (r) => {
    setCurrentRow(r);
    setIsModalOpen(true);
  };

  return (
    <>
      <CatalogItemForm type={type} />
      <Card className="catalog-card" title={<PageLabel img={img} text={label} />}>
        <Table
          scroll={{ x: 375 }}
          onRow={(r) => ({
            onClick: () => tableHandler(r)
          })}
          defaultSortOrder="descend"
          dataSource={dataPreparation(data(type))}
          columns={columns}
          pagination={{ pageSize: 7, className: 'list-pagination', size: 'small' }}
        />
      </Card>
      <CatalogItemModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={currentRow}
        type={type}
      />
    </>
  );
};

CatalogItemContent.propTypes = {
  img: PropTypes.string,
  type: PropTypes.string
};

export default observer(CatalogItemContent);
