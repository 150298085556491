// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-form {
  width: 100%;
}
.sign-up-logo-container {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.sig-up-form-link {
  color: #769ccd;
  cursor: pointer;
  margin: 0 5px;
}
.sig-up-form-button {
  font-size: 13px;
  background-color: #769ccd;
  width: 50%;
}
.sign-up-logo-alert {
  border-radius: 10px;
  font-size: 11px;
  margin-bottom: 5px;
  max-width: 420px;
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/client-auth/signup-form/signup-form.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,eAAe;EACf,aAAa;AACf;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".sign-up-form {\n  width: 100%;\n}\n.sign-up-logo-container {\n  margin-top: 5px;\n  display: flex;\n  justify-content: center;\n}\n.sig-up-form-link {\n  color: #769ccd;\n  cursor: pointer;\n  margin: 0 5px;\n}\n.sig-up-form-button {\n  font-size: 13px;\n  background-color: #769ccd;\n  width: 50%;\n}\n.sign-up-logo-alert {\n  border-radius: 10px;\n  font-size: 11px;\n  margin-bottom: 5px;\n  max-width: 420px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
