import { Col, Row, Tabs } from 'antd';
import { APP_PARTNER_TRAINING_TABS } from '../../../config';
import { useContext, useEffect } from 'react';
import { Context } from '../../../index';
import { observer } from 'mobx-react-lite';
import React from 'react';
const PartnerTrainingPage = () => {
  const { exercise, user, auth, recording, location, workoutProfile } = useContext(Context);
  useEffect(() => {
    const getBaseDate = async () => {
      const userId = auth.partner.id ?? user.user.id;
      if (!userId) {
        return;
      }
      await recording.getAllRecByEmployeeIdOrClientId(userId);
      await exercise.getAllExercises();
      await user.getAllUsers();
      await location.getAllLocations();
      await workoutProfile.getAllWorkoutProfiles();
    };
    getBaseDate().catch((error) => console.log(error));
  }, []);
  return (
    <>
      <Row className="training-container" gutter={10}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs
            defaultActiveKey="1"
            type="card"
            size="small"
            items={APP_PARTNER_TRAINING_TABS.tabItems}
          />
        </Col>
      </Row>
    </>
  );
};

export default observer(PartnerTrainingPage);
