import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';

export const saveExcel = async (workout) => {
  const workbook = new Excel.Workbook();
  const fileName = `Тренировки ${dayjs().format('DD.MM.YYYY')}`;
  const worksheet = workbook.addWorksheet(dayjs().format('DD.MM.YYYY'));
  const columns = [{ header: `Тренировки ${dayjs().format('DD.MM.YYYY')}`, key: 'workout' }];
  const data = [];
  const titles = [1];

  for (let i = 0; i < workout.length; i++) {
    data.push({
      workout: `${workout[i].clientData.client.client.surname} ${workout[i].clientData.client.client.name} на ${workout[i].time} ${workout[i].date}`
    });
    workout[i].clientData?.exercises
      .sort((a, b) => a.id - b.id)
      .forEach((item) =>
        data.push({
          workout: `${item.exercise.name} ${item.description}   ${item.plan}m`
        })
      );
    titles.push(titles[titles.length - 1] + workout[i].clientData?.exercises.length + 1);
  }

  worksheet.columns = columns;
  worksheet.columns.forEach((column) => {
    column.width = column.header.length + 20;
    column.alignment = { horizontal: 'left' };
  });

  for (let i = 0; i < data.length; i++) {
    worksheet.getRow(i + 1).getCell(1).value = data[i].workout;
    if (titles.includes(i + 1)) {
      worksheet.getRow(i + 1).getCell(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FFFFFF00' }
      };
      worksheet.getRow(i + 1).getCell(1).font = {
        // name: 'Comic Sans MS',
        family: 4,
        size: 12,
        underline: false,
        bold: true
      };
    }
  }

  worksheet.eachRow({ includeEmpty: false }, (row) => {
    const currentCell = row._cells;
    currentCell.forEach((singleCell) => {
      const cellAddress = singleCell._address;
      worksheet.getCell(cellAddress).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  const buf = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${fileName}.xlsx`);
};
